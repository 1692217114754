import React, {memo, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import DataTableHeaderFilter
  from '../data_table_header_filter/DataTableHeaderFilter';
import DataTableHeaderAggregation
  from '../data_table_header_aggregation/DataTableHeaderAggregation';
import {calculateFixedWidthForColumn} from '../../helpers/util';

const DataTableHeader = memo(props => {
  const {
    dbName,
    collectionName,
    columnHeaders,
    enableColumnFilters,
    lastFixedColNamespace,
    data,
    index,
    filterData,
    onFilterChange,
  } = props;
  const {
    label,
    key,
    namespace,
    aggregation = false,
    filter,
    fixed,
  } = data;

  const [activeFilter, setActiveFilter] = useState(false);

  const styles = fixed === true ?
      {
        left: index === 0 ?
            0 :
            calculateFixedWidthForColumn(index, columnHeaders),
      } :
      {};

  const handleActiveFilter = x => {
    setActiveFilter(x);
  };

  return (
      <div
          className={fixed === true ?
              `dt-th fixed ` + (activeFilter === true ? `active-filter` : ``) :
              `dt-th ` + (activeFilter === true ? `active-filter` : ``)}
          style={styles}
          data-field-name={key}
          data-field-namespace={namespace}
          data-class={key}
          key={namespace}
      >
        <div className="dt-header-cell">
          <div
              className="dt-header-cell-inner"
              data-tip
              data-for={'tooltip' + index}
          >
            <span>{label}</span>
          </div>
          {(filter?.trueBackendType === 'number' && filter?.type === 'number' &&
              aggregation) && <DataTableHeaderAggregation data={data}/>}
          {
            enableColumnFilters && filter && filter?.type !== 'none' && namespace !== '' && (
                <DataTableHeaderFilter
                    dbName={dbName}
							      collectionName={collectionName}
                    data={data}
                    lastFixedColNamespace={lastFixedColNamespace}
                    filterData={filterData}
                    onFilterChange={onFilterChange}
                    onActiveFilter={handleActiveFilter}
                />
            )
          }
        </div>
        <ReactTooltip
            id={'tooltip' + index}
            aria-haspopup="true"
            effect="solid"
        >
          <p className="m-0 p-0">Name: {key}</p>
          <p className="m-0 p-0">Alias: {label}</p>
        </ReactTooltip>
      </div>
  );
});

function areEqual(prevProps, nextProps) {
  return prevProps === nextProps;

  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

export default DataTableHeader;
