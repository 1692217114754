import React from 'react';
import {useSelector} from 'react-redux';
import {cancelSource} from '../../axios-interceptor';
import {addAlertToDOM} from '../../helpers/util';

const CancelApiButton = () => {
  const {apiRequestIds} = useSelector(state => state.util);

  if (apiRequestIds.length > 0) {
    const cancelAllApiRequest = () => {
      cancelSource.cancel('Your cancellation message');
      addAlertToDOM(
          `.db-show-hide-filter-section .db-show-hide-filter-alert-section`,
          'warning', 'All running API requests are terminated');
    };

    return (
        <button
            className="db-button ml-10"
            onClick={cancelAllApiRequest}
        >Cancel API Requests
        </button>
    );
  } else return null;
};

export default CancelApiButton;
