import React, { useEffect, useReducer } from 'react';
import {
  faExclamationCircle,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch } from "react-redux";
import { useDidUpdateEffect } from '../../helpers/customHooks';
import { addAlertToDOM } from '../../helpers/util';
import { UPDATE_GLOBAL_ERROR_DATA } from '../../redux/actions/types';
import PreAggregateConfiguration from './pre_aggregate_configuration/PreAggregateConfiguration';

const saveBtnStyles = {width: 100};

const initialState = {
  loading: true,
  saveLoading: false,
  preAggregates: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_loading_status':
      return {
        ...state,
        loading: action.payload,
      };
    case 'update_pre_aggregates':
      return {
        ...state,
        preAggregates: action.payload,
        loading: false,
      };
    case 'update_save_loading_status':
      return {
        ...state,
        saveLoading: action.payload,
      };
    default:
      return state;
  }
};

const PreAggregateConfigurationList = ({data, onChange}) => {
  if (data && data.length > 0) {
    return data.map((item, index) => (
        <PreAggregateConfiguration
            key={index}
            index={index}
            data={item}
            preAggregatesList={data}
            onChange={onChange}
        />
    ));
  } else return (
      <div className="db-alert warning mt-10">
        <FontAwesomeIcon icon={faExclamationCircle}/>
        <p>Don't have any pre-aggregates. Add to configure.</p>
      </div>
  );
};

const PreAggregationConfiguration = ({dbName, collectionName}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const {loading, saveLoading, preAggregates} = state;
  const {globalTimeout} = window['runConfig'];

  useEffect(() => {
    getPreAggregates();
  }, []);

  useDidUpdateEffect(() => {
    dispatch({
      type: 'update_loading_status',
      payload: true,
    });
    getPreAggregates();
  }, [dbName, collectionName]);

  const getPreAggregates = () => {
    axios.post('/data-browser/get-pre-aggregates', {
      databaseName: dbName,
      collectionName: collectionName,
      timeout: globalTimeout,
    }).then(response => {
      const resData = response.data;
      if (resData.code === 200 &&
          resData.data.hasOwnProperty('preAggregates')) {
        const preAggregates = resData?.data?.preAggregates ?? [];
        dispatch({
          type: 'update_pre_aggregates',
          payload: preAggregates,
        });
      } else dispatch({
        type: 'update_pre_aggregates',
        payload: [],
      });
    }).catch(() => {
      dispatch({
        type: 'update_pre_aggregates',
        payload: [],
      });
    });
  };

  const addNewPreAggregate = () => {
    const tempPreAggregates = [...preAggregates];
    tempPreAggregates.push({
      label: '',
      timeout: globalTimeout,
      display: true,
      recordInSnapshot: false,
      query: '[]',
      // is_include_filters: false,
      isCached: false,
      between: {
        from: 0,
        to: 0,
      },
      equals: 0,
      lcl: 0,
      hcl: 0,
    });
    handlePreAggregatesListChange(tempPreAggregates);
  };

  const buildAlertText = data => {
    let alertText = '';
    for (const objKey in data) {
      if (data.hasOwnProperty(objKey)) {
        alertText += `<li>${data[objKey]}</li>`;
      }
    }

    return `<ul>${alertText}</ul>`;
  };

  const savePreAggregationQuery = () => {
    if (!saveLoading) {
      let emptyLabel = preAggregates.filter((item)=>{
        return item.label == ""
      })
      if (emptyLabel.length > 0) {
        addAlertToDOM('.action-button-section', 'warning',`<p>Label should not empty</p>`, 'mb-10');
      }
      let duplicate = {};
      preAggregates.forEach((val,key) => {
        let filtered = preAggregates.filter((item)=>{
          return item.label == val.label
        })
        filtered.length > 1 && (duplicate[val.label] = `${val.label} needs to be changed`);
      });

      if (Object.keys(duplicate).length > 0) {
        addAlertToDOM('.action-button-section', 'warning',`<p>Label must be unique ${buildAlertText(duplicate)}</p>`, 'mb-10');
        return;
      }

      dispatch({
        type: 'update_save_loading_status',
        payload: true,
      });
      
      let foundErrors = false, errorsList = [];

      const tempPreAggregates = preAggregates.map(item => {
        if (item.query) {
          const tempPreAggregate = {...item};
          let resultQuery = [];

          try {
            resultQuery = typeof tempPreAggregate.query === 'object' ? JSON.stringify(tempPreAggregate.query) : tempPreAggregate.query;
          } catch(error) {
            foundErrors = true;
            errorsList.push(error.message);
          }

          tempPreAggregate.display = tempPreAggregate?.display ?? true;
          tempPreAggregate.timeout = tempPreAggregate?.timeout ?? globalTimeout;
          tempPreAggregate.recordInSnapshot = tempPreAggregate?.recordInSnapshot ?? false;
          // tempPreAggregate.between = tempPreAggregate?.between ?? { from: 0, to: 0 };
          // tempPreAggregate.lcl = tempPreAggregate?.lcl ?? 0;
          // tempPreAggregate.hcl = tempPreAggregate?.hcl ?? 0;
          // tempPreAggregate.equals = tempPreAggregate?.equals ?? 0;
          tempPreAggregate.query = resultQuery;
          return tempPreAggregate;
        }
        return item;
      });



      if(!foundErrors) {
        axios.post('/data-browser/admin/save-pre-aggregates', {
          databaseName: dbName,
          collectionName: collectionName,
          namespace: dbName + '.' + collectionName,
          preAggregates: tempPreAggregates,
          timeout: globalTimeout,
        }).then(response => {
          if (response.status === 200) {
            addAlertToDOM('.pre-aggregate-list-section', 'success', 'Successfully saved', 'mb-10');
          } else addAlertToDOM('.pre-aggregate-list-section', 'warning', 'Something went wrong. Please try again', 'mb-10');
          dispatch({
            type: 'update_save_loading_status',
            payload: false,
          });
        }).catch(error => {
          addAlertToDOM('.pre-aggregate-list-section', 'warning', 'Something went wrong. Please try again', 'mb-10');
          dispatch({
            type: 'update_save_loading_status',
            payload: false,
          });
          if(error?.response?.data?.message) {
            reduxDispatch({
              type: UPDATE_GLOBAL_ERROR_DATA,
              payload: {
                visibility: true,
                errors: error.response.data.message
              }
            });
          }
        });
      } else {  
        dispatch({
          type: 'update_save_loading_status',
          payload: false,
        });
        reduxDispatch({
          type: UPDATE_GLOBAL_ERROR_DATA,
          payload: {
            visibility: true,
            errors: errorsList
          }
        });
      }
    }
  };

  const handlePreAggregatesListChange = data => {
    dispatch({
      type: 'update_pre_aggregates',
      payload: data,
    });
  };

  if (loading) {
    return (
      <div className="pt-50 pb-50">
        <h3 className="text-center">
          <FontAwesomeIcon
              className="loading-icon"
              icon={faSpinner}
              spin
          /> Loading</h3>
      </div>
    );
  }

  return (
      <Container fluid>
        <Row>
          <Col xs={12} className="pre-aggregate-list-section mt-10">
            <PreAggregateConfigurationList
                data={preAggregates}
                onChange={handlePreAggregatesListChange}
            />
          </Col>
          <Col className="action-button-section" xs={12}>
            <button
                className="db-button db-button--primary mr-10"
                onClick={addNewPreAggregate}
            >Add Pre-Aggregate
            </button>
            <button
                className="db-button db-button--primary"
                style={saveBtnStyles}
                onClick={savePreAggregationQuery}
            >
              {
                saveLoading ? (
                    <span>
                      <FontAwesomeIcon
                          className="loading-icon mr-5"
                          icon={faSpinner}
                          spin
                      />
                      Save
                    </span>
                ) : 'Save'
              }
            </button>
          </Col>
        </Row>
      </Container>
  );
};

export default PreAggregationConfiguration;
