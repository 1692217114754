import React, {useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import axios from 'axios';
import {sha256} from 'js-sha256';
import './Login.scss';

const Login = () => {
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
  });
  const history = useHistory();
  const {email, password} = loginFormData;
  const {mainApp: {loginApiPath}} = window['runConfig'];
  const handleLoginFieldChange = e => {
    const {name, value} = e.target;
    setLoginFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    axios.post('/api' + loginApiPath, {
      login_id: email,
      password: password ? sha256(password) : '',
    }).then(response => {
      if (response.status === 200) {
        history.push('/app/');
      }
    }).catch(error => console.log(error.response));
  };

  return (
      <div className="login-div-wrapper">
        <div className="login-div">
          <h2 className="login-heading">Login to your account</h2>
          <p className="login-sub-heading">Enter login credentials to access
            the <b><i>Data Browser</i></b></p>
          <input
              className="db-input"
              placeholder="Email"
              name="email"
              type="email"
              value={email}
              onChange={handleLoginFieldChange}
          />
          <input
              className="db-input"
              placeholder="Password"
              name="password"
              type="password"
              value={password}
              onChange={handleLoginFieldChange}
          />
          <div className="login-actions-section">
            <div className="login-actions-section-left">
              <button
                  className="login-button db-button db-button--primary"
                  onClick={handleLogin}
              >Login
              </button>
            </div>
            <div className="login-actions-section-right">
              <NavLink className="fp-link" to="/forgot-password">Forgot
                password?</NavLink>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;
