import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import DataTableHeaderFilterLayout
  from '../data_table_header_filter_layout/DataTableHeaderFilterLayout';
import './DataTableHeaderFilter.scss';

class DataTableHeaderFilter extends React.Component {
  state = {
    displayMenu: false,
    pinned: false,
  };

  showDropdownMenu = event => {
    event.preventDefault();
    this.setState({
      displayMenu: true,
    }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
    this.props.onActiveFilter && this.props.onActiveFilter(true);
  };

  hideDropdownMenu = event => {
    if (this.dropdownMenu && !this.dropdownMenu.contains(event.target) && this.state.pinned === false) {
      this.setState({
        displayMenu: false,
      }, () => {
        document.removeEventListener('click', this.hideDropdownMenu);
      });
      this.props.onActiveFilter && this.props.onActiveFilter(true);
    }
  };

  handlePinChange = () => {
    this.setState(prevState => ({
      pinned: !prevState.pinned,
    }));
  };

  hideDropDownForcibly = () => {
    this.setState({
      displayMenu: false,
      pinned: false,
    }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  };

  render() {
    const {
      props: {dbName, collectionName, data, lastFixedColNamespace, filterData, onFilterChange},
      state: {displayMenu, pinned},
    } = this;
    const filterClasses = filterData && typeof filterData[data.namespace] !== 'undefined' ? 'dt-filter-icon active' : 'dt-filter-icon';

    return (
        <div
            className={displayMenu ? 'dropdown active' : 'dropdown'}
            ref={element => this.dropdownMenu = element}
            title="Filter"
        >
          <FontAwesomeIcon
              icon={faFilter}
              className={filterClasses}
              onClick={this.showDropdownMenu}
          />
          {
            displayMenu && (
                <DataTableHeaderFilterLayout
                    dbName={dbName}
                    collectionName={collectionName}
                    data={data}
                    lastFixedColNamespace={lastFixedColNamespace}
                    filterData={filterData}
                    onFilterChange={onFilterChange}
                    pinned={pinned}
                    onPin={this.handlePinChange}
                    onClose={this.hideDropDownForcibly}
                />
            )
          }
        </div>
    );
  }
}

DataTableHeaderFilter.propTypes = {
  data: PropTypes.object.isRequired,
  filterData: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default DataTableHeaderFilter;
