import { useEffect, useMemo, useReducer } from 'react';
import {
	faExclamationCircle,
	faSpinner,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Col, Container, Row } from 'react-grid-system';
import Select from 'react-select';
import './CollectionTagsConfiguration.scss';
import { addAlertToDOM } from '../../helpers/util';

const initialState = {
	collectionTagsStatus: 'loading',
	collectionTags: [],
	selectedCollectionTags: null,
	tags: [],
	saveTagLoadingStatus: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'update_collection_tags_status':
			return {
				...state,
				collectionTagsStatus: action.payload,
			};
		case 'update_selected_collection_tag':
			return {
				...state,
				selectedCollectionTags: action.payload,
			};
		case 'update_collection_tags_and_status':
			return {
				...state,
				collectionTags: action.payload.collectionTags,
				collectionTagsStatus: action.payload.collectionTagsStatus,
			};
		case 'update_collection_tags_and_reset_selected':
			return {
				...state,
				collectionTags: action.payload,
				selectedCollectionTags: null,
				saveTagLoadingStatus: false,
			};
		case 'update_save_tag_loading_status':
			return {
				...state,
				saveTagLoadingStatus: action.payload,
			};
		case 'update_tags':
			return {
				...state,
				tags: action.payload,
			};
		default:
			return state;
	}
};

const CollectionTagsConfiguration = ({ dbName, collectionName }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { globalTimeout } = window['runConfig'];
	const {
		collectionTagsStatus,
		collectionTags,
		selectedCollectionTags,
		tags,
		saveTagLoadingStatus,
	} = state;

	useEffect(() => {
		getTags();
		getAssignedTags();
	}, []);

	const getTags = () => {
		axios
			.post('/data-browser/admin/get-tags', {
				timeout: globalTimeout,
			})
			.then((response) => {
				if (response.status === 200) {
					const data = response?.data?.data ?? {};
					const tempData = [];

					for (const dataKey in data) {
						if (data.hasOwnProperty(dataKey)) {
							const { key, label } = data[dataKey];
							tempData.push({
								label,
								value: key,
							});
						}
					}

					dispatch({
						type: 'update_tags',
						payload: tempData,
					});
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	const getAssignedTags = () => {
		const reqPayload = {
			databaseName: dbName,
			collectionName: collectionName,
			timeout: globalTimeout,
		};

		axios
			.post('/data-browser/admin/get-tags-for-collection', reqPayload)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					const data = response?.data?.data ?? [];
					const tempData = data.map(({ key, label }) => ({
						label,
						value: key,
					}));
					dispatch({
						type: 'update_collection_tags_and_status',
						payload: {
							collectionTags: tempData,
							collectionTagsStatus: 'success',
						},
					});
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	const getFilteredTags = (tags, collectionTags) => {
		const collectionKeys = collectionTags.map((item) => item.value);
		return tags.filter((item) => !collectionKeys.includes(item.value));
	};

	if (collectionTagsStatus === 'loading') {
		return (
			<div className='pt-50 pb-50'>
				<h3 className='text-center'>
					<FontAwesomeIcon className='loading-icon' icon={faSpinner} spin />{' '}
					Loading
				</h3>
			</div>
		);
	}

	if (collectionTagsStatus === 'error') {
		return (
			<div className='pt-50 pb-50'>
				<h3 className='text-center'>Server error</h3>
			</div>
		);
	}

	const handleDeleteTag = (value) => {
		const requestPayload = {
			key: value,
			databases: {
				[dbName]: [collectionName],
			},
			timeout: '15s',
		};
		axios
			.post('/data-browser/admin/delete-tags-for-collections', requestPayload)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					const tempData = collectionTags.filter(
						(item) => item.value !== value
					);
					dispatch({
						type: 'update_collection_tags_and_reset_selected',
						payload: tempData,
					});
					addAlertToDOM(
						'.selected-tags-list',
						'success',
						'Tag removed successfully ',
						'mb-15'
					);
				} else {
					addAlertToDOM(
						'.selected-tags-list',
						'warning',
						'Something went wrong. Please try again',
						'mb-15'
					);
				}
			})
			.catch((error) => {
				console.log(error.response);
				addAlertToDOM(
					'.selected-tags-list',
					'warning',
					'Something went wrong. Please try again',
					'mb-15'
				);
			});
	};

	const handleTagSelect = (selectedOption) => {
		dispatch({
			type: 'update_selected_collection_tag',
			payload: selectedOption,
		});
	};

	const saveTagForCollection = () => {
		if (selectedCollectionTags) {
			dispatch({
				type: 'update_save_tag_loading_status',
				payload: true,
			});

			const requestPayload = selectedCollectionTags.map(({label, value}) => ({
				key: value,
				label,
				databases: {
					[dbName]: [collectionName],
				},
			}));

			axios
				.post('/data-browser/admin/save-tags-for-collections', requestPayload)
				.then((response) => {
					console.log(response);
					if (response.status === 200) {
						const data = response?.data?.data ?? {};
						let savedTags = [], failedTags = [];

						for(let dataKey in data){
							if(data.hasOwnProperty(dataKey)) {
								const {key, label, code} = data[dataKey];
								if(code === 200) {
									savedTags.push({
										label,
										value: key
									})
								} else failedTags.push(data[dataKey]);
							}
						}

						if(failedTags.length > 0) {
							const buildAlertText = list => {
								let alertText = '';
								list.forEach(({message}) => {
									alertText += `<li>${message}</li>`;
								});
								return `<ul>${alertText}</ul>`;
							};

							addAlertToDOM(
								'.selected-tags-list',
								'warning',
								`<p>Below tags can't be saved.</p> ${buildAlertText(failedTags)}`,
								'mb-15',
								10000,
								true
							)
						} else if(selectedCollectionTags.length === savedTags.length) {
							addAlertToDOM(
								'.selected-tags-list',
								'success',
								'Tag(s) are assigned successfully ',
								'mb-15'
							);
						}
						dispatch({
							type: 'update_collection_tags_and_reset_selected',
							payload: [...collectionTags, ...savedTags],
						});	
					} else {
						dispatch({
							type: 'update_save_tag_loading_status',
							payload: false,
						});
						addAlertToDOM(
							'.selected-tags-list',
							'warning',
							'Something went wrong. Please try again',
							'mb-15'
						);
					}
				})
				.catch((error) => {
					console.log(error.response);
					dispatch({
						type: 'update_save_tag_loading_status',
						payload: false,
					});
					addAlertToDOM(
						'.selected-tags-list',
						'warning',
						'Something went wrong. Please try again',
						'mb-15'
					);
				});
		} else alert('Please select any tag');
	};

	const filteredTags = getFilteredTags(tags, collectionTags);

	return (
		<Container fluid className='collection-tags-configuration-section'>
			<Row className='mt-15 mb-10'>
				<Col xs={12}>
					<div className='selected-tags-list'>
						<span className='mr-5'>Assigned Tags :</span>
						{collectionTags.length > 0 ? (
							collectionTags.map(({ label, value }) => {
								return (
									<span className='db-tag' key={value}>
										{label}
										<FontAwesomeIcon
											className='delete-icon'
											icon={faTimes}
											onClick={() => handleDeleteTag(value)}
										/>
									</span>
								);
							})
						) : (
							<div className='db-alert warning mt-6'>
								<FontAwesomeIcon icon={faExclamationCircle} />
								<p>No tags found</p>
							</div>
						)}
					</div>
				</Col>
				<Col xs={12}>
					<Row className='add-tags-to-collection-section'>
						<Col xs={4}>
							<Select
								placeholder='Select Tag'
								isSearchable={true}
								isMulti={true}
								isClearable={true}
								isLoading={saveTagLoadingStatus}
								isDisabled={saveTagLoadingStatus}
								value={selectedCollectionTags}
								options={filteredTags}
								onChange={handleTagSelect}
							/>
						</Col>
						<Col xs={8}>
							<button
								className='db-button db-button--primary save-button'
								disabled={saveTagLoadingStatus}
								onClick={saveTagForCollection}
							>
								{saveTagLoadingStatus ? (
									<>
										<FontAwesomeIcon
											className='loading-icon mr-5'
											icon={faSpinner}
											spin
										/>
										<span>Assign</span>
									</>
								) : (
									'Assign'
								)}
							</button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default CollectionTagsConfiguration;
