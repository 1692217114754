import React, { useState } from 'react';
import { faChevronUp, faChevronDown, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_GLOBAL_ERROR_DATA } from '../../redux/actions/types';

const AlertModal = () => {
    const [errorDetailVisibility, setErrorDetailVisibility] = useState(false);
    const {alertModalData: { visibility, errors }} = useSelector(state => state.util);
    const dispatch = useDispatch();

    const toggleErrorDetailVisibility = () => setErrorDetailVisibility(prevState => !prevState);

    const closeModal = () => {
      setErrorDetailVisibility(false);
      dispatch({
        type: UPDATE_GLOBAL_ERROR_DATA,
        payload: {
          visibility: false,
          error: '',
          errors: ''
        }
      })
    }

    const renderErrors = () => {
      if(errors) {
        if(typeof errors === 'string'){
          return <p>{errors}</p>
        } else if(Array.isArray(errors) && errors.length > 0) {
          return (
            <ul>
              {errors.map((error, index) => <li key={index}>{error}</li>)}
            </ul>
          )
        } else return null;
      } else return null;
    }

    return (
        <div className={`db-modal ${visibility ? 'show' : 'hide'}`}>
          <div className="db-modal-content" style={{ width: 400 }}>
            <div className="db-modal-header">
              <h3 className="db-modal-header-text">Error <span className="close" onClick={closeModal}>&times;</span></h3>
            </div>
            <div className="db-modal-body">
              <div className="alert-modal-section">
                  <FontAwesomeIcon className="error-icon" icon={faExclamationTriangle}/>
                  <div className="alert-modal-content-section">
                    <h3>There was an error, Please try again.</h3>
                    <span className="error-detail-link" onClick={toggleErrorDetailVisibility}>Details { errorDetailVisibility ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}</span>
                    { 
                      errorDetailVisibility && (
                        <div className="alert-modal-content-detailed-section">                    
                          {renderErrors()}
                        </div>
                      )
                    }
                  </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default AlertModal;
