import React from 'react';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faExclamationCircle,
	faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import DataTableColumnConfiguration from '../../components/data_table_column_configuration/DataTableColumnConfiguration';
import PreAggregationConfiguration from '../../components/pre_aggregation_configuration/PreAggregationConfiguration';
import { withPageAccessCheck } from '../../helpers/withPageAccessCheck';
import CollectionTagsConfiguration from '../../components/collection_tags_configutaion/CollectionTagsConfigutaion';

const CollectionConfiguration = () => {
	const { dbName, collectionName } = useParams();

	const redirectToDataTablePage = () => {
		const win = window.open(
			`/app/data-table/db-name=${dbName}&collection-name=${collectionName}`,
			'_blank'
		);
		if (win != null) {
			win.focus();
		}
	};

	if (typeof dbName === 'undefined' || typeof collectionName === 'undefined') {
		return (
			<div className='db-alert warning'>
				<FontAwesomeIcon icon={faExclamationCircle} />
				<p>Please select any collection to configure.</p>
			</div>
		);
	}

	return (
		<>
			<p>
				Database: <b>{dbName}</b>, Database Type: <b>MongoDB</b>, Collection:{' '}
				<b className='cursor-pointer' onClick={redirectToDataTablePage}>
					{collectionName}
					<FontAwesomeIcon icon={faExternalLinkAlt} className='ml-5' />
				</b>
			</p>
			<Tabs className='custom-tabs mt-20'>
				<TabList>
					<Tab>Table Columns</Tab>
					<Tab>Pre-Aggregations</Tab>
					<Tab>Tags</Tab>
				</TabList>
				<TabPanel>
					<DataTableColumnConfiguration
						dbName={dbName}
						collectionName={collectionName}
					/>
				</TabPanel>
				<TabPanel>
					<PreAggregationConfiguration
						dbName={dbName}
						collectionName={collectionName}
					/>
				</TabPanel>
				<TabPanel>
					<CollectionTagsConfiguration
						dbName={dbName}
						collectionName={collectionName}
					/>
				</TabPanel>
			</Tabs>
		</>
	);
};

export default withPageAccessCheck(CollectionConfiguration);
