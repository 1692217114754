import React, { useEffect, useReducer } from 'react';
import { faDatabase, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Col, Row } from 'react-grid-system';
import Select from 'react-select';
import Switch from 'react-switch';
import { addAlertToDOM } from '../../helpers/util';
import { withPageAccessCheck } from '../../helpers/withPageAccessCheck';
import {UPDATE_GLOBAL_ERROR_DATA} from '../../redux/actions/types';
import './UserRestrictions.scss';
import { useDispatch } from 'react-redux';

const initialState = {
  modalVisibility: false,
  enableAdminConfiguration: false,
  tableVisibilityConfigurationData: {},
  selectedUser: '',
  listOfCollection: {},
  listOfUser: [],
  loading: false,
  loadingUserData: false,
  saveDataLoadingStatus: false,
};

const {globalTimeout} = window['runConfig'];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_modal_visibility':
      return {
        ...state,
        modalVisibility: action.payload,
      };
    case 'update_table_configuration_toggle':
      return {
        ...state,
        enableAdminConfiguration: action.payload,
      };
    case 'update_table_visibility_configuration_data':
      return {
        ...state,
        tableVisibilityConfigurationData: action.payload,
      };
    case 'update_selected_user':
      return {
        ...state,
        selectedUser: action.payload,
      };
    case 'update_list_of_collection':
      return {
        ...state,
        listOfCollection: action.payload,
      };
    case 'update_loading_user':
      return {
        ...state,
        loadingUserData: action.payload,
      };
    case 'update_list_of_user':
      return {
        ...state,
        listOfUser: action.payload,
        loadingUserData: false
      };
    case 'update_loading':
      return {
        ...state,
        loading: action.payload,
      };
    case 'update_user_restrictions_data':
      return {
        ...state,
        enableAdminConfiguration: action.payload.enableAdminConfiguration,
        listOfCollection: action.payload.listOfCollection,
        tableVisibilityConfigurationData: action.payload.tableVisibilityConfigurationData,
        loading: false,
        modalVisibility: true,
      };
    case 'update_save_data_loading_status':
      return {
        ...state,
        saveDataLoadingStatus: action.payload,
      };
    default:
      return state;
  }
};

const UserRestrictions = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const {
    modalVisibility,
    enableAdminConfiguration,
    tableVisibilityConfigurationData,
    selectedUser,
    listOfCollection,
    listOfUser,
    loading,
    loadingUserData,
    saveDataLoadingStatus
  } = state;

  useEffect(() => {
    populateUser();
  }, []);

  const populateUser = () => {
    dispatch({
      type: 'update_loading_user',
      payload: true
    });
    axios.post('/data-browser/admin/get-all-users', {
      filterName: ''
    }).then(response => {
      if (response.status === 200) {
        const {data} = response.data;
        dispatch({
          type: 'update_list_of_user',
          payload: data,
        });
      } else dispatch({
        type: 'update_loading_user',
        payload: true
      });
    }).catch(error => {
      console.log(error);
      dispatch({
        type: 'update_loading_user',
        payload: true
      });
    });
  };

  const handleTableConfigurationToggleChange = checked => {
    dispatch({
      type: 'update_table_configuration_toggle',
      payload: checked,
    });
  };

  const handleSelectedUserChange = val => {
    // console.log(evt.target.value);
    dispatch({
      type: 'update_selected_user',
      payload: val.email,
    });
    dispatch({
      type: 'update_modal_visibility',
      payload: false,
    });
  };

  const handleTableVisibilityConfigurationData = (namespace, checked) => {
    let tempTableVisibilityConfigurationData = {...tableVisibilityConfigurationData};
    tempTableVisibilityConfigurationData[namespace] = checked;
    dispatch({
      type: 'update_table_visibility_configuration_data',
      payload: tempTableVisibilityConfigurationData,
    });
  };

  const handleButtonSaveClicked = () => {
    saveUserRestrictions(tableVisibilityConfigurationData);
  };

  const saveUserRestrictions = tableVisibilityConfigurationData => {
    dispatch({
      type: 'update_save_data_loading_status',
      payload: true,
    });
    let tmpSavedData = {
      user: selectedUser,
      enableAdminConfiguration,
      configureCollection: {},
      timeout: globalTimeout,
    };
    for (const key in tableVisibilityConfigurationData) {
      if (Object.hasOwnProperty.call(tableVisibilityConfigurationData, key)) {
        let db = key.split('--')[0];
        let collection = key.split('--')[1];
        if (Object.hasOwnProperty.call(tmpSavedData['configureCollection'],
            db)) {
          tmpSavedData.configureCollection[db][collection] = tableVisibilityConfigurationData[key];
        } else {
          tmpSavedData.configureCollection[db] = {};
          tmpSavedData.configureCollection[db][collection] = tableVisibilityConfigurationData[key];

        }
      }
    }

    axios.post('/data-browser/admin/save-user-restriction', tmpSavedData).then(response => {
          if (response.status === 200) {
            addAlertToDOM('.user-restriction-alert', 'success', 'Successfully saved user restrictions', 'mb-10');
          } else addAlertToDOM('.user-restriction-alert', 'warning', 'Something went wrong. Please try again.', 'mb-10');
        }).catch(error => {
          if(error?.response?.data?.message){
            reduxDispatch({
              type: UPDATE_GLOBAL_ERROR_DATA,
              payload: {
                visibility: true,
                errors: error.response.data.message
              }
            });
          }
          addAlertToDOM('.user-restriction-alert', 'warning', 'Something went wrong. Please try again.', 'mb-10');
        }).finally(() => {
          dispatch({
            type: 'update_save_data_loading_status',
            payload: false,
          });
        });
  };

  const getUserRestriction = () => {
    if (selectedUser === '') {
      addAlertToDOM('.user-restriction-alert', 'warning', 'Please select any user', 'mb-10');
    } else {
      dispatch({
        type: 'update_loading',
        payload: true,
      });
      axios.post('/data-browser/admin/get-user-restriction', {
        'user': selectedUser,
        'timeout': globalTimeout,
      }).then(response => {
        if (response.status === 200) {
          let tempTableVisibilityConfigurationData = {};
          const {
            enableAdminConfiguration,
            configureCollection,
          } = response.data.data;
          for (const dbname in configureCollection) {
            if (Object.hasOwnProperty.call(configureCollection, dbname)) {
              const collections = configureCollection[dbname];
              for (const collectionName in collections) {
                if (Object.hasOwnProperty.call(collections, collectionName)) {
                  tempTableVisibilityConfigurationData[`${dbname}--${collectionName}`] = collections[collectionName];
                }
              }
            }
          }

          dispatch({
            type: 'update_user_restrictions_data',
            payload: {
              enableAdminConfiguration,
              listOfCollection: configureCollection,
              tableVisibilityConfigurationData: tempTableVisibilityConfigurationData,
              modalVisibility: true,
            },
          });
        } else dispatch({
          type: 'update_loading',
          payload: false,
        });
      }).catch(error => {
        if(error?.response?.data?.message){
					reduxDispatch({
						type: UPDATE_GLOBAL_ERROR_DATA,
						payload: {
							visibility: true,
							errors: error.response.data.message
						}
					});
				}
        dispatch({
          type: 'update_loading',
          payload: false,
        });
      });
    }
  };

  const getList = () => {
    let dbHtml = [];

    for (const key in listOfCollection) {
      if (listOfCollection.hasOwnProperty(key)) {
        dbHtml.push(
            <div
                key={key}
                className="collection-level-restriction-row"
            >
              <Row key={key}>
                <Col xs={4}><FontAwesomeIcon icon={faDatabase} className="database-icon"/> {key}</Col>
                <Col xs={8}>
                  {
                    Object.keys(listOfCollection[key]).length > 0 && (
                        <>
                          {
                            Object.keys(listOfCollection[key]).map((item) => {
                              // const currCollectionVisibility = tableVisibilityConfigurationData.hasOwnProperty(
                              //     `${key}--${item}`) ? tableVisibilityConfigurationData[`${key}--${item}`] :true;
                              return (
                                  <Row className="mt-10 mb-10" key={item}>
                                    <Col xs={6} key={item}>
                                      {item}
                                    </Col>
                                    <Col xs={6}>
                                      <Switch
                                          onChange={checked => handleTableVisibilityConfigurationData(
                                              `${key}--${item}`, checked)}
                                          checked={tableVisibilityConfigurationData[`${key}--${item}`]}
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={15}
                                          width={40}
                                          className="react-switch"
                                      />
                                    </Col>
                                  </Row>
                              );
                            })
                          }
                        </>
                    )
                  }
                </Col>
              </Row>
            </div>,
        );
      }
    }

    if (dbHtml.length > 0) {
      return (
          <div>
            {dbHtml}
          </div>
      );
    } else return null;
  };

  const loadingIcon = <FontAwesomeIcon
        className="loading-icon mr-5"
        icon={faSpinner}
        spin
    />;

  return (
      <div className="user-restrictions-section">
          <Row>
            <Col xs={12}>
              <div className="user-restriction-alert"/>
              <>
                {
                  loadingUserData ?
                      <span>{loadingIcon} Getting user list..</span> :
                      (
                          <Select
                              placeholder="Select User"
                              className="react-selectcomponent"
                              options={listOfUser}
                              getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                              getOptionValue={option => option.email}
                              onChange={handleSelectedUserChange}
                              isSearchable={true}
                          />
                      )
                }
                <button
                    className="db-button db-button--danger edit-button ml-10 mr-10"
                    onClick={getUserRestriction}
                >Edit Restrictions
                </button>
                {
                  modalVisibility && selectedUser !== '' && (
                      <button
                          className="db-button db-button--primary save-button"
                          onClick={handleButtonSaveClicked}
                      >{saveDataLoadingStatus ? <>{loadingIcon} <span>Save</span></> : 'Save'}</button>
                  )
                }
              </>
            </Col>
            {
              loading ? (
                  <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                  >
                    <h2>{loadingIcon} Getting user restriction data..</h2>
                  </div>
              ) : (
                  modalVisibility && <>
                    <Col xs={12}>
                      <div className="admin-configuration-section">
                        <p className="mr-15">Enable admin configuration:</p>
                        <Switch
                            onChange={handleTableConfigurationToggleChange}
                            checked={enableAdminConfiguration}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={15}
                            width={40}
                            className="react-switch"
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <p>Configure collection/table visibility:</p>
                      {getList()}
                    </Col>
                  </>
              )
            }
          </Row>
      </div>
  );
};

export default withPageAccessCheck(UserRestrictions);
