import React, {useEffect, useState} from 'react';
import './ScrollToTop.scss';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top co-ordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  if (isVisible) {
    return (
        <div className="scroll-to-top" onClick={scrollToTop}>
          <FontAwesomeIcon
              className="scroll-to-top-icon"
              icon={faChevronUp}
          />
        </div>
    );
  } else return null;
};

export default ScrollToTop;
