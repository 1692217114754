import React, {useEffect, useReducer} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {DataTableHeaderColumnPicker} from '../index';
import DataTableColumnConfigurationRow
  from '../configurable_table_column/DataTableColumnConfigurationRow';
import Switch from 'react-switch';

const sortColumns = (a, b) => a.key.localeCompare(b.key);

const mergeTableColumns = (columnsList1, columnsList2) => Object.values([].concat(columnsList1, columnsList2).reduce((r, c) => (r[c.namespace] = Object.assign((r[c.namespace] || {}), c), r), {}));

const initialState = {
  loading: true,
  tableColumns: [],
  configuredTableColumns: [],
  saveDefaultFlag: false,
  disableSaveDefault: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_loading_status':
      return {
        ...state,
        loading: action.payload,
      };
    case 'update_all_table_columns':
      return {
        ...state,
        tableColumns: action.payload.tableColumns,
        configuredTableColumns: action.payload.configuredTableColumns,
        loading: false,
      };
    case 'update_all_table_columns_and_disable_save_default':
      return {
        ...state,
        tableColumns: action.payload.tableColumns,
        configuredTableColumns: action.payload.configuredTableColumns,
        saveDefaultFlag: action.payload.saveDefaultFlag,
        disableSaveDefault: action.payload.disableSaveDefault,
        loading: false,
      };
    case 'update_configured_table_columns':
      return {
        ...state,
        configuredTableColumns: action.payload,
      };
    case 'update_save_default_flag': {
      return {
        ...state,
        saveDefaultFlag: action.payload,
      };
    }
    default:
      return state;
  }
};

const DataTableColumnConfiguration = ({dbName, collectionName}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    loading,
    tableColumns,
    configuredTableColumns,
    saveDefaultFlag,
    disableSaveDefault
  } = state;
  const {globalTimeout} = window['runConfig'];

  useEffect(() => {
    if (dbName || collectionName) {
      getCollectionStructure();
    }
  }, [dbName, collectionName]);

  const getCollectionStructure = () => {
    dispatch({
      type: 'update_loading_status',
      payload: true,
    });
    axios.post('/data-browser/get-structure', {
      databaseName: dbName,
      collectionName,
      query: [{'$sample': {'size': 10}}],
      timeout: globalTimeout,
    }).then(response => {
      if (response.status === 200) {
        const {
          tableColumns,
          configuredTableColumns,
          saveDefaultFlag,
          disableSaveDefault,
        } = response.data.data;
        const mergedTableColumns = mergeTableColumns(tableColumns, configuredTableColumns ?? []);
        const sortedTableColumns = tableColumns ? mergedTableColumns.sort(sortColumns) : [];

        dispatch({
          type: 'update_all_table_columns_and_disable_save_default',
          payload: {
            tableColumns: sortedTableColumns,
            configuredTableColumns: configuredTableColumns ?? [],
            saveDefaultFlag: saveDefaultFlag,
            disableSaveDefault: disableSaveDefault ?? false,
          },
        });
      } else dispatch({
        type: 'update_loading_status',
        payload: false,
      });
    }).catch(() => dispatch({
      type: 'update_loading_status',
      payload: false,
    }));
  };

  const parseData = data => {
    let maxIndex = -1, indexArray = [];

    data.forEach(({orderIndex}) => {
      indexArray.push(orderIndex ?? -1);
      if (typeof orderIndex != 'undefined') {
        if (orderIndex > maxIndex) maxIndex = orderIndex;
      }
    });

    let tempMaxIndex = maxIndex;

    return indexArray.map((item, index) => {
      let tempItem = {...data[index]};
      if (item === -1) {
        const incTempMaxIndex = tempMaxIndex + 1;
        tempMaxIndex = incTempMaxIndex;
        tempItem.orderIndex = incTempMaxIndex;
        return tempItem;
      } else return tempItem;
    }).sort((a, b) => a.orderIndex - b.orderIndex).map((item, index) => {
      let tempItem = {...item};
      tempItem.orderIndex = index;
      return tempItem;
    });
  };

  const handleListBoxChange = data => {
    const parsedData = parseData(data);
    dispatch({
      type: 'update_configured_table_columns',
      payload: parsedData,
    });
    saveConfiguredData(parsedData);
  };

  const handleListBoxReset = () => {
    dispatch({
      type: 'update_configured_table_columns',
      payload: [],
    });
    saveConfiguredData([]);
  };

  const handleColumnOrderChange = (index, newIndex) => {
    let tempConfiguredColumns = [...configuredTableColumns];
    tempConfiguredColumns[index].orderIndex = newIndex;
    tempConfiguredColumns[newIndex].orderIndex = index;
    const sortedTableColumns = tempConfiguredColumns.sort((a, b) => a.orderIndex - b.orderIndex);
    dispatch({
      type: 'update_configured_table_columns',
      payload: sortedTableColumns,
    });
    saveConfiguredData(sortedTableColumns);
  };

  const handleSaveConfiguredTableColumnsChange = data => {
    const mergedTableColumns = mergeTableColumns(tableColumns, data);

    dispatch({
      type: 'update_all_table_columns',
      payload: {
        tableColumns: mergedTableColumns,
        configuredTableColumns: data,
      },
    });
  };

  const handleDeleteConfiguredTableColumn = index => {
    let tempConfiguredTableColumns = [...configuredTableColumns];
    tempConfiguredTableColumns.splice(index, 1);
    const mergedTableColumns = mergeTableColumns(tableColumns,
        tempConfiguredTableColumns);

    dispatch({
      type: 'update_all_table_columns',
      payload: {
        tableColumns: mergedTableColumns,
        configuredTableColumns: tempConfiguredTableColumns,
      },
    });
    saveConfiguredData(tempConfiguredTableColumns);
  };

  const saveConfiguredData = configuredTableColumns => {
    const tempConfiguredTableColumns = configuredTableColumns.map(item => {
      if (item?.filter?.source?.data) {
        const tempConfiguredTableColumn = {...item};
        const sourceData = tempConfiguredTableColumn.filter.source.data;
        tempConfiguredTableColumn.filter.source.data = typeof sourceData === 'object' ? JSON.stringify(sourceData) : sourceData
        return tempConfiguredTableColumn;
      }
      return item;
    });

    const requestPayload = {
      databaseName: dbName,
      collectionName: collectionName,
      namespace: dbName + '.' + collectionName,
      configuredTableColumns: tempConfiguredTableColumns,
      timeout: globalTimeout,
    };

    if(disableSaveDefault === true) requestPayload.saveDefaultFlag = saveDefaultFlag

    axios.post('/data-browser/admin/save-structure', requestPayload).then(response => {
      if (response.status === 200) {
        // alert("Table column updated successfully");
      }
    }).catch(error => {
      console.log(error);
    });
  };

  if (loading) {
    return (
      <div className="text-center pt-50 pb-50">
        <h3 className="text-center">
          <FontAwesomeIcon
              className="loading-icon"
              icon={faSpinner}
              spin
          /> Loading..</h3>
      </div>
    );
  }

  const buildSelectedTableColumnsSection = () => {
    return (
        <>
          {
            configuredTableColumns.length === 0 ? (
                <Col xs={12}>
                  <div className="db-alert warning mb-10">
                    <FontAwesomeIcon icon={faExclamationCircle}/>
                    <p>You haven't selected any column. Please add the columns.</p>
                  </div>
                </Col>
            ) : (
                <Col xs={12}>
                  <h4>Selected Table Columns :</h4>
                  <div className="table-column-list">
                    {
                      configuredTableColumns.map((item, index) => (
                          <DataTableColumnConfigurationRow
                              key={item.namespace}
                              index={index}
                              columnCount={configuredTableColumns.length}
                              data={item}
                              dbName={dbName}
                              collectionName={collectionName}
                              configuredTableColumns={configuredTableColumns}
                              saveDefaultFlag={saveDefaultFlag}
                              onOrderChange={handleColumnOrderChange}
                              onSave={handleSaveConfiguredTableColumnsChange}
                              onDelete={handleDeleteConfiguredTableColumn}
                          />
                      ))
                    }
                  </div>
                </Col>
            )
          }
        </>
    );
  };

  return (
      <Container fluid>
        <Row>
          <Col className="header-serction" xs={12}>
            <DataTableHeaderColumnPicker
                title="Select table columns"
                configuredTableColumns={tableColumns}
                filteredTableColumns={configuredTableColumns}
                onChange={handleListBoxChange}
                onReset={handleListBoxReset}
                doValidate={true}
            />
            {
              disableSaveDefault === false && (
                  <div className="save-default-switch-wrapper">
                    <label
                        htmlFor="save-default-flag-switch"
                        className="save-default-label mr-15"
                    >Save as Default :</label>
                    <Switch
                        onChange={checked => dispatch({
                          type: 'update_save_default_flag',
                          payload: checked,
                        })}
                        checked={saveDefaultFlag}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={40}
                        className="react-switch"
                        id="save-default-flag-switch"
                    />
                  </div>
              )
            }
          </Col>
          {buildSelectedTableColumnsSection()}
        </Row>
      </Container>
  );
};

export default DataTableColumnConfiguration;
