import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import DataTableHeader from '../data_table_header/DataTableHeader';
import SortableDataTableHeader from '../sortable_data_table_header/SortableDataTableHeader';
import DataTableRows from '../data_table_rows/DataTableRows';
import './DataTable.scss';
import noDataFoundIcon from '../../assets/images/no-data-icon.jpg';

const DataTable = (props) => {
	const tableRef = useRef(null);
	const lastFixedColNamespace = useRef('');
	const history = useHistory();

	const {
		loading,
		dbName,
		collectionName,
		columnHeaders,
		enableColumnFilters,
		data,
		sortData,
		onSortChange,
		filterData,
		onFilterChange,
		disablePagination,
		pagination,
		onPaginationChange,
		actionType,
    	onAction,
		tableHeight,
	} = props;

	useEffect(() => {
		let tempLastFixedColNamespace = '';
		columnHeaders.forEach((col) => {
			if (col?.fixed === true) {
				tempLastFixedColNamespace = col.namespace;
			}
		});
		lastFixedColNamespace.current = tempLastFixedColNamespace;
	}, [columnHeaders]);

	const { pageNumber, count, total } = pagination;

	const currPageStartValue = pageNumber * count;

	const buildColumnGroups = useMemo(
		() =>
			columnHeaders.map(({ width }, index) => {
				const parsedWidth = width ?? 240;
				return (
					<div
						className='dt-col'
						key={index}
						style={{ width: parsedWidth, minWidth: parsedWidth }}
					/>
				);
			}),
		[columnHeaders]
	);

	const buildColumnHeaders = () => {
		if (typeof columnHeaders !== 'undefined' || columnHeaders !== null) {
			return columnHeaders.map((currentColumnHeaderData, index) => {
				const { namespace, sortable } = currentColumnHeaderData;
				if (sortable) {
					return (
						<SortableDataTableHeader
							key={namespace}
							index={index}
							dbName={dbName}
							collectionName={collectionName}
							data={currentColumnHeaderData}
							columnHeaders={columnHeaders}
							enableColumnFilters={enableColumnFilters}
							lastFixedColNamespace={lastFixedColNamespace.current}
							sortData={sortData}
							onSortChange={onSortChange}
							filterData={filterData}
							onFilterChange={onFilterChange}
						/>
					);
				} else
					return (
						<DataTableHeader
							key={namespace}
							index={index}
							dbName={dbName}
							collectionName={collectionName}
							data={currentColumnHeaderData}
							columnHeaders={columnHeaders}
							enableColumnFilters={enableColumnFilters}
							lastFixedColNamespace={lastFixedColNamespace.current}
							filterData={filterData}
							onFilterChange={onFilterChange}
						/>
					);
			});
		}
	};

	const handlePaginationChange = ({ selected }) => {
		onPaginationChange({ ...pagination, pageNumber: selected });
	};

	const redirectToTableConfigurationPage = () => {
		history.push(
			`/app/admin/settings/db-name=${dbName}&collection-name=${collectionName}`
		);
	};

	if (loading === true && columnHeaders.length === 0) {
		return (
			<h3 className='text-center mt-50'>
				<FontAwesomeIcon className='loading-icon' icon={faSpinner} spin />{' '}
				Loading..
			</h3>
		);
	} else if (loading === false && columnHeaders.length === 0) {
		return (
			<div
				style={{
					display: 'block',
					width: '100%',
					textAlign: 'center',
					paddingTop: 50,
					paddingBottom: 50,
				}}
			>
				<img src={noDataFoundIcon} alt='Data not found' width={70} />
				<h2 className='mb-0'>Table Configuration</h2>
				<p className='mt-10 mb-5'>Please configure table to see the data</p>
				<button
					className='db-button db-button--primary'
					onClick={redirectToTableConfigurationPage}
				>
					Configure
				</button>
			</div>
		);
	}

	const wrapperClasses =
		typeof tableHeight === 'number' && tableHeight > 0
			? 'dt-wrapper fixed-table-header'
			: 'dt-wrapper';
	const wrapperStyles = {
		maxHeight:
			typeof tableHeight === 'number' && tableHeight > 0 ? tableHeight : 'auto',
	};

	return (
		<div className='dt-container'>
			<div className={wrapperClasses} style={wrapperStyles}>
				<div className={`dt ${collectionName}`} ref={tableRef}>
					{buildColumnGroups}
					<div className='dt-thead'>
						<div className='dt-tr'>{buildColumnHeaders()}</div>
					</div>
					<div
						className='dt-tbody'
						isEmpty={
							(typeof data === 'undefined' ||
								data === null ||
								data.length === 0) &&
							!loading
								? 'empty'
								: ''
						}
					>
						<DataTableRows
							loading={loading}
							data={data}
							columnHeaders={columnHeaders}
							rowCount={count}
							currPageStartValue={currPageStartValue}
							actionType={actionType}
                  			onAction={(type, index) => onAction && onAction(type, index)}
						/>
					</div>
				</div>
			</div>
			{disablePagination === false && (
				<div className='dt-pagination-container'>
					<div className='dt-pagination-container-left'>
						<ReactPaginate
							forcePage={pageNumber}
							disableInitialCallback={true}
							previousLabel={'Previous'}
							nextLabel={'Next'}
							breakLabel={'...'}
							breakClassName={'break-me'}
							pageCount={total / count}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePaginationChange}
							containerClassName={'dt-pagination'}
							pageLinkClassName={'dt-page-link'}
							activeClassName={'active'}
							previousClassName={'dt-prev-page'}
							previousLinkClassName={'dt-prev-page-link'}
							nextClassName={'dt-next-page'}
							nextLinkClassName={'dt-next-page-link'}
							disabledClassName={'disabled'}
						/>
					</div>
					<div className='dt-pagination-container-right'>
						<p>
							{currPageStartValue + 1} -{' '}
							{currPageStartValue + count > total
								? total
								: currPageStartValue + count}{' '}
							of {total} items
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

DataTable.propTypes = {
	loading: PropTypes.bool,
	columnHeaders: PropTypes.array,
	enableColumnFilters: PropTypes.bool,
	data: PropTypes.array,
	sortData: PropTypes.object,
	onSortChange: PropTypes.func,
	filterData: PropTypes.object,
	onFilterChange: PropTypes.func,
	disablePagination: PropTypes.bool,
	pagination: PropTypes.object,
	onPaginationChange: PropTypes.func,
	onAction: PropTypes.func,
	tableHeight: PropTypes.number,
};

DataTable.defaultProps = {
	loading: false,
	columnHeaders: [],
	enableColumnFilters: true,
	data: [],
	sortData: {},
	filterData: {},
	disablePagination: false,
	pagination: {
		pageNumber: 0,
		count: 10,
		total: 0,
	},
	tableHeight: 0,
};

export default DataTable;
