import React, { useState } from 'react';
import {
	calculateFixedWidthForColumn,
	getCalculatedTypeAndSubType,
	getPropByString,
} from '../../helpers/util';
import ReactTooltip from 'react-tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { CELL_WRAP_TYPE_CONSTANTS } from '../../helpers/constants';
import ShowMoreText from 'react-show-more-text';
import DBCodeEditor from '../db_code_editor/DBCodeEditor';
import Modal from '../modal/Modal';
import noDataImage from '../../assets/images/nodata.png';

const { WRAP_SCROLL_Y, CLIP, DYNAMIC, WRAP_SCROLL_X } =
	CELL_WRAP_TYPE_CONSTANTS;

const DataTableRows = (props) => {
	const [modalData, setModalData] = useState({
		isModalVisible: false,
		selectedDataField: '',
		modalContent: '',
	});
	const { isModalVisible, selectedDataField, modalContent } = modalData;
	const {
		loading = false,
		data,
		columnHeaders,
		rowCount,
		currPageStartValue,
		actionType,
    	onAction
	} = props;

	if (loading) {
		let i,
			tempRows = [];

		for (i = 0; i < rowCount; i++) {
			tempRows.push(
				<div className='dt-tr' key={i}>
					{columnHeaders.map((colHeader, rowTdIndex) => {
						const { key, fixed } = colHeader;
						const tdUniqueKey = `${key}-${i}${rowTdIndex}`;

						const getTdClassNames = () => {
							let classNames = 'dt-td loading wrap-dynamic';
							if (fixed === true) {
								classNames += ' fixed';
							}
							return classNames;
						};

						return (
							<div
								className={getTdClassNames()}
								style={
									fixed === true
										? {
												left:
													rowTdIndex === 0
														? 0
														: calculateFixedWidthForColumn(
																rowTdIndex,
																columnHeaders
														  ),
										  }
										: {}
								}
								key={tdUniqueKey}
							>
								<div className='bar' />
							</div>
						);
					})}
				</div>
			);
		}

		return tempRows;
	}

	if (typeof data === 'undefined' || data === null || data.length === 0) {
		return (
			<div className='dt-tbody-empty'>
				<img className='no-data-image' src={noDataImage} alt='No data found' />
				<p className='no-data-text'>No records found</p>
			</div>
		);
	}

	const closeModal = () => {
		setModalData({
			isModalVisible: false,
			selectedDataField: '',
			modalContent: '',
		});
	};

	return (
		<>
			{data.map((row, rowIndex) => (
				<div className='dt-tr' key={rowIndex}>
					{columnHeaders.map((colHeader, rowTdIndex) => {
						const {
							key,
							namespace,
							fixed,
							wrapType = 'wrap-dynamic',
							filter,
						} = colHeader;

					if (key === 'action') {

                      const handleEditClick = () => {
                        onAction && onAction('edit', rowIndex);
                      }

                      const handleDeleteClick = () => {
                        onAction && onAction('delete', rowIndex);
                      }

                      const getActionIcons = () => {

                        const editIcon = <>
                          <ReactTooltip id="edit-icon" effect="solid">Edit</ReactTooltip>
                          <FontAwesomeIcon icon={faEdit} className="cursor-pointer mr-5" data-tip data-for="edit-icon" onClick={handleEditClick}/>
                        </>;

                        const deleteIcon = <>
                          <ReactTooltip id="change-password-icon" effect="solid">Delete</ReactTooltip>
                          <FontAwesomeIcon icon={faTrashAlt} className="cursor-pointer ml-5" data-tip data-for="change-password-icon" onClick={handleDeleteClick}/>
                        </>

                        switch(actionType) {
                          case 'edit':
                          return editIcon
                          case 'delete':
                          return deleteIcon;
                          case 'none':
                            return null;
                          default:
                            return <>
                              {editIcon}
                              {deleteIcon}
                            </>
                        }

                      }

                      return (
                          <div className="dt-td text-center" key={rowTdIndex}>
                            {getActionIcons()}
                          </div>
                      );
                    } else {
							const { type = '', subType = '' } = filter ?? {};
							const { calculatedType, calculatedSubType } = getCalculatedTypeAndSubType(type, subType);
							const tdValue = namespace === '' ? currPageStartValue + rowIndex + 1 : getPropByString(row, namespace);
							const tdUniqueKey = `${key}-${rowIndex}${rowTdIndex}`;

							if (typeof tdValue === 'object') {
								const showObjectDataInModal = () => {
									setModalData({
										isModalVisible: true,
										selectedDataField: key,
										modalContent: tdValue,
									});
								};

								const tdClasses =
									fixed === true
										? `dt-td ${calculatedType} ${calculatedSubType} wrap-dynamic fixed link`
										: `dt-td ${calculatedType} ${calculatedSubType} wrap-dynamic link`;

								return (
									<div
										className={tdClasses}
										style={
											fixed === true
												? {
														left:
															rowTdIndex === 0
																? 0
																: calculateFixedWidthForColumn(
																		rowTdIndex,
																		columnHeaders
																),
												}
												: {}
										}
										data-class={key}
										key={tdUniqueKey}
										onClick={showObjectDataInModal}
									>
										View Data
									</div>
								);
							} else if (wrapType === CLIP) {
								return (
									<div
										className={
											fixed === true
												? `dt-td ${calculatedType} ${calculatedSubType} word-break fixed`
												: `dt-td ${calculatedType} ${calculatedSubType} word-break`
										}
										style={
											fixed === true
												? {
														left:
															rowTdIndex === 0
																? 0
																: calculateFixedWidthForColumn(
																		rowTdIndex,
																		columnHeaders
																),
												}
												: {}
										}
										key={tdUniqueKey}
										data-class={key}
									>
										<ShowMoreText
											lines={1}
											more='more'
											less='less'
											className='content-css'
											anchorClass='my-anchor-css-class'
											expanded={false}
										>
											{tdValue}
										</ShowMoreText>
									</div>
								);
							} else {
								const getTdClassNames = () => {
									let classNames = `dt-td ${calculatedType} ${calculatedSubType} `;

									if (fixed === true) {
										classNames += 'fixed ';
									}

									if (wrapType === WRAP_SCROLL_X) {
										classNames += 'wrap-scroll-x';
									} else if (wrapType === WRAP_SCROLL_Y) {
										classNames += 'wrap-scroll-y';
									} else if (wrapType === DYNAMIC) {
										classNames += 'dynamic';
									} else classNames += 'wrap-dynamic';

									return classNames;
								};

								return (
									<div
										className={getTdClassNames()}
										style={
											fixed === true
												? {
														left:
															rowTdIndex === 0
																? 0
																: calculateFixedWidthForColumn(
																		rowTdIndex,
																		columnHeaders
																),
												}
												: {}
										}
										key={tdUniqueKey}
										data-class={key}
									>
										{wrapType === WRAP_SCROLL_Y ? (
											<div className='wrap-scroll-y-inner'>{tdValue}</div>
										) : (
											calculatedSubType=='bool'?tdValue.toString():tdValue
										)}
									</div>
								);
							}
						}
					})}
				</div>
			))}
			<Modal
				title={`Data Field: ${selectedDataField}`}
				visible={isModalVisible}
				onCancel={closeModal}
			>
				<DBCodeEditor value={modalContent} readOnly={true} height='300px' />
			</Modal>
		</>
	);
};

export default DataTableRows;
