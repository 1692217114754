import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const DTHBoolFilterLayout = ({data, filterData, onFilterChange}) => {
  const [boolFilter, setBoolFilter] = useState([]);
  const [boolFilterCheckboxData, setBoolFilterCheckboxData] = useState({
    loading: true,
    checkboxList: [],
  });
  const didFirstMount = useRef(false);
  const {dbName, collectionName, key, namespace, filter: {subType, source, sourceType = 'dbQuery'}} = data;
  const {loading, checkboxList} = boolFilterCheckboxData;
  const calculatedSubType = subType ?? 'bool';

  useEffect(() => {
    if (calculatedSubType === 'bool') {
      setBoolFilterCheckboxData({
        loading: false,
        checkboxList: ['true', 'false'],
      });
    } else if (calculatedSubType === 'static') {
      if (source && source.data && source.data.length > 0) {
        const checkboxList = source.data.map(item => item.trim());
        setBoolFilterCheckboxData({
          loading: false,
          checkboxList,
        });
      } else {
        setBoolFilterCheckboxData({
          loading: false,
          checkboxList: ['true', 'false'],
        });
      }
    } else if (calculatedSubType === 'dynamic') {
      const { globalTimeout } = window['runConfig'];
      const requestPayload = {
        databaseName: dbName,
        collectionName: collectionName,
        fieldName: key,
        timeout: globalTimeout
      }

      axios.post('/data-browser/get-distinct', requestPayload).then(response => {
        if (response?.data?.code === 200) {
          const data = response?.data?.data ?? [];
          setBoolFilterCheckboxData({
            loading: false,
            checkboxList: Array.isArray(data) ? data : [],
          });
        } else {
          setBoolFilterCheckboxData(prevData => ({
            ...prevData,
            loading: false,
          }));
        }
      }).catch(() => {
        setBoolFilterCheckboxData(prevData => ({
          ...prevData,
          loading: false,
        }));
      });
    }
  }, []);

  useEffect(() => {
    if (didFirstMount.current) {
      setBoolFilter(filterData[namespace] ?? []);
    } else didFirstMount.current = true;
  }, [checkboxList, boolFilterCheckboxData, filterData, namespace]);

  const handleCheckboxChange = event => {
    const fieldName = event.target.name;
    const isChecked = event.target.checked;
    if (fieldName === 'all') {
      if (isChecked) {
        setBoolFilter([...['exist', 'nil', 'blank'], ...checkboxList]);
      } else setBoolFilter([]);
    } else {
      const checkboxIndex = boolFilter.indexOf(fieldName);
      let tempBoolFilter = [...boolFilter];
      if (checkboxIndex > -1) {
        tempBoolFilter.splice(checkboxIndex, 1);
      } else tempBoolFilter.push(fieldName);
      setBoolFilter(tempBoolFilter);
    }
  };

  const sendBoolFilterDataToParent = () => {
    let tempFilterData = {...filterData};
    tempFilterData[namespace] = boolFilter;
    onFilterChange(tempFilterData);
  };

  const resetBoolFilter = () => {
    let tempFilterData = {...filterData};
    if (tempFilterData.hasOwnProperty(namespace)) {
      delete tempFilterData[namespace];
      onFilterChange(tempFilterData);
    } else setBoolFilter([]);
  };

  const builtCheckboxList = checkboxList.map((cbName, index) => (
      <div className="checkbox-wrapper" key={`${cbName} + ${index}`}>
        <input
            type="checkbox"
            id={'' + cbName}
            name={'' + cbName}
            className="db-input"
            checked={boolFilter.includes('' + cbName)}
            onChange={handleCheckboxChange}
        />
        <label htmlFor={'' + cbName}> {'' + cbName}</label>
      </div>
  ));

  const checkboxCount = [...['exist', 'nil', 'blank'], ...checkboxList].length;

  return (
      <>
        <div className="dropdown-inner-section">
          {
            loading ? (
                <div className="loading-section">
                  <FontAwesomeIcon
                      className="loading-icon"
                      icon={faSpinner}
                      spin
                  />
                </div>
            ) : (
                <>
                  <div className="checkbox-wrapper">
                    <input
                        type="checkbox"
                        id="all"
                        name="all"
                        className="db-input"
                        checked={boolFilter.length === checkboxCount}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="all"> Select all</label>
                  </div>
                  {builtCheckboxList}
                  <div className="checkbox-wrapper">
                    <input
                        type="checkbox"
                        id="exist"
                        name="exist"
                        className="db-input"
                        checked={boolFilter.includes('exist')}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="exist"> Exist</label>
                  </div>
                  <div className="checkbox-wrapper">
                    <input
                        type="checkbox"
                        id="nil"
                        name="nil"
                        className="db-input"
                        checked={boolFilter.includes('nil')}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="nil"> Nil</label>
                  </div>
                  <div className="checkbox-wrapper">
                    <input
                        type="checkbox"
                        id="blank"
                        name="blank"
                        className="db-input"
                        checked={boolFilter.includes('blank')}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="blank"> Blank</label>
                  </div>
                </>
            )
          }
        </div>
        <div className="dropdown-inner-action-section">
          <button
              className="filter"
              type="button"
              onClick={sendBoolFilterDataToParent}
          >Filter
          </button>
          <button
              className="clear"
              type="button"
              onClick={resetBoolFilter}
          >Clear
          </button>
        </div>
      </>
  );
};

DTHBoolFilterLayout.propTypes = {
  data: PropTypes.object,
  filterData: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default DTHBoolFilterLayout;
