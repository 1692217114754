const {globalTimeout} = window['runConfig'];
const initialState = {
  loading: true,
  databaseName: '',
  databaseType: '',
  collectionName: '',
  globalNamespace: '',
  timeout: globalTimeout,
  tableColumns: [],
  configuredTableColumns: [],
  filteredTableColumns: [],
  isTableColumnsFiltered: false,
  data: [],
  dbQuery: '',
  dbMatchQuery: '',
  sortData: {},
  filterData: {},
  filterRows: {},
  pagination: {
    pageNumber: 0,
    count: 10,
    total: 0,
  },
  externalFilterRows: {},
  flattenExternalFilterRows: {},
  externalFiltersRowCount: 0,
  isJsonFilterActive: false,
};

const TableDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_loading_status':
      return {
        ...state,
        loading: action.payload,
      };
    case 'update_loading_status_and_reset_json_filter_flag':
      return {
        ...state,
        loading: action.payload,
        isJsonFilterActive: false
      };
    case 'update_table_config_data':
      return {
        ...state,
        databaseName: action.payload.databaseName,
        databaseType: action.payload.databaseType,
        collectionName: action.payload.collectionName,
        globalNamespace: action.payload.globalNamespace,
        tableColumns: action.payload.tableColumns,
        configuredTableColumns: action.payload.configuredTableColumns,
        filteredTableColumns: action.payload.configuredTableColumns,
        dbQuery: action.payload.dbQuery,
        dbMatchQuery: action.payload.dbMatchQuery,
        loading: action.payload.loading,
      };
    case 'update_filtered_columns':
      return {
        ...state,
        filteredTableColumns: action.payload.filteredTableColumns,
        isTableColumnsFiltered: action.payload.isTableColumnsFiltered,
      };
    case 'update_table_columns':
      return {
        ...state,
        tableColumns: action.payload,
      };
    case 'update_table_data':
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case 'update_table_sort_data':
      return {
        ...state,
        sortData: action.payload,
        pagination: {
          ...state.pagination,
          pageNumber: 0,
        },
      };
    case 'update_sort_data_and_database_query':
      return {
        ...state,
        sortData: action.payload.sortData,
        dbQuery: action.payload.dbQuery,
        dbMatchQuery: action.payload.dbMatchQuery,
        pagination: {
          ...state.pagination,
          pageNumber: 0,
        },
        loading: true,
      };
    case 'update_table_filter_data':
      return {
        ...state,
        filterData: action.payload,
      };
    case 'update_table_filter_row_data':
      return {
        ...state,
        filterRows: action.payload,
      };
    case 'update_db_query_and_json_filter_status':
      return {
        ...state,
        dbQuery: action.payload.dbQuery,
        isJsonFilterActive: action.payload.isJsonFilterActive,
        pagination: {
          ...state.pagination,
          pageNumber: 0,
        },
        loading: true,
      };
    case 'update_db_query_and_table_current_page_number':
      return {
        ...state,
        dbQuery: action.payload.dbQuery,
        dbMatchQuery: action.payload.dbMatchQuery,
        pagination: {
          ...state.pagination,
          pageNumber: action.payload.pageNUmber
        },
        loading: true,
      };
    case 'update_table_current_page_number':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageNumber: action.payload,
        },
        loading: true,
      };
    case 'update_table_total_record_count':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: action.payload,
        },
      };
    case 'update_external_filter_rows':
      return {
        ...state,
        externalFilterRows: action.payload.externalFilterRows,
        flattenExternalFilterRows: action.payload.flattenExternalFilterRows,
      };
    case 'update_external_filter_rows_and_count':
      return {
        ...state,
        externalFilterRows: action.payload.externalFilterRows,
        externalFiltersRowCount: action.payload.externalFiltersRowCount,
        flattenExternalFilterRows: action.payload.flattenExternalFilterRows,
      };
    case 'update_table_filter_and_row_data':
      return {
        ...state,
        filterData: action.payload.filterData,
        filterRows: action.payload.filterRows,
      };
    case 'update_table_filter_row_data_and_database_query':
      return {
        ...state,
        filterData: action.payload.filterData,
        filterRows: action.payload.filterRows,
        dbQuery: action.payload.dbQuery,
        dbMatchQuery: action.payload.dbMatchQuery,
        pagination: {
          ...state.pagination,
          pageNumber: 0,
        },
        isJsonFilterActive: false,
        loading: true,
      };
    case 'update_db_query':
      return {
        ...state,
        dbQuery: action.payload,
        pagination: {
          ...state.pagination,
          pageNumber: 0,
        },
        loading: true,
      };
    case 'update_timeout':
      return {
        ...state,
        timeout: action.payload,
      };
    case 'reset_external_filters_data':
      return {
        ...state,
        sortData: {},
        filterRows: {},
        filterData: {},
        externalFilterRows: {},
        flattenExternalFilterRows: {},
        externalFiltersRowCount: 0,
        dbQuery: action.payload.dbQuery,
        dbMatchQuery: action.payload.dbMatchQuery,
        pagination: {
          ...state.pagination,
          pageNumber: 0,
        },
        isJsonFilterActive: false,
        loading: true
      };
    case 'reset_table_data':
      return initialState;
    default:
      return state;
  }
};

export default TableDataReducer;
