import React, {useReducer} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import {downloadFileFromUrl} from '../../helpers/util';
import './ExportButtons.scss';
import exportExcelIcon from '../../assets/images/export-excel.png';
import exportCsvIcon from '../../assets/images/export-csv.png';
import {faSpinner, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UPDATE_GLOBAL_ERROR_DATA} from '../../redux/actions/types';

const initialState = {
  excelButtonData: {
    dropdownVisibility: false,
    status: '',
    fileData: {
      name: '',
      url: '',
    },
  },
  csvButtonData: {
    dropdownVisibility: false,
    status: '',
    fileData: {
      name: '',
      url: '',
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_excel_button_data':
      return {
        ...state,
        excelButtonData: action.payload,
      };
    case 'update_csv_button_data':
      return {
        ...state,
        csvButtonData: action.payload,
      };
    default:
      return state;
  }
};

const ExportButtons = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    databaseName,
    collectionName,
    dbQuery,
    timeout,
  } = useSelector(state => state.tableData);
  const reduxDispatch = useDispatch();
  const {domainName} = window['runConfig'];
  const {
    excelButtonData: {
      dropdownVisibility: excelDropdownVisibility,
      status: excelStatus,
      fileData: {
        name: excelFileName,
        url: excelFileUrl,
      },
    },
    csvButtonData: {
      dropdownVisibility: csvDropdownVisibility,
      status: csvStatus,
      fileData: {
        name: csvFileName,
        url: csvFileUrl,
      },
    },
  } = state;

  const openExcelDropdown = () => {
    dispatch({
      type: 'update_excel_button_data',
      payload: {
        dropdownVisibility: true,
        status: 'loading',
        fileData: {
          name: '',
          url: '',
        },
      },
    });
    exportToExcel();
  };

  const closeExcelDropdown = () => {
    dispatch({
      type: 'update_excel_button_data',
      payload: {
        dropdownVisibility: false,
        status: '',
        fileData: {
          name: '',
          url: '',
        },
      },
    });
  };

  const openCsvDropdown = () => {
    dispatch({
      type: 'update_csv_button_data',
      payload: {
        dropdownVisibility: true,
        status: 'loading',
        fileData: {
          name: '',
          url: '',
        },
      },
    });
    exportToCSV();
  };

  const closeCsvDropdown = () => {
    dispatch({
      type: 'update_csv_button_data',
      payload: {
        dropdownVisibility: false,
        status: '',
        fileData: {
          name: '',
          url: '',
        },
      },
    });
  };

  const exportToExcel = () => {
    const reqData = {
      databaseName,
      collectionName,
      query: dbQuery,
      timeout,
    };

    axios.post('/data-browser/export-excel', reqData, {
      headers: {
        'X-CLIENT-ID': 'export-excel',
      },
    }).then(response => {
      console.log(response);
      if (response.status === 200) {
        const filePath = response.data.data;
        const splitFilePath = filePath.split('/');
        const fileName = splitFilePath[splitFilePath.length - 1];
        dispatch({
          type: 'update_excel_button_data',
          payload: {
            dropdownVisibility: true,
            status: 'success',
            fileData: {
              name: fileName,
              url: domainName + '/' + filePath,
            },
          },
        });
      } else dispatch({
        type: 'update_excel_button_data',
        payload: {
          dropdownVisibility: true,
          status: 'fail',
          fileData: {
            name: '',
            url: '',
          },
        },
      });
    }).catch(error => {
      if(error?.response?.data?.message){
					reduxDispatch({
						type: UPDATE_GLOBAL_ERROR_DATA,
						payload: {
							visibility: true,
							errors: error.response.data.message
						}
					});
				}
      dispatch({
        type: 'update_excel_button_data',
        payload: {
          dropdownVisibility: true,
          status: 'fail',
          fileData: {
            name: '',
            url: '',
          },
        },
      });
    });
  };

  const exportToCSV = () => {
    const reqData = {
      databaseName,
      collectionName,
      query: dbQuery,
      timeout,
    };

    axios.post('/data-browser/export-csv', reqData, {
      headers: {
        'X-CLIENT-ID': 'export-csv',
      },
    }).then(response => {
      if (response.status === 200) {
        const filePath = response.data.data;
        const splitFilePath = filePath.split('/');
        const fileName = splitFilePath[splitFilePath.length - 1];
        dispatch({
          type: 'update_csv_button_data',
          payload: {
            dropdownVisibility: true,
            status: 'success',
            fileData: {
              name: fileName,
              url: domainName + '/' + filePath,
            },
          },
        });
      } else dispatch({
        type: 'update_csv_button_data',
        payload: {
          dropdownVisibility: true,
          status: 'fail',
          fileData: {
            name: '',
            url: '',
          },
        },
      });
    }).catch(() => {
      dispatch({
        type: 'update_csv_button_data',
        payload: {
          dropdownVisibility: true,
          status: 'fail',
          fileData: {
            name: '',
            url: '',
          },
        },
      });
    });
  };

  const downloadExcel = () => {
    downloadFileFromUrl(excelFileName, excelFileUrl);
  };

  const downloadCSV = () => {
    downloadFileFromUrl(csvFileName, csvFileUrl);
  };

  return (
      <div style={{position: 'relative'}}>
        <img
            className="cursor-pointer"
            src={exportExcelIcon}
            width={30}
            alt="export excel"
            data-tip
            data-for="export-excel"
            onClick={openExcelDropdown}
        />
        <ReactTooltip id="export-excel" place="left" effect="solid">
          <span>Export as a Excel</span>
        </ReactTooltip>
        {
          excelDropdownVisibility && (
              <div className="eb-dropdown">
                <div className="eb-dropdown-header">
                  <div className="eb-dropdown-header-left">
                    <p className="title">Export excel :</p>
                  </div>
                  <div className="eb-dropdown-header-right">
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="close-icon"
                        onClick={closeExcelDropdown}
                    />
                  </div>
                </div>
                <div className="eb-dropdown-content">
                  {excelStatus === 'loading' &&
                  <p><FontAwesomeIcon className="mr-5" icon={faSpinner} spin/> Loading..</p>}
                  {
                    excelStatus === 'success' && (
                        <>
                          <p>File is ready to download.</p>
                          <button className="db-button db-button--primary"
                                  onClick={downloadExcel}>Download
                          </button>
                        </>
                    )
                  }
                  {excelStatus === 'fail' &&
                  <p>Something went wrong. Please try again.</p>}
                </div>
              </div>
          )
        }
        <img
            className="cursor-pointer ml-5"
            src={exportCsvIcon}
            width={30}
            alt="export csv"
            data-tip
            data-for="export-csv"
            onClick={openCsvDropdown}
        />
        <ReactTooltip id="export-csv" place="left" effect="solid">
          <span>Export as a CSV</span>
        </ReactTooltip>
        {
          csvDropdownVisibility && (
              <div className="eb-dropdown">
                <div className="eb-dropdown-header">
                  <div className="eb-dropdown-header-left">
                    <p className="title">Export CSV :</p>
                  </div>
                  <div className="eb-dropdown-header-right">
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="close-icon"
                        onClick={closeCsvDropdown}
                    />
                  </div>
                </div>
                <div className="eb-dropdown-content">
                  {csvStatus === 'loading' &&
                  <p><FontAwesomeIcon className="mr-5" icon={faSpinner}
                                      spin/> Loading..</p>}
                  {
                    csvStatus === 'success' && (
                        <>
                          <p>File is ready to download.</p>
                          <button
                              className="db-button db-button--primary"
                              onClick={downloadCSV}>Download
                          </button>
                        </>
                    )
                  }
                  {csvStatus === 'fail' &&
                  <p>Something went wrong. Please try again.</p>}
                </div>
              </div>
          )
        }
      </div>
  );
};

export default ExportButtons;
