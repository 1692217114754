import React from 'react';
import {Col, Row} from 'react-grid-system';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import DBCodeEditor from '../db_code_editor/DBCodeEditor';
import ConfigurableTableColumnFilterStaticSourceData
  from './ConfigurableTableColumnFilterStaticSourceData';
import {getCalculatedTypeAndSubType, parseSubType} from '../../helpers/util';
import Switch from 'react-switch';

const ConfigurableTableColumnFilterRow = props => {
  const {
    dbName,
    collectionName,
    data,
    onFieldsChange,
    onStaticDataChange,
    onDynamicDataChange,
    onCacheChange
  } = props;
  const {filterTypeAndSubTypes} = window['runConfig'];
  const {type, subType, sourceType, source} = data ?? {};

  const {calculatedType, calculatedSubType} = getCalculatedTypeAndSubType(type, subType);
  const calculatedSourceType = sourceType ?? 'dbQuery';
  const sourceUrl = source?.url ?? '';
  const isCached = data?.isCached ?? false;
  const sourceData = calculatedSubType === 'bool' ? [true, false] : calculatedSubType === 'static' ? source?.data ?? [] : source?.data ?? {};

  const buildSubTypeOptions = () => {
    return filterTypeAndSubTypes[calculatedType] ? filterTypeAndSubTypes[calculatedType].map(st => <option key={st} value={st}>{parseSubType(st)}</option>) : [];
  };

  const isSubTypeDisabled = !['string', 'date', 'enum'].includes(calculatedType);

  return (
      <>
        <Row gutterWidth={15}>
          <Col xs={12}>
            <p className="strike-text"><span>Filter :</span></p>
          </Col>
          <Col className="mt-5" xs={3}>
            <label
                htmlFor="filter-type"
                className="column-section-label"
            >Type :</label>
            <select
                className="db-input"
                name="filter-type"
                value={calculatedType}
                onChange={event => onFieldsChange(event, 'string')}
            >
              <option value={'none'}>None</option>
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="enum">Enum(Range)</option>
              <option value="date">Date</option>
              <option value="default">Default</option>
            </select>
          </Col>
          <Col className="mt-5" xs={3}>
            <label
                htmlFor="filter-subType"
                className="column-section-label"
            >Sub-Type :</label>
            <select
                className="db-input"
                name="filter-subType"
                disabled={isSubTypeDisabled}
                value={calculatedSubType}
                onChange={event => onFieldsChange(event, 'string')}
            >
              {buildSubTypeOptions()}
            </select>
          </Col>
          {
            (calculatedType === 'enum' && calculatedSubType === 'dynamic') && (
                <Col className="mt-5" xs={3}>
                  <label
                      htmlFor="filter-sourceType"
                      className="column-section-label"
                  >Source Type:</label>
                  <select
                      className="db-input"
                      name="filter-sourceType"
                      value={calculatedSourceType}
                      onChange={event => onFieldsChange(event, 'string')}
                  >
                    <option value="dbQuery">Database Query</option>
                    <option value="url">Url</option>
                  </select>
                </Col>
            )
          }
          {
            (calculatedType === 'enum' && calculatedSubType === 'dynamic') && (
              <Col className="mt-10" xs={3}>
                <div className="column-section-switch-wrapper">
                  <label
                      htmlFor="cache-switch"
                      className="column-section-label mr-15"
                  >Cached :</label>
                  <Switch
                      onChange={onCacheChange}
                      checked={isCached}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={15}
                      width={40}
                      className="react-switch"
                      id="cache-switch"
                  />
                </div>
              </Col>
            )
          }
          {
            (calculatedType === 'enum' && calculatedSubType === 'dynamic' && calculatedSourceType === 'url') && (
                <Col className="mt-5" xs={3}>
                  <label
                      htmlFor="filter-source-url"
                      className="column-section-label"
                  >Url :</label>
                  <input
                      type="text"
                      className="db-input"
                      name="filter-source-url"
                      value={sourceUrl}
                      onChange={event => onFieldsChange(event, 'string')}
                  />
                </Col>
            )
          }
          
        </Row>
        <Row gutterWidth={15}>
          {
            (calculatedType === 'enum' && calculatedSubType === 'static') && (
                <ConfigurableTableColumnFilterStaticSourceData
                    sourceData={sourceData}
                    onChange={onStaticDataChange}
                />
            )
          }
          {
            (calculatedType === 'enum' && calculatedSubType === 'dynamic' && calculatedSourceType === 'dbQuery') && (
                <Col className="mt-5" xs={12}>
                  <Row>
                    <Col xs={6}>
                      <label
                          htmlFor="filter-source-data"
                          className="column-section-label"
                      >Database Query :</label>
                    </Col>
                    <Col xs={6} className="text-right">
                      <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="column-section-information-icon"
                          data-tip
                          data-for="db-query-tooltip"
                      />
                      <ReactTooltip
                          id="db-query-tooltip"
                          globalEventOff="click"
                          aria-haspopup="true"
                          className="db-query-tooltip-section"
                      >
                        <Row className="text-left">
                          <Col xs={12} className="mb-10">
                            <span>Sample DB query :</span>
                          </Col>
                          <Col xs={12} className="mb-10">
                            <span>
                              {
                                `{
                                  databaseName: "${dbName}",
                                  collectionName: "${collectionName}",
                                  query: {},
                                  fieldName: "",
                                  timeout: "10s"
                                }`
                              }
                            </span>
                          </Col>
                        </Row>
                      </ReactTooltip>
                    </Col>
                  </Row>
                  <DBCodeEditor
                      className="mt-6"
                      height="200px"
                      value={sourceData}
                      onChange={onDynamicDataChange}
                  />
                </Col>
            )
          }
        </Row>
      </>

  );
};

export default ConfigurableTableColumnFilterRow;
