export const UPDATE_DATABASE_COLLECTION_NAMES_LOADING = 'update_database_collection_names_loading';
export const UPDATE_DATABASE_COLLECTION_NAMES = 'update_database_collection_names';
export const UPDATE_TABLE_FILTER_DATA = 'update_table_filter_data';
export const UPDATE_API_REQUEST_IDS = 'update_api_request_ids';
export const UPDATE_BULK_API_PERMISSIONS = 'update_bulk_api_permissions';
export const UPDATE_GLOBAL_ERROR_DATA = 'update_global_error_data';

export const UPDATE_ADMIN_DATABASE_COLLECTION_NAMES_LOADING = 'update_admin_database_collection_names_loading';
export const UPDATE_ADMIN_DATABASE_COLLECTION_NAMES = 'update_admin_database_collection_names';
export const UPDATE_ADMIN_BULK_API_PERMISSIONS = 'update_admin_bulk_api_permissions';

export const UPDATE_USER_DATA = 'update_user_data';
export const UPDATE_USER_DATA_STATUS = 'update_user_data_status';
