import {useEffect, useRef} from 'react';

export const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else didMountRef.current = true;
  }, inputs);
};

export const useComponentWillMount = func => {
  const willMount = useRef(true);
  if (willMount.current) func();
  willMount.current = false;
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => ref.current = value);
  return ref.current;
};
