import {
	faCaretDown,
	faDatabase,
	faExclamationCircle,
	faSpinner,
	faTags,
	faUser,
	faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import {
	isEmptyObject,
	slugify,
	sortDatabaseCollections,
} from '../../helpers/util';
import {
	UPDATE_ADMIN_BULK_API_PERMISSIONS,
	UPDATE_ADMIN_DATABASE_COLLECTION_NAMES,
	UPDATE_ADMIN_DATABASE_COLLECTION_NAMES_LOADING,
} from '../../redux/actions/types';

const SettingsSidebar = () => {
	const {
		adminLoadingDCNames,
		adminDatabaseCollectionNames,
		adminDatabaseNames,
		adminBulkUrls,
	} = useSelector((state) => state.util);
	const dispatch = useDispatch();
	const { url } = useRouteMatch();
	const { globalTimeout } = window['runConfig'];

	useEffect(() => {
		const getAdminCollectionDatabaseNames = () => {
			let adminDatabaseNames = [];
			let adminDatabaseCollectionNames = {};
			let configStateDic = {};

			axios
				.post('/data-browser/admin/database-collection-names', {
					timeout: globalTimeout,
				})
				.then((response) => {
					if (response.status === 200) {
						const data = sortDatabaseCollections(response?.data?.data ?? {});

						for (let dbName in data) {
							if (dbName && data.hasOwnProperty(dbName)) {
								adminDatabaseNames.push(dbName);
								data[dbName].forEach((coll) => {
									configStateDic[`${dbName}--${coll.collection_name}`] =
										coll.config_state;
								});
							}
						}

						axios
							.post('/data-browser/admin/get-tags', {
								timeout: globalTimeout,
							})
							.then((response) => {
								if (response.status === 200) {
									const dataRaw = response?.data?.data ?? {};
									let tags = {},
										tagsName = [];
									for (const k in dataRaw) {
										if (Object.hasOwnProperty.call(dataRaw, k)) {
											const element = dataRaw[k];
											const databases =
												element.databases == null ? {} : element.databases;
											if (Object.keys(databases).length > 0) {
												tagsName.push(`${element.label}__${element.color}`);
												tags[`${element.label}__${element.color}`] = [];
												for (const dbName in databases) {
													if (Object.hasOwnProperty.call(databases, dbName)) {
														const cols = databases[dbName];
														cols.forEach((collName) => {
															tags[`${element.label}__${element.color}`].push({
																collection_name: collName,
																config_state:
																	configStateDic[`${dbName}--${collName}`],
																db_name: dbName,
																type: 'tags',
																tagSlug: slugify(element.label),
															});
														});
													}
												}
											}
										}
									}
									adminDatabaseNames = [...tagsName, ...adminDatabaseNames];
									adminDatabaseCollectionNames = {
										...sortDatabaseCollections(tags),
										...data,
									};
								}

								dispatch({
									type: UPDATE_ADMIN_DATABASE_COLLECTION_NAMES,
									payload: {
										adminDatabaseNames,
										adminDatabaseCollectionNames,
									},
								});
							});
					} else {
						dispatch({
							type: UPDATE_ADMIN_DATABASE_COLLECTION_NAMES_LOADING,
							payload: false,
						});
					}
				})
				.catch((error) => {
					console.log(error.response);
					dispatch({
						type: UPDATE_ADMIN_DATABASE_COLLECTION_NAMES_LOADING,
						payload: false,
					});
				});
		};

		const checkBulkUrlAccess = () => {
			axios
				.post('/data-browser/bulk-check-permission', {
					urls: [
						'/app/admin/generic-settings/',
						'/app/admin/security-settings/restrictions/',
					],
				})
				.then((response) => {
					if (response.status === 200) {
						const data = response?.data?.data ?? [];
						let urlStatusList = {};
						data.forEach(({ url, status }) => {
							urlStatusList[url] = status;
						});
						dispatch({
							type: UPDATE_ADMIN_BULK_API_PERMISSIONS,
							payload: urlStatusList,
						});
					}
				})
				.catch((error) => {
					console.log(error.response);
				});
		};

		if (isEmptyObject(adminDatabaseCollectionNames)) {
			dispatch({
				type: UPDATE_ADMIN_DATABASE_COLLECTION_NAMES_LOADING,
				payload: true,
			});
			getAdminCollectionDatabaseNames();
		}
		if (isEmptyObject(adminBulkUrls)) {
			dispatch({
				type: UPDATE_ADMIN_BULK_API_PERMISSIONS,
				payload: [],
			});
			checkBulkUrlAccess();
		}
	}, []);

	if (adminLoadingDCNames) {
		return (
			<div className='db-settings-left-section sidenav'>
				<h2 className='mt-30 text-center'>
					<FontAwesomeIcon className='mr-5' icon={faSpinner} spin />
				</h2>
			</div>
		);
	}

	const getConfigState = (configState) => {
		return configState === 'not-configured' ? (
			<FontAwesomeIcon icon={faExclamationCircle} />
		) : configState === 'admin-configured' ? (
			<FontAwesomeIcon icon={faUserCog} />
		) : configState === 'user-configured' ? (
			<FontAwesomeIcon icon={faUser} />
		) : (
			''
		);
	};

	const collectionSidebar = () =>
		adminDatabaseNames.map((dbName, dbIndex) => (
			<li key={dbName + dbIndex}>
				<div
					className='expand-collections pointer'
					onClick={(x) => {
						const target = x.target.closest('.expand-collections');
						target.lastChild.classList.toggle('expanded');
						target.nextSibling.classList.toggle('expand');
					}}
				>
					<span>
						{dbName.split('__').length > 1 ? (
							<FontAwesomeIcon
								color={dbName.split('__')[1]}
								icon={faTags}
								className='tags-icon'
							/>
						) : (
							<FontAwesomeIcon icon={faDatabase} className='database-icon' />
						)}
						{dbName.split('__').length > 1 ? dbName.split('__')[0] : dbName}
					</span>
					<FontAwesomeIcon icon={faCaretDown} className='expand-icon' />
				</div>
				<ul className='sidenav-child'>
					{adminDatabaseCollectionNames[dbName].map(
						(
							{
								db_name = '',
								collection_name = '--',
								config_state = false,
								type = '',
								tagSlug,
							},
							colIndex
						) => (
							<li
								key={`${dbName}-${collection_name}-${colIndex}`}
								data-class={config_state}
							>
								<NavLink
									to={
										type === 'tags'
											? `${url}/settings/tag=${tagSlug}&db-name=${db_name}&collection-name=${collection_name}`
											: `${url}/settings/db-name=${dbName}&collection-name=${collection_name}`
									}
									activeClassName='active'
									title={collection_name}
								>
									<div className='label'>{collection_name}</div>
									{getConfigState(config_state)}
								</NavLink>
							</li>
						)
					)}
				</ul>
			</li>
		));

	const genericSettingsPageStatus =
		adminBulkUrls?.['/app/admin/generic-settings/'] ?? '';
	const userRestrictionsPageStatus =
		adminBulkUrls?.['/app/admin/security-settings/restrictions/'] ?? '';

	if (
		genericSettingsPageStatus !== 200 &&
		userRestrictionsPageStatus !== 200 &&
		adminDatabaseNames.length === 0
	) {
		return null;
	}

	return (
		<div className='db-settings-left-section sidenav'>
			<ul className='sidenav-parent'>
				{genericSettingsPageStatus === 200 && (
					<li>
						General Settings
						<ul className='sidenav-child expand'>
							<li>
								<NavLink
									to={`${url}/generic-settings/cache/`}
									activeClassName='active'
								>
									Cache
								</NavLink>
							</li>
							<li>
								<NavLink
									to={`${url}/generic-settings/tags`}
									activeClassName='active'
								>
									Tags
								</NavLink>
							</li>
						</ul>
					</li>
				)}
				{userRestrictionsPageStatus == 200 && (
					<li>
						Security Settings
						<ul className='sidenav-child expand'>
							<li>
								<NavLink
									to={`${url}/security-settings/restrictions/`}
									activeClassName='active'
								>
									User Restrictions
								</NavLink>
							</li>
						</ul>
					</li>
				)}
				{collectionSidebar()}
			</ul>
		</div>
	);
};

export default SettingsSidebar;
