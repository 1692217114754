import {combineReducers} from 'redux';
import UtilReducer from './UtilReducer';
import TableDataReducer from './TableDataReducer';
import UserDataReducer from './UserDataReducer';

export default combineReducers({
  tableData: TableDataReducer,
  userData: UserDataReducer,
  util: UtilReducer,
});
