import { getParameterByName } from '../../helpers/util';
import {
  UPDATE_ADMIN_BULK_API_PERMISSIONS,
  UPDATE_ADMIN_DATABASE_COLLECTION_NAMES,
  UPDATE_ADMIN_DATABASE_COLLECTION_NAMES_LOADING,
  UPDATE_API_REQUEST_IDS,
  UPDATE_BULK_API_PERMISSIONS,
  UPDATE_DATABASE_COLLECTION_NAMES,
  UPDATE_DATABASE_COLLECTION_NAMES_LOADING,
  UPDATE_GLOBAL_ERROR_DATA
} from '../actions/types';

const initialState = {
  isFullScreen: getParameterByName('screenSize') === 'fullscreen',
  defaultFilterVisibility: getParameterByName('filterVisibility') == 'true',
  enableColumnFilters: true,
  devMode: true,
  enableDownload: true,
  loadingDCNames: true,
  databaseCollectionNames: {},
  databaseNames: [],
  apiRequestIds: [],
  bulkUrls: {},
  // Settings sidebar related data
  adminLoadingDCNames: true,
  adminDatabaseCollectionNames: {},
  adminDatabaseNames: [],
  adminBulkUrls: {},
  alertModalData: {
    visibility: false,
    errors: '',
  }
};

const UtilReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_pre_defined_data':
      return {
        ...state,
        defaultDatabaseName: action.payload.defaultDatabaseName,
        defaultCollectionName: action.payload.defaultCollectionName,
        isFullScreen: action.payload.isFullScreen,
        defaultFilterVisibility: action.payload.defaultFilterVisibility,
        enableColumnFilters: action.payload.enableColumnFilters,
        devMode: action.payload.devMode,
        enableDownload: action.payload.enableDownload
      };
    case 'update_screen_size':
      return {
        ...state,
        isFullScreen: action.payload,
      };
    case UPDATE_DATABASE_COLLECTION_NAMES:
      return {
        ...state,
        databaseCollectionNames: action.payload.databaseCollectionNames,
        databaseNames: action.payload.databaseNames,
        loadingDCNames: false,
      };
    case UPDATE_DATABASE_COLLECTION_NAMES_LOADING:
      return {
        ...state,
        loadingDCNames: action.payload,
      };
    case UPDATE_BULK_API_PERMISSIONS:
      return {
        ...state,
        bulkUrls: action.payload,
      };
    case UPDATE_ADMIN_DATABASE_COLLECTION_NAMES:
      return {
        ...state,
        adminDatabaseCollectionNames: action.payload.adminDatabaseCollectionNames,
        adminDatabaseNames: action.payload.adminDatabaseNames,
        adminLoadingDCNames: false,
      };
    case UPDATE_ADMIN_DATABASE_COLLECTION_NAMES_LOADING:
      return {
        ...state,
        adminLoadingDCNames: action.payload,
      };
    case UPDATE_ADMIN_BULK_API_PERMISSIONS:
      return {
        ...state,
        adminBulkUrls: action.payload,
      };
    case UPDATE_API_REQUEST_IDS:
      return {
        ...state,
        apiRequestIds: action.payload,
      };
    case UPDATE_GLOBAL_ERROR_DATA:
      return {
        ...state,
        alertModalData: action.payload
      };
    default:
      return state;
  }
};

export default UtilReducer;
