import React, {useEffect, useReducer} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import axios from 'axios';
import {withPageAccessCheck} from '../../helpers/withPageAccessCheck';
import {addAlertToDOM} from '../../helpers/util';
import {UPDATE_GLOBAL_ERROR_DATA} from '../../redux/actions/types';
import { useDispatch } from 'react-redux';

const initialState = {
  cacheData: {},
  usernameKey: 'all',
  dcNamespaceKey: 'all',
  cacheKey: 'all',

  dcNamespace: 'all',
  selectedDCNamespaceKey: 'all',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_cache_data':
      return {
        ...state,
        cacheData: action.payload,
      };
    case 'update_cache_data_and_selections':
      return {
        ...state,
        cacheData: action.payload,
        usernameKey: 'all',
        dcNamespace: 'all',
        selectedDCNamespaceKey: 'all'
      }
    case 'update_username_key':
      return {
        ...state,
        usernameKey: action.payload,
        dcNamespace: 'all',
        selectedDCNamespaceKey: 'all',
      };
    case 'update_dc_namespace':
      return {
        ...state,
        dcNamespace: action.payload,
        selectedDCNamespaceKey: 'all',
      };
    case 'update_selected_dc_namespace_key':
      return {
        ...state,
        selectedDCNamespaceKey: action.payload,
      };
    case 'update_cache_data_and_dc_namespace':
      return {
        ...state,
        cacheData: action.cacheData,
        dcNamespace: action.payload.dcNamespace,
      };
    case 'update_cache_data_and_dc_namespace_key':
      return {
        ...state,
        cacheData: action.payload.cacheData,
        selectedDCNamespaceKey: action.payload.selectedDCNamespaceKey,
      };
    default:
      return state;
  }
};

const GenericSettings = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const {
    cacheData,
    usernameKey,
    dcNamespace,
    selectedDCNamespaceKey,
  } = state;
  const {globalTimeout} = window['runConfig'];

  useEffect(() => {
    getCacheKeys();
  }, []);

  const getCacheKeys = () => {
    axios.post('/data-browser/admin/get-cache-keys', {
      namespace: '',
      timeout: globalTimeout,
    }).then(response => {
      if (response.status === 200) {
        const data = response?.data?.data ?? {};
        // const data = {
        //   'asif@eaciit.com': {
        //     'RIDE_RG_NEW': {
        //       'GlobalAlert': [
        //         'Asif GlobalAlert key',
        //       ],
        //       'RPTDETSummary': [
        //         'Asif RPTDETSummary key',
        //       ],
        //     },
        //     'record_snapshot': {
        //       'snapshots': [
        //         'asif snapshot key',
        //       ],
        //     },
        //   },
        //   'cron@eaciit.com': {
        //     'RIDE_RG_NEW': {
        //       'GlobalAlert': [
        //         'Cron GlobalAlert key 2',
        //         'Cron GlobalAlert key 3',
        //         'Cron GlobalAlert key',
        //       ],
        //       'RPTDETSummary': [
        //         'Cron RPTDETSummary key ',
        //       ],
        //     },
        //   },
        // };
        let tempCacheData = {};

        for (const username in data) {
          if (data.hasOwnProperty(username)) {
            tempCacheData[username] = {};
            const usernameData = data[username];
            for (const dbKey in usernameData) {
              if (usernameData.hasOwnProperty(dbKey)) {
                const collData = usernameData[dbKey];
                for (const collKey in collData) {
                  if (collData.hasOwnProperty(collKey)) {
                    tempCacheData[username][`${dbKey}---${collKey}`] = collData[collKey];
                  } else delete tempCacheData[username];
                }
              } else delete tempCacheData[username];
            }
          }
        }

        dispatch({
          type: 'update_cache_data',
          payload: tempCacheData,
        });
      }
    }).catch(() => {
      console.log('Error');
    });
  };

  const handleSelectFieldChange = event => {
    const {name, value} = event.target;
    if (name === 'usernameKey') {
      dispatch({
        type: 'update_username_key',
        payload: value,
      });
    } else if (name === 'dcNamespace') {
      dispatch({
        type: 'update_dc_namespace',
        payload: value,
      });
    } else dispatch({
      type: 'update_selected_dc_namespace_key',
      payload: value,
    });
  };

  const clearCache = () => {
    const splitNamespace = dcNamespace.split('---');
    const requestPayload = {
      targetUsername: usernameKey === 'all' ? '' : usernameKey,
      databaseName: dcNamespace === 'all' ? '' : splitNamespace[0],
      collectionName: dcNamespace === 'all' ? '' : splitNamespace[1],
      keys: dcNamespace === 'all' || selectedDCNamespaceKey === 'all' ? [] : [selectedDCNamespaceKey],
      timeout: globalTimeout,
    };

    axios.post('/data-browser/admin/clear-cache', requestPayload)
      .then(response => {
        if (response.status === 200) {
          addAlertToDOM('.db-settings-right-section', 'success', 'Successfully cleared the cache');

          if (usernameKey === 'all' && dcNamespace === 'all' && selectedDCNamespaceKey === 'all'){
            dispatch({
              type: 'update_cache_data_and_selections',
              payload: {}
            })
          }

          if (usernameKey !== 'all' && dcNamespace === 'all' && selectedDCNamespaceKey === 'all'){
            let tempCacheData = {...cacheData};
            if (tempCacheData.hasOwnProperty(usernameKey)){
              delete tempCacheData[usernameKey];

              dispatch({
                type: 'update_cache_data_and_selections',
                payload: tempCacheData
              });
            }
          }

          if (usernameKey !== 'all' && dcNamespace !== 'all' && selectedDCNamespaceKey !== 'all'){
            let tempCacheData = {...cacheData};
            if (tempCacheData?.[usernameKey]?.[dcNamespace] !== undefined){
              let tempCacheKeys = tempCacheData[usernameKey][dcNamespace];

              if (tempCacheKeys.length === 1) {
                delete tempCacheData[usernameKey][dcNamespace];
              } else {
                let index = tempCacheKeys.indexOf(selectedDCNamespaceKey);

                if (index > -1){
                  tempCacheKeys.splice(index, 1);
                }
              }

              dispatch({
                type: 'update_cache_data_and_selections',
                payload: tempCacheData
              });
            }
          }

          if (usernameKey !== 'all' && dcNamespace !== 'all' && selectedDCNamespaceKey === 'all') {
            let tempCacheData = {...cacheData};
            delete tempCacheData[usernameKey][dcNamespace];

            dispatch({
              type: 'update_cache_data_and_selections',
              payload: tempCacheData
            });
          }

          if (usernameKey === 'all' && dcNamespace !== 'all' && selectedDCNamespaceKey !== 'all') {
            let tempCacheData = {...cacheData};

            for (const tempUsernameKey in cacheData){
              if (cacheData.hasOwnProperty(tempUsernameKey) && cacheData[tempUsernameKey].hasOwnProperty(dcNamespace)) {
                const tempCacheKeys = cacheData[tempUsernameKey][dcNamespace];
                let index = tempCacheKeys.indexOf(selectedDCNamespaceKey);
                if (index > -1){
                  tempCacheKeys.splice(index, 1);
                }
              }
            }

            dispatch({
              type: 'update_cache_data_and_selections',
              payload: tempCacheData
            });
          }

          if (usernameKey === 'all' && dcNamespace !== 'all' && selectedDCNamespaceKey === 'all') {
            let tempCacheData = {...cacheData};

            for (const tempUsernameKey in cacheData){
              if (cacheData.hasOwnProperty(tempUsernameKey) && cacheData[tempUsernameKey].hasOwnProperty(dcNamespace)) {
                delete tempCacheData[tempUsernameKey][dcNamespace];
              }
            }

            dispatch({
              type: 'update_cache_data_and_selections',
              payload: tempCacheData
            });
          }
        } else addAlertToDOM('.db-settings-right-section', 'warning', 'Something went wrong');
      })
      .catch(error => {
        if(error?.response?.data?.message){
					reduxDispatch({
						type: UPDATE_GLOBAL_ERROR_DATA,
						payload: {
							visibility: true,
							errors: error.response.data.message
						}
					});
				}
        addAlertToDOM('.db-settings-right-section', 'warning', 'Something went wrong');
      });
  };

  const getUsernameKeyOptions = () => {
    let options = [];
    for (const key in cacheData) {
      if (cacheData.hasOwnProperty(key)) {
        options.push(<option key={key} value={key}>{key}</option>);
      }
    }
    return options;
  };

  const getDCNamespaceOptions = () => {
    let options = [];
    if (usernameKey === 'all') {
      let dcNamespaceList = [];

      for (const tempUsernameKey in cacheData) {
        if (cacheData.hasOwnProperty(tempUsernameKey)) {
          for (const tempDCNamespaceKey in cacheData[tempUsernameKey]) {
            if (cacheData[tempUsernameKey].hasOwnProperty(tempDCNamespaceKey)) {
              if (dcNamespaceList.includes(tempDCNamespaceKey) === false) {
                dcNamespaceList.push(tempDCNamespaceKey);
              }
            }
          }
        }
      }

      dcNamespaceList.forEach(key => options.push(<option key={key} value={key}>{key}</option>));
    } else if (cacheData.hasOwnProperty(usernameKey)) {
      for (const key in cacheData[usernameKey]) {
        if (cacheData[usernameKey].hasOwnProperty(key)) {
          options.push(<option key={key} value={key}>{key}</option>);
        }
      }
    }

    return options;
  };

  const getDCNamespaceKeyOptions = () => {
    let options = [];
    if (dcNamespace !== 'all') {
      if (usernameKey === 'all') {
        let cacheKeyList = [];

        for (const tempUsernameKey in cacheData) {
          if (cacheData.hasOwnProperty(tempUsernameKey)) {
              if (cacheData[tempUsernameKey].hasOwnProperty(dcNamespace)){
                cacheData[tempUsernameKey][dcNamespace].forEach(item => {
                  if (cacheKeyList.includes(item) === false) {
                    cacheKeyList.push(item);
                  }
                });
              }
          }
        }

        cacheKeyList.forEach(key => options.push(<option key={key} value={key}>{key}</option>));
      } else {
        const selectedDCNamespaceData = cacheData[usernameKey];
        if (selectedDCNamespaceData.hasOwnProperty(dcNamespace)) {
          selectedDCNamespaceData[dcNamespace].forEach(item => options.push(<option key={item} value={item}>{item}</option>));
        }
      }
    }

    return options;
  };

  return (
      <Row>
          <Col xs={12}>
            <h3>Want to clear cache?
              <select
                  name="usernameKey"
                  className="db-select ml-10"
                  value={usernameKey}
                  onChange={handleSelectFieldChange}
                  style={{width: 200}}
              >
                <option value="all">All</option>
                {getUsernameKeyOptions()}
              </select>
              <select
                  name="dcNamespace"
                  className="db-select ml-10"
                  value={dcNamespace}
                  onChange={handleSelectFieldChange}
                  style={{width: 200}}
              >
                <option value="all">All</option>
                {getDCNamespaceOptions()}
              </select>
              <select
                  name="selectedDCNamespaceKey"
                  className="db-select ml-10"
                  value={dcNamespace === 'all' ? 'all' : selectedDCNamespaceKey}
                  onChange={handleSelectFieldChange}
                  style={{width: 150}}
                  disabled={dcNamespace === 'all'}
              >
                <option value="all">All</option>
                {getDCNamespaceKeyOptions()}
              </select>
              <button
                  className="db-button db-button--danger ml-10"
                  onClick={clearCache}
              >Clear Cache
              </button>
            </h3>
          </Col>
        </Row>
  );
};

export default withPageAccessCheck(GenericSettings);
