import React, {useEffect, useRef} from 'react';
import './Modal.scss';

const Modal = ({title, visible, width = 650, onCancel, children}) => {
  const modalContentRef = useRef(null);

  useEffect(() => {
    if (!visible) {
      document.body.style.overflow = 'unset';
      return;
    }

    document.body.style.overflow = 'hidden';

    document.addEventListener('click', hideDropdownMenu);
    return () => document.removeEventListener('click', hideDropdownMenu);
  }, [visible]);

  const hideDropdownMenu = event => {
    if (modalContentRef && modalContentRef.current.contains(event.target) ===
        false) {
      onCancel && onCancel();
    }
  };

  const closeModal = e => {
    e.stopPropagation();
    onCancel && onCancel();
  };

  const modalContentStyles = {maxWidth: width};

  return (
      <div className={visible ? 'db-modal show' : 'db-modal hide'}>
        <div className="db-modal-content" style={modalContentStyles} ref={modalContentRef}>
          <div className="db-modal-header">
            <h3 className="db-modal-header-text">{title} <span className="close" onClick={closeModal}>&times;</span></h3>
          </div>
          <div className="db-modal-body">
            {children}
          </div>
        </div>
      </div>
  );
};

export default Modal;
