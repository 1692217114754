import React, {useEffect, useState} from 'react';

const DTHDateFilterLayout = props => {
  const [date, setDate] = useState('');
  const {data: {namespace}, filterData, onFilterChange} = props;

  useEffect(() => {
    if (filterData && filterData.hasOwnProperty(namespace)) {
      const date = filterData[namespace] ?? '';
      setDate(date);
    } else {
      setDate('');
    }
  }, [filterData, namespace]);

  const handleDateChange = e => {
    setDate(e.target.value);
  };

  const setFilterData = () => {
    let tempFilterData = {...filterData};
    tempFilterData[namespace] = date;
    onFilterChange(tempFilterData);
  };

  const resetFilter = () => {
    let tempFilterData = {...filterData};
    if (tempFilterData.hasOwnProperty(namespace)) {
      delete tempFilterData[namespace];
      onFilterChange(tempFilterData);
    }
  };

  return (
      <>
        <div className="dropdown-inner-section">
          {/*<label htmlFor="date">Date:</label>*/}
          <input
              type="date"
              id="date"
              name="date"
              className="db-input"
              value={date}
              onChange={handleDateChange}
          />
        </div>
        <div className="dropdown-inner-action-section">
          <button
              className="filter"
              type="button"
              onClick={setFilterData}
          >Filter
          </button>
          <button
              className="clear"
              type="button"
              onClick={resetFilter}
          >Clear
          </button>
        </div>
      </>
  );
};

export default DTHDateFilterLayout;
