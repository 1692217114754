import React, {useEffect, useReducer} from 'react';
import axios from 'axios';
import {faCameraRetro, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from '../modal/Modal';
import DBCodeEditor from '../db_code_editor/DBCodeEditor';
import {addAlertToDOM, isFloat} from '../../helpers/util';
import './PreAggregationList.scss';

const initialState = {
  preAggregateList: [],
  hasRecordInSnapshotPreAggregates: false,
  modalData: {
    isModalVisible: false,
    selectedDataField: '',
    modalContent: '',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_pre_aggregates_and_has_snapshot_flag':
      return {
        ...state,
        preAggregateList: action.payload.preAggregateList,
        hasRecordInSnapshotPreAggregates: action.payload.hasRecordInSnapshotPreAggregates,
      };
    case 'update_modal_data':
      return {
        ...state,
        modalData: action.payload,
      };
  }
};

export const PreAggregationList = ({dbName, collectionName, timeout}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    preAggregateList,
    hasRecordInSnapshotPreAggregates,
    modalData: {isModalVisible, selectedDataField, modalContent},
  } = state;

  useEffect(() => {
    getPreAggregates();
  }, [dbName, collectionName]);

  const getPreAggregates = () => {
    const reqData = {
      databaseName: dbName,
      collectionName: collectionName,
      timeout,
    };

    axios.post('/data-browser/execute-pre-aggregates', reqData, {
      headers: {
        'X-CLIENT-ID': 'pre-aggregate-list',
      },
    }).then(response => {
      if (response.status === 200) {
        let filteredPreAggregates = [];
        let hasRecordInSnapshotPreAggregates = false;

        if (response?.data?.data) {
          response.data.data.forEach(item => {
            const {
              display = true,
              recordInSnapshot = false,
            } = item?.aggregateInfo ?? {};

            if (display) {
              filteredPreAggregates.push(item);
              if (recordInSnapshot) hasRecordInSnapshotPreAggregates = true;
            }
          });
        }


        dispatch({
          type: 'update_pre_aggregates_and_has_snapshot_flag',
          payload: {
            preAggregateList: filteredPreAggregates,
            hasRecordInSnapshotPreAggregates,
          },
        });
      }
    }).catch(error => console.log(error));
  };

  const renderPreAggregates = () => {
    let list = [];
    preAggregateList.forEach((item, index) => {
      const {
        aggregateInfo: {
          label,
          display = true,
          between = {},
          equals,
          lcl,
          hcl,
        }, 
        aggregateResult,
        error
      } = item;

      if (display === true) {
        const parsedAggregateResult = aggregateResult ? aggregateResult.map(result => {
          const tempResult = {...result};
          if (tempResult.hasOwnProperty('_id')) {
            delete tempResult._id;
            return tempResult;
          } else return tempResult;
        }) : [];

        const buildResultHtml = () => {
          if(aggregateResult) {
            const currParsedAggregateResult = parsedAggregateResult[0];
            if (currParsedAggregateResult) {
              if (Object.keys(currParsedAggregateResult).length === 1) {
                const value = currParsedAggregateResult[Object.keys(currParsedAggregateResult)[0]];
                return <h3 className="db-pre-aggregation-card-value">{isFloat(value) ? value.toFixed(2) : value}</h3>;
              } else return (
                  <div className="code-wrap" onClick={() => setPopupData(label, parsedAggregateResult)}>
                    <code className="code">{JSON.stringify(parsedAggregateResult)}</code>
                  </div>
              );
            } else return null;
          } else return <span className="error-link" onClick={() => alert(error)}><FontAwesomeIcon className="error-icon" icon={faExclamationTriangle}/> view error</span>;
        };

        const val = aggregateResult ? parsedAggregateResult[0][Object.keys(parsedAggregateResult[0])[0]] : '';
        const getClassNames = label ? `db-pre-aggregation-card ${label.replace(/\s+/g, '-').toLowerCase()}` :  'db-pre-aggregation-card';
        let thresholdClassNames = '';

        if(aggregateResult){
          if (Object.keys(parsedAggregateResult[0]).length !== 1) {
            thresholdClassNames += ' threshold-multivalue'
          }
          if (val >= between['from'] && val<= between['to']) {
            thresholdClassNames += ' threshold-between'
          }
          if (val == equals) {
            thresholdClassNames += ' threshold-equals'
          }
          if (val < lcl) {
            thresholdClassNames += ' threshold-lcl'
          }
          if (val > hcl) {
            thresholdClassNames += ' threshold-hcl'
          }
        }

        list.push(
            <div className={getClassNames + thresholdClassNames} key={index}>
              <h5 className="db-pre-aggregation-card-label">{label}</h5>
              {buildResultHtml()}
            </div>,
        );
      }
    });
    return list;
  };

  const takeSnapshot = () => {
    axios.post('/data-browser/take-snapshots', {
      databaseName: dbName,
      collectionName,
      timeout,
    }, {
      headers: {
        'X-CLIENT-ID': 'pre-aggregate-snapshot',
      },
    }).then(response => {
      if (response.status === 200) {
        addAlertToDOM(`.db-pre-aggregation-list`, 'success', 'Snapshot has been taken successfully.', 'mb-10');
      } else addAlertToDOM(`.db-pre-aggregation-list`, 'warning', 'Something went wrong.', 'mb-10');
    }).catch(() => {
      addAlertToDOM(`.db-pre-aggregation-list`, 'warning', 'Something went wrong.', 'mb-10');
    });
  };

  const buildAggregationList = renderPreAggregates();

  const setPopupData = (label, data) => {
    dispatch({
      type: 'update_modal_data',
      payload: {
        isModalVisible: true,
        selectedDataField: label,
        modalContent: data,
      },
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'update_modal_data',
      payload: {
        isModalVisible: false,
        selectedDataField: '',
        modalContent: '',
      },
    });
  };

  return (
      <>
        <div className="db-pre-aggregation-list">
          {buildAggregationList}
          {
            hasRecordInSnapshotPreAggregates && (
                <button className="db-button mt-0" onClick={takeSnapshot}>
                  <FontAwesomeIcon className="mr-5" icon={faCameraRetro}/>
                  Take Snapshot
                </button>
            )
          }
        </div>
        <Modal
            title={selectedDataField}
            visible={isModalVisible}
            onCancel={closeModal}
        >
          <DBCodeEditor
              value={modalContent}
              height="300px"
          />
        </Modal>
      </>
  );
};

export default PreAggregationList;
