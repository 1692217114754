import {UPDATE_API_REQUEST_IDS} from './types';

export const addApiRequestIdToList = apiRequestId => (dispatch, getState) => {
  let tempApiRequestIds = getState().util.apiRequestIds;
  tempApiRequestIds.push(apiRequestId);
  dispatch({
    type: UPDATE_API_REQUEST_IDS,
    payload: tempApiRequestIds,
  });
};

export const removeApiRequestIdFromList = apiRequestId => (
    dispatch, getState) => {
  let tempApiRequestIds = getState().util.apiRequestIds;
  const currRequestIdIndex = tempApiRequestIds.indexOf(apiRequestId);
  if (currRequestIdIndex > -1) {
    tempApiRequestIds.splice(currRequestIdIndex, 1);

    dispatch({
      type: UPDATE_API_REQUEST_IDS,
      payload: tempApiRequestIds,
    });
  }
};
