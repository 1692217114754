import React from 'react';
import PropTypes from 'prop-types';

const ExternalFilterConditionValueFields = props => {
  const {
    dataKey,
    filterType,
    condition,
    isDisabledCondition,
    value,
    isDisabledValue,
    handleFieldChange,
  } = props;

  const tempValue = ['exist', 'nil', 'blank'].includes(condition) ?
      '' :
      value ?? '';

  const buildConditionOptionsBasedOnColumnType = () => {
    switch (filterType) {
      case 'string':
        return (
            <>
              <option value="eq">Equals to</option>
              <option value="ne">Not equals to</option>
              <option value="contains">Contains</option>
              <option value="doesnotcontains">Does not contains</option>
              <option value="startswith">Starts with</option>
              <option value="endswith">Ends with</option>
              <option value="exist">Exist</option>
              <option value="nil">Nil</option>
              <option value="blank">Blank</option>
            </>
        );
      case 'number':
        return (
            <>
              <option value="eq">Equals to</option>
              <option value="ne">Not equals to</option>
              <option value="lt">Less than</option>
              <option value="lte">Less than or equals to</option>
              <option value="gt">Greater than</option>
              <option value="gte">Greater than or equals to</option>
              <option value="exist">Exist</option>
              <option value="nil">Nil</option>
              <option value="blank">Blank</option>
            </>
        );
      case 'bool':
        return (
            <>
              <option value="eq">Equals to</option>
              <option value="ne">Not equals to</option>
              <option value="exist">Exist</option>
              <option value="nil">Nil</option>
              <option value="blank">Blank</option>
            </>
        );
      case 'date':
        return (
            <>
              <option value="eq">Equals to</option>
              <option value="ne">Not equals to</option>
              <option value="lt">Less than</option>
              <option value="lte">Less than or equals to</option>
              <option value="gt">Greater than</option>
              <option value="gte">Greater than or equals to</option>
            </>
        );
      default:
        return (
            <>
              <option value="eq">Equals to</option>
              <option value="ne">Not equals to</option>
              <option value="lt">Less than</option>
              <option value="lte">Less than or equals to</option>
              <option value="gt">Greater than</option>
              <option value="gte">Greater than or equals to</option>
              <option value="exist">Exist</option>
              <option value="nil">Nil</option>
              <option value="blank">Blank</option>
            </>
        );
    }
  };

  const buildValueFieldBasedOnColumnType = () => {
    switch (filterType) {
      case 'string':
        return (
            <input
                name="value"
                type={'text'}
                className="db-input filter-value-input"
                data-key={dataKey}
                value={tempValue}
                onChange={e => handleFieldChange(e, 'string')}
                disabled={isDisabledValue}
            />
        );
      case 'number':
        return (
            <input
                name="value"
                type="number"
                className="db-input filter-value-input"
                data-key={dataKey}
                value={value}
                onChange={e => handleFieldChange(e, 'number')}
                disabled={isDisabledValue}
            />
        );
      case 'date':
        return (
            <input
                name="value"
                type="date"
                className="db-input filter-value-input"
                data-key={dataKey}
                value={tempValue}
                onChange={e => handleFieldChange(e, 'date')}
                disabled={isDisabledValue}
            />
        );
      default:
        return (
            <input
                name="value"
                type={'text'}
                className="db-input filter-value-input"
                data-key={dataKey}
                value={tempValue}
                onChange={e => handleFieldChange(e, 'string')}
                disabled={isDisabledValue}
            />
        );
    }
  };

  return (
      <>
        <select
            name="condition"
            className="db-select condition-select"
            data-key={dataKey}
            value={condition}
            onChange={handleFieldChange}
            disabled={isDisabledCondition}
        >
          {buildConditionOptionsBasedOnColumnType()}
        </select>
        {buildValueFieldBasedOnColumnType()}
      </>
  );
};

ExternalFilterConditionValueFields.propTypes = {
  dataKey: PropTypes.string.isRequired,
  filterType: PropTypes.string,
  condition: PropTypes.string,
  isDisabledCondition: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  isDisabledValue: PropTypes.bool,
  handleFieldChange: PropTypes.func,
};

ExternalFilterConditionValueFields.defaultProps = {
  filterType: '',
  condition: 'eq',
};

export default ExternalFilterConditionValueFields;
