import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lockIcon from '../../assets/images/lock.png';
import warningIcon from '../../assets/images/warning.png';
import Home from '../../pages/home/Home';
import Settings from '../../pages/settings/Settings';
import TableDetailed from '../../pages/table_detailed/TableDetailed';
import {
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_STATUS
} from '../../redux/actions/types';
import NavigationBar from '../navigation_bar/NavigationBar';
import './BaseLayoutWrapper.scss';

const BaseLayoutWrapper = () => {
  const {url} = useRouteMatch();
  const dispatch = useDispatch();
  const {userDataStatus} = useSelector(state => state.userData);
  const {isFullScreen} = useSelector(state => state.util);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    axios.get('/data-browser/get-user-details').then(response => {
      if (response.status === 200) {
        dispatch({
          type: UPDATE_USER_DATA,
          payload: {
            userData: response?.data?.data ?? {},
            userDataStatus: 'success',
          },
        });
      } else dispatch({
        type: UPDATE_USER_DATA_STATUS,
        payload: 'error',
      });
    }).catch(error => {
      console.log(error.response);
      const status = error?.response?.status ?? '';
      if (status === 403) {
        dispatch({
          type: UPDATE_USER_DATA_STATUS,
          payload: 'forbidden',
        });
      } else if (status === 401) {
        dispatch({
          type: UPDATE_USER_DATA_STATUS,
          payload: 'unauthorized',
        });
      } else dispatch({
        type: UPDATE_USER_DATA_STATUS,
        payload: 'error',
      });
    });
  };

  const pageRefresh = () => {
    window.location.reload();
  };

  if (userDataStatus === 'loading') {
    return (
        <div className="blw-section">
          <h2><FontAwesomeIcon className="mr-5" icon={faSpinner} spin/>Loading..</h2>
        </div>
    );
  }

  if (userDataStatus === 'unauthorized') {
    return null;
  }

  if (userDataStatus === 'forbidden') {
    return (
        <div className="blw-section">
          <img
              src={lockIcon}
              alt="lock icon"
              width={70}
          />
          <h2>Access Denied</h2>
          <p>You don't have permission to access this page.<br/>Contact an
            administrator to get permissions.</p>
        </div>
    );
  }

  if (userDataStatus === 'error') {
    return (
        <div className="blw-section">
          <img
              src={warningIcon}
              alt="lock icon"
              width={70}
          />
          <h2>Error</h2>
          <p>Something went wrong. Please refresh the page.</p>
          <button
              className="db-button db-button--primary"
              onClick={pageRefresh}
          >Refresh
          </button>
        </div>
    );
  }

  if (userDataStatus === 'success') {
    return (
        <>
          { !isFullScreen && <NavigationBar/> }
          <Switch>
            <Route exact path={`${url}/`}>
              <Home/>
            </Route>
            <Route path={`${url}/admin/`}>
              <Settings/>
            </Route>
            <Route path={`${url}/data-table/tag=:tagSlug&db-name=:dbName&collection-name=:collectionName`}>
              <TableDetailed/>
            </Route>
            <Route path={`${url}/data-table/db-name=:dbName&collection-name=:collectionName`}>
              <TableDetailed/>
            </Route>
          </Switch>
        </>
    );
  }

};

export default BaseLayoutWrapper;
