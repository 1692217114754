// import './components/wdyr/wdyr';
import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Login from './pages/login/Login';
import BaseLayoutWrapper from './components/BaseLayoutWrapper/BaseLayoutWrapper';
import NoPage from './pages/no_page/NoPage';
import ScrollToTop from './components/scroll_to_top/ScrollToTop';
import { createAxiosInterceptor } from './axios-interceptor';
import AlertModal from './components/alert_modal/AlertModal';
import './App.scss';

createAxiosInterceptor();

const App = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		window.addEventListener('message', handleParentDOMMessage, false);
	}, []);

	const handleParentDOMMessage = async (e) => {
		const data = e?.data ?? {};
		if (data.hasOwnProperty('dataBrowserFilterQuery')) {
			const { dataBrowserFilterQuery = [] } = data;
			if (dataBrowserFilterQuery != []) {
				await dispatch({
					type: 'update_db_query_and_json_filter_status',
					payload: {
						dbQuery: dataBrowserFilterQuery,
						isJsonFilterActive: true,
					},
				});
			}
		}

		if (
			data.hasOwnProperty('dataBrowserDatabaseName') ||
			data.hasOwnProperty('dataBrowserCollectionName') ||
			data.hasOwnProperty('dataBrowserScreenSize') ||
			data.hasOwnProperty('dataBrowserFilterVisibility') ||
			data.hasOwnProperty('dataBrowserEnableColumnFilters') ||
			data.hasOwnProperty('dataBrowserDevMode') ||
			data.hasOwnProperty('dataBrowserEnableDownload')
		) {
			const {
				dataBrowserDatabaseName = '',
				dataBrowserCollectionName = '',
				dataBrowserScreenSize = '',
				dataBrowserFilterVisibility = true,
				dataBrowserEnableColumnFilters = true,
				dataBrowserDevMode = true,
				dataBrowserEnableDownload = true,
			} = data;

			await dispatch({
				type: 'update_pre_defined_data',
				payload: {
					defaultDatabaseName: dataBrowserDatabaseName,
					defaultCollectionName: dataBrowserCollectionName,
					isFullScreen: dataBrowserScreenSize === 'fullscreen',
					defaultFilterVisibility: dataBrowserFilterVisibility,
					enableColumnFilters: dataBrowserEnableColumnFilters,
					devMode: dataBrowserDevMode,
					enableDownload: dataBrowserEnableDownload,
				},
			});
		}
	};

	return (
		<Router>
			<div className='db'>
				<Switch>
					<Route exact path='/'>
						<Redirect to='/app/' />
					</Route>
					<Route path='/app'>
						<BaseLayoutWrapper />
					</Route>
					<Route exact path='/login/'>
						<Login />
					</Route>
					<Route path='*'>
						<NoPage />
					</Route>
				</Switch>
			</div>
			<AlertModal />
			<ScrollToTop />
		</Router>
	);
};

export default App;
