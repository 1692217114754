import React, { useEffect, useState } from 'react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { copyTextToClipboard } from '../../../helpers/util';
import DBCodeEditor from '../../db_code_editor/DBCodeEditor';
import { getEngine } from 'db-engine';

const JsonFilter = ({ fetchDataForcefully }) => {
	const [tempDbQuery, setTempDbQuery] = useState();
	const [dbPrefix, setDbPrefix] = useState('');
	const {
		databaseType,
		dbQuery,
		collectionName,
		pagination,
		isJsonFilterActive,
	} = useSelector((state) => state.tableData);
	const dispatch = useDispatch();

	useEffect(() => {
		setTempDbQuery(dbQuery);
	}, [dbQuery]);

	useEffect(() => {
		const dbEngine = getEngine(databaseType);
		setDbPrefix(dbEngine.getQueryPrefix(collectionName));
	}, [collectionName]);

	const handleExternalFilterQuery = (data) => {
		setTempDbQuery(data);
	};

	const copyFullQuery = () => {
		const dbEngine = getEngine(databaseType);
		copyTextToClipboard(dbEngine.getFullDbQuery(collectionName, tempDbQuery));
	};

	const applyFilter = () => {
		let parsedQuery = [],
			foundErrors = false;

		try {
			parsedQuery =
				typeof tempDbQuery === 'object'
					? JSON.stringify(tempDbQuery)
					: tempDbQuery;
		} catch (error) {
			foundErrors = true;
			alert(error.message);
		}

		if (dbQuery !== tempDbQuery) {
			if (!foundErrors) {
				dispatch({
					type: 'update_db_query_and_json_filter_status',
					payload: {
						dbQuery: parsedQuery,
						isJsonFilterActive: true,
					},
				});
			}
		} else {
			dispatch({
				type: 'update_loading_status_and_reset_json_filter_flag',
				payload: true,
			});
			fetchDataForcefully();
		}
	};

	const clearFilters = () => {
		const dbEngine = getEngine(databaseType);
		const { dbStringifyQuery, dbMatchStringifyQuery } = dbEngine.getQuery(
			{},
			{},
			pagination
		);

		if (dbQuery !== dbStringifyQuery) {
			dispatch({
				type: 'reset_external_filters_data',
				payload: {
					dbQuery: dbStringifyQuery,
					dbMatchQuery: dbMatchStringifyQuery,
				},
			});
		} else {
			dispatch({
				type: 'update_loading_status',
				payload: true,
			});
			fetchDataForcefully();
		}
	};

	return (
		<>
			{isJsonFilterActive && (
				<div className='db-alert warning'>
					<FontAwesomeIcon icon={faExclamationCircle} />
					<p>
						Changes made in raw query mode may not be consistent with GUI query
						settings like filters, pagination etc
					</p>
				</div>
			)}
			<code className='code mr-5'>{dbPrefix}</code>
			<button
				className='db-button db-button--primary'
				data-tip='Query copied'
				data-event='click'
			>
				Copy Query
			</button>
			<ReactTooltip
				eventOff='click'
				afterShow={copyFullQuery}
				delayHide={1500}
				place='top'
				type='success'
				effect='solid'
			/>
			<DBCodeEditor
				value={tempDbQuery}
				onChange={handleExternalFilterQuery}
				height='200px'
			/>
			<button
				className='db-button db-button--primary mr-5'
				onClick={applyFilter}
			>
				Apply
			</button>
			<button
				className='db-button db-button--danger mr-5'
				onClick={clearFilters}
			>
				Clear Filters
			</button>
		</>
	);
};

export default JsonFilter;
