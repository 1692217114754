import React from 'react';
import {
	faExclamationCircle,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { getEngine } from 'db-engine';
import ExternalFilterConditionValueFields from './external_filter_condition_value_fields/ExternalFilterConditionValueFields';
import { deleteByPath, flatten, unflatten } from '../../../helpers/util';
import './FieldFilter.scss';

const FieldFilter = ({ fetchDataForcefully }) => {
	const {
		databaseType,
		tableColumns,
		filterRows,
		dbQuery,
		sortData,
		pagination,
		externalFilterRows,
		externalFiltersRowCount,
		flattenExternalFilterRows,
		isJsonFilterActive,
	} = useSelector((state) => state.tableData);
	const { devMode } = useSelector((state) => state.util);
	const dispatch = useDispatch();

	const combinedFilterRows = { ...externalFilterRows, ...filterRows };

	const addFilterRow = (key) => {
		let tempExternalFilterRows = { ...externalFilterRows };
		let tempFlattenExternalFilterRows = { ...flattenExternalFilterRows };

		const tempExternalFiltersRowCount = externalFiltersRowCount + 1;

		tempExternalFilterRows[key + tempExternalFiltersRowCount] = {
			key: key + tempExternalFiltersRowCount,
			type: 'normal',
			fieldName: 'none',
			condition: 'eq',
			value: '',
			trueBackendType: 'string',
			filterType: 'string',
		};

		tempFlattenExternalFilterRows[key + tempExternalFiltersRowCount] = {
			key: key + tempExternalFiltersRowCount,
			type: 'normal',
			fieldName: 'none',
			condition: 'eq',
			value: '',
			trueBackendType: 'string',
			filterType: 'string',
		};

		const unflattenExternalFilterRows = unflatten(
			tempFlattenExternalFilterRows
		);

		dispatch({
			type: 'update_external_filter_rows_and_count',
			payload: {
				externalFilterRows: unflattenExternalFilterRows,
				externalFiltersRowCount: tempExternalFiltersRowCount,
				flattenExternalFilterRows: tempFlattenExternalFilterRows,
			},
		});
	};

	const addLogicFilterRow = (key) => {
		let tempExternalFilterRows = { ...externalFilterRows };
		let tempFlattenExternalFilterRows = { ...flattenExternalFilterRows };

		const tempExternalFiltersRowCount = externalFiltersRowCount + 1;

		tempExternalFilterRows[key + tempExternalFiltersRowCount] = {
			key: key + tempExternalFiltersRowCount,
			type: 'logic',
			condition: 'and',
			subFilters: {},
		};

		tempFlattenExternalFilterRows[key + tempExternalFiltersRowCount] = {
			key: key + tempExternalFiltersRowCount,
			type: 'logic',
			condition: 'and',
			subFilters: {},
		};

		const unflattenExternalFilterRows = unflatten(
			tempFlattenExternalFilterRows
		);

		dispatch({
			type: 'update_external_filter_rows_and_count',
			payload: {
				externalFilterRows: unflattenExternalFilterRows,
				flattenExternalFilterRows: tempFlattenExternalFilterRows,
				externalFiltersRowCount: tempExternalFiltersRowCount,
			},
		});
	};

	const removeFilterRow = (key) => {
		let tempExternalFilterRows = { ...externalFilterRows };
		deleteByPath(tempExternalFilterRows, key);

		const flattenFilterRows = flatten(tempExternalFilterRows);

		dispatch({
			type: 'update_external_filter_rows',
			payload: {
				externalFilterRows: tempExternalFilterRows,
				flattenExternalFilterRows: flattenFilterRows,
			},
		});
	};

	const clearFilters = () => {
		const dbEngine = getEngine(databaseType);
		const { dbStringifyQuery, dbMatchStringifyQuery } = dbEngine.getQuery(
			{},
			{},
			pagination
		);

		if (dbQuery !== dbStringifyQuery) {
			dispatch({
				type: 'reset_external_filters_data',
				payload: {
					dbQuery: dbStringifyQuery,
					dbMatchQuery: dbMatchStringifyQuery,
				},
			});
		} else {
			dispatch({
				type: 'update_loading_status',
				payload: true,
			});
			fetchDataForcefully();
		}
	};

	const handleFilterFieldNameChange = (e) => {
		const {
			name,
			value,
			dataset: { key: eleKey },
			options,
			selectedIndex,
		} = e.target;

		const {
			truebackendtype: trueBackendType = '',
			filtertype: filterType = '',
		} = options[selectedIndex].dataset;

		let tempFlattenExternalFilterRows = { ...flattenExternalFilterRows };
		tempFlattenExternalFilterRows[eleKey + '-' + name] = value;
		tempFlattenExternalFilterRows[eleKey + '-filterType'] = filterType;
		tempFlattenExternalFilterRows[eleKey + '-trueBackendType'] =
			trueBackendType;

		const unflattenExternalFilterRows = unflatten(
			tempFlattenExternalFilterRows
		);

		dispatch({
			type: 'update_external_filter_rows',
			payload: {
				externalFilterRows: unflattenExternalFilterRows,
				flattenExternalFilterRows: tempFlattenExternalFilterRows,
			},
		});
	};

	const handleFilterFieldChange = (e, type) => {
		const {
			name,
			value,
			dataset: { key: eleKey },
		} = e.target;

		let tempFlattenExternalFilterRows = { ...flattenExternalFilterRows };
		tempFlattenExternalFilterRows[eleKey + '-' + name] =
			type === 'number' ? Number(value) : value;

		const unflattenExternalFilterRows = unflatten(
			tempFlattenExternalFilterRows
		);

		dispatch({
			type: 'update_external_filter_rows',
			payload: {
				externalFilterRows: unflattenExternalFilterRows,
				flattenExternalFilterRows: tempFlattenExternalFilterRows,
			},
		});
	};

	const applyFilter = () => {
		const engine = getEngine(databaseType);
		const { dbStringifyQuery } = engine.getQuery(
			combinedFilterRows,
			sortData,
			pagination
		);

		if (dbQuery !== dbStringifyQuery) {
			dispatch({
				type: 'update_db_query_and_json_filter_status',
				payload: {
					dbQuery: dbStringifyQuery,
					isJsonFilterActive: false,
				},
			});
		} else {
			dispatch({
				type: 'update_loading_status_and_reset_json_filter_flag',
				payload: true,
			});
			fetchDataForcefully();
		}
	};

	const buildNamespaceOptions = () => {
		let options = [];
		const sortedTableColumns = tableColumns.sort((a, b) =>
			a.namespace.localeCompare(b.namespace)
		);
		sortedTableColumns.forEach((item, index) => {
			const {
				label,
				namespace,
				filter: { trueBackendType, type },
			} = item;
			options.push(
				<option
					key={namespace + index}
					value={namespace}
					data-truebackendtype={trueBackendType ?? ''}
					data-filtertype={type ?? ''}
				>
					{label}
				</option>
			);
		});
		return options;
	};

	const buildFilterRows = (data, parentCondition = '') => {
		let rows = [];
		for (let objKey in data) {
			if (data.hasOwnProperty(objKey)) {
				const {
					key,
					type,
					fieldName,
					condition,
					value,
					filterType,
					subFilters = {},
					readOnly = false,
				} = data[objKey];

				if (type === 'normal') {
					const isDisabledCondition = readOnly || fieldName === 'none';
					const isDisabledValue =
						readOnly ||
						fieldName === 'none' ||
						['exist', 'nil', 'blank'].includes(condition);

					rows.push(
						<div className='field-filter-row normal-row' key={key}>
							<button
								className='db-button db-button--danger delete'
								onClick={() => removeFilterRow(key)}
								disabled={readOnly}
							>
								<FontAwesomeIcon icon={faTrashAlt} />
							</button>
							<select
								name='fieldName'
								className='db-select column-header-select'
								data-key={key}
								disabled={readOnly}
								value={fieldName}
								onChange={handleFilterFieldNameChange}
							>
								<option value='none'>Select field</option>
								{buildNamespaceOptions()}
							</select>
							<ExternalFilterConditionValueFields
								filterType={filterType}
								dataKey={key}
								condition={condition}
								isDisabledCondition={isDisabledCondition}
								value={value}
								isDisabledValue={isDisabledValue}
								handleFieldChange={handleFilterFieldChange}
							/>
							<span className='condition-text'>
								{parentCondition.toUpperCase()}
							</span>
						</div>
					);
				} else if (type === 'logic') {
					rows.push(
						<div className='field-filter-row logical-row' key={key}>
							<span className='brace'>{'('}</span>
							<button
								className='db-button db-button--danger delete ml-10'
								onClick={() => removeFilterRow(key)}
								disabled={readOnly}
							>
								<FontAwesomeIcon icon={faTrashAlt} />
							</button>
							<select
								name='condition'
								className='db-select logic-select logic-select'
								data-key={key}
								disabled={readOnly}
								value={condition}
								onChange={(e) => handleFilterFieldChange(e, filterType)}
							>
								<option value='and'>AND</option>
								<option value='or'>OR</option>
							</select>
							<button
								className='db-button db-button--primary mr-5'
								disabled={readOnly}
								onClick={() => addFilterRow(key + '-subFilters-fr')}
							>
								Add Filter
							</button>
							<button
								className='db-button db-button--primary mr-10'
								disabled={readOnly}
								onClick={() => addLogicFilterRow(key + '-subFilters-lfr')}
							>
								Add Logic Filter
							</button>
							<span className='brace'>{'{'}</span>
							<div className='field-filter-row-inner'>
								{buildFilterRows(subFilters, condition)}
							</div>
							<span className='brace ml-15'>{'}'}</span>
							<br />
							<span className='brace'>{')'}</span>
						</div>
					);
				}
			}
		}
		return rows;
	};

	return (
		<div className='field-filter-container'>
			{devMode && isJsonFilterActive && (
				<div className='db-alert warning'>
					<FontAwesomeIcon icon={faExclamationCircle} />
					<p>
						Filters are applied using JSON filter, hence below filters are not
						applicable.
					</p>
				</div>
			)}
			<div className='field-filter-row-section'>
				{buildFilterRows(combinedFilterRows)}
			</div>
			<div className='field-filter-actions-section'>
				<button
					className='db-button db-button--primary mr-5'
					onClick={applyFilter}
				>
					Apply
				</button>
				<button
					className='db-button db-button--primary mr-5'
					onClick={() => addFilterRow('fr')}
				>
					Add Filter
				</button>
				<button
					className='db-button db-button--primary mr-5'
					onClick={() => addLogicFilterRow('lfr')}
				>
					Add Logic Filter
				</button>
				<button
					className='db-button db-button--danger mr-5'
					onClick={clearFilters}
				>
					Clear Filters
				</button>
			</div>
		</div>
	);
};

export default FieldFilter;
