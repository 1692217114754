import React from 'react';
import {Col, Row} from 'react-grid-system';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

const ConfigurableTableColumnFilterStaticSourceData = ({
                                                         sourceData,
                                                         onChange,
                                                       }) => {
  const staticDataFieldsLength = sourceData ? sourceData.length : 0;

  const addStaticDataInputField = () => {
    onChange([...sourceData, '']);
  };

  const removeStaticDataInputField = () => {
    let temSourceData = [...sourceData];
    temSourceData.pop();
    onChange(temSourceData);
  };

  const handleStaticDataValueChange = event => {
    const {name, value} = event.target;
    let temSourceData = [...sourceData];
    temSourceData[name] = value;
    onChange(temSourceData);
  };

  const buildStaticFields = () => sourceData.map((item, index) => (
      <Col xs={12} key={index}>
        <input
            type="text"
            className="db-input"
            value={item}
            name={index}
            onChange={handleStaticDataValueChange}
        />
      </Col>
  ));

  return (
      <Col className="mt-5" xs={3}>
        <label
            htmlFor="filter-source-data"
            className="column-section-label"
        >Static Data :</label>
        <Row gutterWidth={5}>
          {
            staticDataFieldsLength === 0 ? (
                <Col xs={12}>
                  <div className="db-alert warning mt-6">
                    <FontAwesomeIcon icon={faExclamationCircle}/>
                    <p style={{fontSize: 12}}>No data fields found. Add to
                      configure.</p>
                  </div>
                </Col>
            ) : (buildStaticFields())
          }
          <Col xs={12}>
            <button
                className="db-button db-button--primary mr-10"
                onClick={addStaticDataInputField}
            >Add data item
            </button>
            <button
                className="db-button db-button--danger"
                onClick={removeStaticDataInputField}
            >Remove data item
            </button>
          </Col>
        </Row>
      </Col>
  );
};

export default ConfigurableTableColumnFilterStaticSourceData;
