export const getPropByString = memoize((obj, propString) => {
  if (typeof obj == 'undefined' || obj == 'null' || typeof propString ===
      'undefined' || propString == 'null' || propString === '') {
    return '-';
  } else {
    let value = obj;

    const props = propString.split('.');
    for (let index = 0; index < props.length; index += 1) {
      const currPropName = props[index];

      if (typeof currPropName === 'undefined' || currPropName === null ||
          typeof value === 'undefined' || value === null) {
        value = '';
        break;
      } else if (Array.isArray(value)) {
        let isNumberValue = false;
        let tempValue = 0;
        let tempArrayValue = [];
        value.forEach((item, index) => {
          const currPropValue = item[currPropName];
          if (typeof currPropValue !== 'number') {
            if (typeof currPropValue !== 'undefined' && currPropValue !==
                null) tempArrayValue.push(currPropValue);
          } else {
            isNumberValue = true;
            if (index === 0) {
              tempValue = currPropValue;
            } else tempValue += currPropValue;
          }
        });
        value = isNumberValue ? tempValue : tempArrayValue;
      } else value = value[currPropName];
    }
    value = typeof value === 'number' && isFloat(value) ?
        value.toFixed(2) :
        typeof value === 'string' && value === '' ? '-' : value;
    return value ?? '-';
  }
});

export const isObject = variable => {
  return Object.prototype.toString.call(variable) === '[object Object]';
};

export const isEmptyObject = value => {
  return isObject(value) && Object.keys(value).length === 0;
};

export const setInnerObjValue = (path, value, schema) => {
  let pList = path.split('-');
  let i, len = pList.length;
  for (i = 0; i < len - 1; i++) {
    let elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }
  schema[pList[len - 1]] = value;
};

export const getAllKeysFromObj = (obj, prefix = '') => Object.keys(obj).
    reduce((res, el) => {
      if (Array.isArray(obj[el])) {
        return res;
      } else if (typeof obj[el] === 'object' && obj[el] !== null) {
        return [...res, ...getAllKeysFromObj(obj[el], prefix + el + '.')];
      }
      return [...res, prefix + el];
    }, []);

export const unflatten = obj => {
  let result = {}, temp, substrings, property, i;
  for (property in obj) {
    if (obj.hasOwnProperty(property)) {
      substrings = property.split('-');
      temp = result;
      for (i = 0; i < substrings.length - 1; i++) {
        if (!(substrings[i] in temp)) {
          if (isFinite(substrings[i + 1])) { // check if the next key is
            temp[substrings[i]] = [];      // an index of an array
          } else {
            temp[substrings[i]] = {};      // or a key of an object
          }
        }
        temp = temp[substrings[i]];
      }
      temp[substrings[substrings.length - 1]] = obj[property];
    }
  }
  return result;
};

export const flatten = obj => {
  let result = {};
  (function f(e, p) {
    switch (typeof e) {
      case 'object':
        p = p ? p + '-' : '';
        for (let i in e)
          f(e[i], p + i);
        break;
      default:
        result[p] = e;
        break;
    }
  })(obj);
  return result;
};

export const deleteByPath = (object, path) => {
  let currentObject = object;
  const parts = path.split('-');
  const last = parts.pop();
  for (const part of parts) {
    currentObject = currentObject[part];
    if (!currentObject) {
      return;
    }
  }
  delete currentObject[last];
};

const getParsedValueBasedOnType = (type, value) => {
  if (type === 'number') {
    return Number(value);
  } else if (type === 'string') {
    return value.trim();
  } else return '';
};

export const buildFilterQuery = (databaseType = 'MongoDB', obj, condition) => {
  let fieldsQuery = [];
  for (const objKey in obj) {
    if (obj.hasOwnProperty(objKey)) {
      if (obj[objKey].type === 'logic') {
        const {condition, subFilters} = obj[objKey];
        if (Object.keys(subFilters).length > 0) {
          fieldsQuery.push(
              buildFilterQuery(databaseType, subFilters, `$${condition}`));
        }
      } else {
        const {
          fieldName,
          condition,
          value,
          trueBackendType,
          filterType,
        } = obj[objKey];

        let parsedValue = filterType === 'number' ?
            Number(value) :
            value.trim();

        if (fieldName !== 'none') {
          switch (condition) {
            case 'eq':
              if (trueBackendType === 'date' && filterType === 'date') {
                fieldsQuery.push({
                  '$expr': {
                    '$eq': [
                      `$${fieldName}`,
                      {
                        '$dateFromString': {
                          'dateString': value,
                        },
                      },
                    ],
                  },
                });
              } else fieldsQuery.push({
                [fieldName]: {
                  '$eq': parsedValue,
                },
              });
              break;
            case 'ne':
              if (trueBackendType === 'date' && filterType === 'date') {
                fieldsQuery.push({
                  '$expr': {
                    '$ne': [
                      `$${fieldName}`,
                      {
                        '$dateFromString': {
                          'dateString': value,
                        },
                      },
                    ],
                  },
                });
              } else fieldsQuery.push({
                [fieldName]: {
                  '$ne': parsedValue,
                },
              });
              break;
            case 'lt':
              if (trueBackendType === 'date' && filterType === 'date') {
                fieldsQuery.push({
                  '$expr': {
                    '$lt': [
                      `$${fieldName}`,
                      {
                        '$dateFromString': {
                          'dateString': value,
                        },
                      },
                    ],
                  },
                });
              } else fieldsQuery.push({
                [fieldName]: {
                  '$lt': parsedValue,
                },
              });
              break;
            case 'lte':
              if (trueBackendType === 'date' && filterType === 'date') {
                fieldsQuery.push({
                  '$expr': {
                    '$lte': [
                      `$${fieldName}`,
                      {
                        '$dateFromString': {
                          'dateString': value,
                        },
                      },
                    ],
                  },
                });
              } else fieldsQuery.push({
                [fieldName]: {
                  '$lte': parsedValue,
                },
              });
              break;
            case 'gt':
              if (trueBackendType === 'date' && filterType === 'date') {
                fieldsQuery.push({
                  '$expr': {
                    '$gt': [
                      `$${fieldName}`,
                      {
                        '$dateFromString': {
                          'dateString': value,
                        },
                      },
                    ],
                  },
                });
              } else fieldsQuery.push({
                [fieldName]: {
                  '$gt': parsedValue,
                },
              });
              break;
            case 'gte':
              if (trueBackendType === 'date' && filterType === 'date') {
                fieldsQuery.push({
                  '$expr': {
                    '$gte': [
                      `$${fieldName}`,
                      {
                        '$dateFromString': {
                          'dateString': value,
                        },
                      },
                    ],
                  },
                });
              } else fieldsQuery.push({
                [fieldName]: {
                  '$gte': parsedValue,
                },
              });
              break;
            case 'contains':
              fieldsQuery.push({
                [fieldName]: {
                  '$regex': `.*${escapeRegExp(parsedValue)}.*`,
                  '$options': 'i',
                },
              });
              break;
            case 'doesnotcontains':
              fieldsQuery.push({
                [fieldName]: {
                  '$ne': {
                    'eq': {
                      '$regex': `.*${escapeRegExp(parsedValue)}.*`,
                      '$options': 'i',
                    },
                  },
                },
              });
              break;
            case 'startswith':
              fieldsQuery.push({
                [fieldName]: {
                  '$regex': `^${escapeRegExp(parsedValue)}`,
                  '$options': 'i',
                },
              });
              break;
            case 'endswith':
              fieldsQuery.push({
                [fieldName]: {
                  '$regex': `${escapeRegExp(parsedValue)}$`,
                  '$options': 'i',
                },
              });
              break;
            case 'daterange':
              const date = parsedValue.split('/');
              const fromDate = new Date(date[0]);
              const toDate = new Date(date[1]);

              fieldsQuery.push({
                [fieldName]: {
                  '$gte': new Date(fromDate.toISOString()),
                  '$lte': new Date(toDate.toISOString()),
                },
              });
              break;
            case 'exist':
              fieldsQuery.push({
                [fieldName]: {
                  '$exists': true,
                },
              });
              break;
            case 'nil':
              fieldsQuery.push({
                [fieldName]: {
                  '$eq': null,
                },
              });
              break;
            case 'blank':
              fieldsQuery.push({
                [fieldName]: {
                  '$eq': '',
                },
              });
              break;
            default:
              break;
          }
        }
      }
    }
  }

  if (condition === '') {
    return fieldsQuery;
  } else return ({
    [condition]: fieldsQuery,
  });
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  let tempArr = [...arr];
  if (newIndex >= tempArr.length) {
    let k = newIndex - tempArr.length + 1;
    while (k--) {
      tempArr.push(undefined);
    }
  }
  tempArr.splice(newIndex, 0, tempArr.splice(oldIndex, 1)[0]);
  return tempArr;
};

const getClosest = function(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function(s) {
          let matches = (this.document || this.ownerDocument).querySelectorAll(
              s),
              i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {
          }
          return i > -1;
        };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

export const addAlertToDOM = (selector, type = 'primary', text = '', classNames = '', duration = 3000, html = false) => {
  const selectedElement = document.querySelector(selector);
  if (selectedElement) {
    // if (html) {
    selectedElement.insertAdjacentHTML(
        'afterbegin',
        `<div class="db-alert ${type} ${classNames} text-left">
                   <div class="db-alert-icon-section">
                      <svg 
                        aria-hidden="true" 
                        focusable="false" 
                        data-prefix="fas" 
                        data-icon="bell" 
                        class="bell-icon svg-inline--fa fa-bell fa-w-14" 
                        role="img" 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 448 512"
                      >
                          <path fill="currentColor" d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path>
                      </svg>
                   </div>
                   ${html ?
            `<div class="db-alert-content-section">${text}</div>` :
            `<p>${text}</p>`}
                </div>`,
    );
    const alertElements = selectedElement.getElementsByClassName('db-alert');
    setTimeout(() => {
      if (alertElements.length > 0) {
        const childEle = alertElements[0];
        childEle.style.opacity = '0';
        setTimeout(() => {
          childEle.parentNode.removeChild(childEle);
        }, 1000);
      }
    }, duration);
  }
};

export const escapeRegExp = text => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g,
    '\\$&');

const fallbackCopyTextToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
};

export const calculateFixedWidthForColumn = (index, columnHeaders) => {
  let width = 0;
  if (index > 0) {
    for (let i = 0; i < index; i++) {
      width += columnHeaders[i]?.width ?? 240;
    }
  }
  return width;
};

export function toCapitalize(input) {
  return input.toLowerCase().
      split(' ').
      map(s => s.charAt(0).toUpperCase() + s.substring(1)).
      join(' ');
}

export function toPascalCase(string) {
  return `${string}`.replace(new RegExp(/[-_]+/, 'g'), ' ').
      replace(new RegExp(/[^\w\s]/, 'g'), '').
      replace(new RegExp(/\s+(.)(\w+)/, 'g'),
          ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`).
      replace(new RegExp(/\s/, 'g'), '').
      replace(new RegExp(/\w/), s => s.toUpperCase());
}

export const getCalculatedTypeAndSubType = (type, subType) => {
  let calculatedType = 'default';
  let calculatedSubType = 'default';

  if (typeof type != 'undefined' && type != null) {
    calculatedType = type === 'bool' ?
        'enum' :
        type === 'object' ? 'default' : type;
  }

  if (calculatedType === 'date') {
    calculatedSubType = subType || 'date';
  } else if (calculatedType === 'string') {
    calculatedSubType = subType || 'base';
  } else if (calculatedType === 'enum') {
    calculatedSubType = subType || 'bool';
  } else if (calculatedType === 'number') {
    calculatedSubType = subType || 'number';
  }

  return {calculatedType, calculatedSubType};
};

export function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const convertColumnNameToClassName = text => {
  return text.replace(/[^a-zA-Z ]/g, ' ').
      trim().
      replace(/  +/g, ' ').
      replaceAll(' ', '-').
      toLowerCase();
};

export const parseSubType = st => {
  return st === 'bool' ? 'Boolean' : toCapitalize(st.replaceAll('_', ' '));
};

export function memoize(func) {
  let cache = {};
  return function() {
    const key = JSON.stringify(arguments);
    if (cache[key]) {
      return cache[key];
    } else {
      const val = func.apply(this, arguments);
      cache[key] = val;
      return val;
    }
  };
}

function getPositionAtCenter(element) {
  const {top, left, width, height} = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}

export const getDistanceBetweenElements = (a, b) => {
  const aElement = document.body.querySelector(a);
  const bElement = document.body.querySelector(b);
  const aPosition = getPositionAtCenter(aElement);
  const bPosition = getPositionAtCenter(bElement);
  return {left: aPosition.x - bPosition.x, right: bPosition.y - bPosition.y};
};

export const downloadFileFromUrl = (fileName, fileUrl) => {
  console.log(fileName, fileUrl);
  // Construct the 'a' element
  let link = document.createElement('a');
  link.download = fileName;
  link.target = '_blank';

  // Construct the URI
  link.href = fileUrl;
  document.body.appendChild(link);
  console.log(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
};

export const parseTimeout = memoize((val) => {
  if (val) {
    const lowercaseVal = val.toLowerCase();
    if (lowercaseVal.includes('ms')) {
      const parsedVal = lowercaseVal.split('ms');
      if (parsedVal.length === 2) {
        return {
          timeoutValue: parsedVal[0],
          timeoutUnit: 'ms',
        };
      } else return {timeoutValue: 15, timeoutUnit: 'ms'};
    } else if (lowercaseVal.includes('s')) {
      const parsedVal = lowercaseVal.split('s');
      if (parsedVal.length === 2) {
        return {
          timeoutValue: parsedVal[0],
          timeoutUnit: 's',
        };
      } else return {timeoutValue: 15, timeoutUnit: 's'};
    } else return {timeoutValue: 15, timeoutUnit: 'ms'};
  } else return {timeoutValue: 15, timeoutUnit: 's'};
});

export const getCookie = (name) => {
  let dc,
      prefix,
      begin,
      end;

  dc = document.cookie;
  prefix = name + '=';
  begin = dc.indexOf('; ' + prefix);
  end = dc.length; // default to end of the string

  // found, and not in first position
  if (begin !== -1) {
    // exclude the "; "
    begin += 2;
  } else {
    //see if cookie is in first position
    begin = dc.indexOf(prefix);
    // not found at all or found as a portion of another cookie name
    if (begin === -1 || begin !== 0) return null;
  }

  // if we find a ";" somewhere after the prefix position then "end" is that position,
  // otherwise it defaults to the end of the string
  if (dc.indexOf(';', begin) !== -1) {
    end = dc.indexOf(';', begin);
  }

  return decodeURI(dc.substring(begin + prefix.length, end)).replace(/\"/g, '');
};

export const getParameterByName = function() {
  let queries = window.location.search.substring(1).split('&'), processed = {};
  for (let query of queries) {
    let [name, value] = query.split('=');
    processed[decodeURIComponent(name)] = value ? decodeURIComponent(value) : '';
  }

  return function(name) {
    if (typeof processed[name] !== 'undefined')
      return processed[name];
    else
      return null;
  };
}();

export const sortByAlphabets = (a,b) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if( a === b) return 0;
    return a < b ? -1 : 1;
};

export const sortDatabaseCollections = o => {
  return Object.keys(o).sort(sortByAlphabets).reduce((r, k) => {
    const tempArr = { ...r };
    tempArr[k] = o[k].sort((a, b) =>  {
      const { collection_name: aCollectionName } = a;
      const { collection_name: bCollectionName } = b;
      if( aCollectionName === bCollectionName) return 0;
    return aCollectionName < bCollectionName ? -1 : 1;      
    });
   return tempArr;
  }, {})
};

export const convertToStringIfObject = data => {
  return new Promise((resolve, reject) => {
    if(data) {
      if(typeof data === 'string'){
        resolve(data);
      } else {
        let result = '';
        try {
          result = JSON.parse(data);
        } catch(error) {
          reject(error.message);
        }
        resolve(result);
      }
    } else return '';
  })
};

export const slugify = (string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}