import React, {useEffect, useState} from 'react';

const DTHDefaultFilterLayout = props => {
  const [defaultFilterData, setDefaultFilterData] = useState({
    fieldCondition: 'eq',
    fieldValue: '',
  });

  const {
    data: {namespace},
    filterData,
    onFilterChange,
  } = props;

  useEffect(() => {
    if (filterData && filterData.hasOwnProperty(namespace)) {
      const {
        fieldCondition = 'eq',
        fieldValue = '',
      } = filterData[namespace];
      setDefaultFilterData({
        fieldCondition,
        fieldValue,
      });
    }
  }, [filterData, namespace]);

  const handleBaseFilterData = event => {
    const {name = '', value = ''} = event.target;
    setDefaultFilterData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const {
    fieldCondition,
    fieldValue,
  } = defaultFilterData;

  const applyFilter = () => {
    let tempFilterData = {...filterData};
    tempFilterData[namespace] = defaultFilterData;
    onFilterChange(tempFilterData);
  };

  const resetFilter = () => {
    let tempFilterData = {...filterData};
    if (tempFilterData.hasOwnProperty(namespace)) {
      delete tempFilterData[namespace];
      onFilterChange(tempFilterData);
    }
    setDefaultFilterData({
      fieldCondition: 'eq',
      fieldValue: '',
    });
  };

  return (
      <>
        <div className="dropdown-inner-section">
          <select
              id={'fieldCondition'}
              name={'fieldCondition'}
              className="db-select"
              value={fieldCondition}
              onChange={handleBaseFilterData}
          >
            <option value="eq">Equals to</option>
            <option value="ne">Not equals to</option>
            <option value="exist">Exist</option>
            <option value="nil">Nil</option>
            <option value="blank">Blank</option>
          </select>
          <input
              type="text"
              id={'fieldValue'}
              name={'fieldValue'}
              className="db-input"
              value={['exist', 'nil', 'blank'].includes(fieldCondition) ?
                  '' :
                  fieldValue}
              disabled={['exist', 'nil', 'blank'].includes(fieldCondition)}
              onChange={handleBaseFilterData}
          />
        </div>
        <div className="dropdown-inner-action-section">
          <button
              className="filter"
              type="button"
              onClick={applyFilter}
          >Filter
          </button>
          <button
              className="clear"
              type="button"
              onClick={resetFilter}
          >Clear
          </button>
        </div>
      </>
  );
};

export default DTHDefaultFilterLayout;
