import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {isEmptyObject} from '../../helpers/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import noDataImage from '../../assets/images/nodata.png';

const Home = () => {
  const {loadingDCNames, databaseCollectionNames} = useSelector(state => state.util);

  const buildDatabaseCollectionList = () => {
    if (!isEmptyObject(databaseCollectionNames)) {
      let collectionList = [];
      for (const dbName in databaseCollectionNames) {
        if (databaseCollectionNames.hasOwnProperty(dbName)) {
          collectionList.push(
              <li key={dbName}>
                  {dbName.split("__").length>1?dbName.split("__")[0]:dbName}
                <ul>
                  {
                    databaseCollectionNames[dbName].map(({ db_name='', collection_name = '--'})=> (
                        <li key={`${dbName}-${collection_name}`}>
                          <Link
                              to={`/app/data-table/db-name=${db_name!=''?db_name:dbName}&collection-name=${collection_name}`}>
                            {collection_name}
                          </Link>
                        </li>
                    ))
                  }
                </ul>
              </li>,
          );
        }
      }
      return collectionList;
    }
  };

  return (
      <>
        <h3 className="db-top-heading">Home</h3>
        <div className="db-main-content">
          {
            loadingDCNames ? (
                <h2 className="text-center"><FontAwesomeIcon className="mr-5" icon={faSpinner} spin/> Loading.. </h2>
            ) : isEmptyObject(databaseCollectionNames) ? (
                <div className="text-center mt-40">
                  <img src={noDataImage} alt="No data found"/>
                  <h2>No data found</h2>
                  <p>No database and collections found.</p>
                </div>
            ) : (
                <ul>
                  {buildDatabaseCollectionList()}
                </ul>
            )
          }
        </div>
      </>
  );
};

export default Home;
