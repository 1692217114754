import {
  faChevronDown,
  faChevronUp,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Switch from 'react-switch';
import ReactTooltip from 'react-tooltip';
import {
  parseTimeout,
  setInnerObjValue
} from '../../../helpers/util';
import DBCodeEditor from '../../db_code_editor/DBCodeEditor';
import './PreAggregateConfiguration.scss';

const PreAggregateConfiguration = props => {
  const [showFields, setFieldsVisibility] = useState(false);
  const {globalTimeout} = window['runConfig'];

  const {
    data: {
      label = '',
      timeout = globalTimeout,
      display = true,
      recordInSnapshot = false,
      isCached = false,
      equals = 0,
      lcl = 0,
      hcl = 0,
      between: {
        from = 0,
        to = 0,
      },
      query = '[]',
    },
    index,
    preAggregatesList,
    onChange,
  } = props;
  const {timeoutValue, timeoutUnit} = parseTimeout(timeout);

  const toggleContentVisibility = () => setFieldsVisibility(prevState => !prevState);

  const handleFieldsChange = (event, dataType) => {
    const {name, value} = event.target;
    const tempPreAggregatesList = [...preAggregatesList];

    // let filtered = [];
    // if (name === 'label') {
    //   filtered = tempPreAggregatesList.filter(val => val[name] === value);
    // }

    // if (filtered.length > 0) {
    //   addAlertToDOM('.pre-aggregate-list-section > div:nth-child(' + (index + 1) + ') > .pre-aggregate-config-content-section', 'warning', 'Label must be unique. Please change the label', 'mb-10');
    //   return;
    // }

    let parsedValue;
    if (dataType === 'bool') {
      parsedValue = value === 'true';
    } else if (dataType === 'number') {
      parsedValue = Number(value);
    } else parsedValue = value;

    if (name.includes('between')) {
      setInnerObjValue(name, parsedValue, tempPreAggregatesList[index]);
    } else tempPreAggregatesList[index][name] = parsedValue;

    onChange(tempPreAggregatesList);
  };

  const handleTimeoutValueChange = e => {
    const tempPreAggregatesList = [...preAggregatesList];
    tempPreAggregatesList[index].timeout = (e.target.value ?? 15) + timeoutUnit;
    onChange(tempPreAggregatesList);
  };

  const handleTimeoutUnitChange = e => {
    const tempPreAggregatesList = [...preAggregatesList];
    const selectedTimeoutUnit = e.target.value;
    let tempTimeoutValue = timeoutValue;

    if (selectedTimeoutUnit === 'ms') {
      tempTimeoutValue *= 1000;
    } else if (selectedTimeoutUnit === 's') {
      if (1000 <= tempTimeoutValue) {
        tempTimeoutValue /= 1000;
      } else tempTimeoutValue = 1;
    }
    tempPreAggregatesList[index].timeout = tempTimeoutValue + selectedTimeoutUnit;
    onChange(tempPreAggregatesList);
  }

  const handleSwitchChange = (fieldName, checked) => {
    const tempPreAggregatesList = [...preAggregatesList];
    tempPreAggregatesList[index][fieldName] = checked;
    onChange(tempPreAggregatesList);
  };

  const handleCodeEditorChange = data => {
    const tempPreAggregatesList = [...preAggregatesList];
    tempPreAggregatesList[index]['query'] = data;
    onChange(tempPreAggregatesList);
  };

  const handleDelete = () => {
    let tempPreAggregatesList = [...preAggregatesList];
    tempPreAggregatesList.splice(index, 1);
    onChange(tempPreAggregatesList);
  };

  return (
      <div className="pre-aggregate-config-section">
        <div
            className="pre-aggregate-config-header-section"
            onClick={toggleContentVisibility}
        >
          <div className="pre-aggregate-config-title-wrapper">
            <p className="pre-aggregate-config-title">Pre-Aggregate {index + 1} {label !== '' && `(${label})`}</p>
          </div>
          {
            showFields ?
                <FontAwesomeIcon icon={faChevronUp}/> :
                <FontAwesomeIcon icon={faChevronDown}/>
          }
        </div>
        {
          showFields && (
              <div className="pre-aggregate-config-content-section">
                <Container fluid>
                  <Row gutterWidth={15}>
                    <Col className="mt-10" xs={12} sm={6} lg={3}>
                      <label
                          htmlFor="label"
                          className="column-section-label"
                      >Label :</label>
                      <input
                          type="text"
                          className="db-input"
                          name="label"
                          value={label}
                          onChange={event => handleFieldsChange(event, 'string')}
                      />
                    </Col>
                    <Col className="mt-10" xs={12} sm={6} lg={2}>
                      <label
                          htmlFor="timeout"
                          className="column-section-label"
                      >Timeout :</label>
                      <div className="db-input-group-wrapper full-width mt-6 mb-5">
                        <div className="db-input-group">
                          <input
                              type="number"
                              min={1}
                              className="db-input ml-10"
                              value={timeoutValue}
                              onChange={handleTimeoutValueChange}
                          />
                          <select
                              className="db-select"
                              title="Unit"
                              value={timeoutUnit}
                              onChange={handleTimeoutUnitChange}
                          >
                            <option value="s">sec</option>
                            <option value="ms">ms</option>
                          </select>
                        </div>
                      </div>
                      {/*<input*/}
                      {/*    type="text"*/}
                      {/*    className="db-input"*/}
                      {/*    name="timeout"*/}
                      {/*    value={timeout}*/}
                      {/*    onChange={event => handleFieldsChange(event, 'string')}*/}
                      {/*/>*/}
                    </Col>
                    <Col className="mt-10" xs={12} sm={4} lg={2}>
                      <div className="column-section-switch-wrapper">
                        <label
                            htmlFor="display-switch"
                            className="column-section-label mr-15"
                        >Display :</label>
                        <Switch
                            onChange={checked => handleSwitchChange('display',
                                checked)}
                            checked={display}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={15}
                            width={40}
                            className="react-switch"
                            id="display-switch"
                        />
                      </div>
                    </Col>
                    <Col className="mt-10" xs={12} sm={4} lg={2}>
                      <div className="column-section-switch-wrapper">
                        <label
                            htmlFor="is-cached-switch"
                            className="column-section-label mr-15"
                        >Cached :</label>
                        <Switch
                            onChange={checked => handleSwitchChange('isCached',
                                checked)}
                            checked={isCached}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={15}
                            width={40}
                            className="react-switch"
                            id="is-cached-switch"
                        />
                      </div>
                    </Col>
                    <Col className="mt-10" xs={12} sm={4} lg={3}>
                      <div className="column-section-switch-wrapper">
                        <label
                            htmlFor="recordInSnapshot-switch"
                            className="column-section-label mr-15"
                        >Record In Snapshot :</label>
                        <Switch
                            onChange={checked => handleSwitchChange(
                                'recordInSnapshot', checked)}
                            checked={recordInSnapshot}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={15}
                            width={40}
                            className="react-switch"
                            id="recordInSnapshot-switch"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row gutterWidth={15}>
                    <Col xs={12}>
                      <p className="strike-text"><span>Thresholds :</span></p>
                    </Col>
                    <Col className="mt-10" xs={12} sm={6} lg={3}>
                      <label
                          htmlFor="between"
                          className="column-section-label"
                      >Between :</label>
                      <Row gutterWidth={15}>
                        <Col xs={6}>
                          <input
                              type="number"
                              className="db-input"
                              name="between-from"
                              value={from}
                              onChange={event => handleFieldsChange(event,
                                  'number')}
                          />
                        </Col>
                        <Col xs={6}>
                          <input
                              type="number"
                              className="db-input"
                              name="between-to"
                              value={to}
                              onChange={event => handleFieldsChange(event,
                                  'number')}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="mt-10" xs={12} sm={6} lg={3}>
                      <label
                          htmlFor="equals"
                          className="column-section-label"
                      >Equal :</label>
                      <input
                          type="number"
                          className="db-input"
                          name="equals"
                          value={equals}
                          onChange={event => handleFieldsChange(event,
                              'number')}
                      />
                    </Col>
                    <Col className="mt-10" xs={12} sm={6} lg={3}>
                      <label
                          htmlFor="lcl"
                          className="column-section-label"
                      >LCL :</label>
                      <input
                          type="number"
                          className="db-input"
                          name="lcl"
                          value={lcl}
                          onChange={event => handleFieldsChange(event,
                              'number')}
                      />
                    </Col>
                    <Col className="mt-10" xs={12} sm={6} lg={3}>
                      <label
                          htmlFor="hcl"
                          className="column-section-label"
                      >HCL :</label>
                      <input
                          type="number"
                          className="db-input"
                          name="hcl"
                          value={hcl}
                          onChange={event => handleFieldsChange(event,
                              'number')}
                      />
                    </Col>
                    <Col xs={12}>
                      <p className="strike-text"/>
                    </Col>
                    <Col className="mt-10" xs={12}>
                      <Row>
                        <Col xs={6}>
                          <label htmlFor="filter-source-data"
                                 className="column-section-label">DB Query
                            :</label>
                        </Col>
                        <Col xs={6} className="text-right">
                          <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="column-section-information-icon"
                              data-tip
                              data-for="pre-aggregate-query-tooltip"
                          />
                          <ReactTooltip
                              id="pre-aggregate-query-tooltip"
                              globalEventOff="click"
                              aria-haspopup="true"
                              className="pre-aggregate-query-tooltip-section"
                          >
                            <Row className="text-left">
                              <Col xs={12} className="mb-10">
                                <span>Sample DB query :</span>
                              </Col>
                              <Col xs={12} className="mb-10">
                            <span>
                              {
                                `[
                                    {
                                      "$group": {
                                        "_id": "",
                                        "totalIncome": {
                                          "$sum": "FIRST_PMT_NET_SALES"
                                        }
                                      }
                                    }
                                  ]`
                              }
                            </span>
                              </Col>
                            </Row>
                          </ReactTooltip>
                        </Col>
                      </Row>
                      <DBCodeEditor
                          className="mt-6"
                          value={query}
                          height="250px"
                          onChange={handleCodeEditorChange}
                      />
                    </Col>
                    <Col className="mt-10" xs={12}>
                      <button
                          className="db-button db-button--danger"
                          onClick={handleDelete}
                      >Delete
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
          )
        }
      </div>
  );
};

export default PreAggregateConfiguration;
