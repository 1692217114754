import React from 'react';
import {useHistory} from 'react-router-dom';
import './NoPage.scss';
import pageNotFoundImage from '../../assets/images/404.png';

const NoPage = () => {
  const history = useHistory();

  const redirectToHomepage = () => {
    history.push('/');
  };

  return (
      <>
        <h3 className="db-top-heading">Page not found</h3>
        <div className="db-main-content text-center">
          <div className="pnf-section">
            <img
                className="pnf-image"
                src={pageNotFoundImage}
                alt="Page not found"
            />
            <h2 className="pnf-sub-title">page not found</h2>
            <p>The page you are looking does not exist.</p>
            <button className="db-button db-button--primary"
                    onClick={redirectToHomepage}>Go to Home
            </button>
          </div>
        </div>
      </>
  );
};

export default NoPage;
