import React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import FieldFilter from './field_filter/FieldFilter';
import JsonFilter from './json_filter/JsonFilter';
import 'react-tabs/style/react-tabs.css';

const ExternalFilters = ({ fetchDataForcefully }) => {
  const {devMode} = useSelector(state => state.util);
  const {isJsonFilterActive} = useSelector(state => state.tableData);

  return (
      <Tabs className={isJsonFilterActive ? "filter-tabs json-filter__active mb-10" : "filter-tabs mb-10"}>
        <TabList>
          {devMode && <Tab>Raw Query</Tab>}
          <Tab>Filter</Tab>
        </TabList>
        {
          devMode && (
              <TabPanel>
                <JsonFilter fetchDataForcefully={fetchDataForcefully}/>
              </TabPanel>
          )
        }
        <TabPanel>
          <FieldFilter fetchDataForcefully={fetchDataForcefully}/>
        </TabPanel>
      </Tabs>
  );
};

export default ExternalFilters;
