import React, {useEffect, useReducer, useRef} from 'react';
import Draggable from 'react-draggable';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalculator, faSpinner} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import './DataTableHeaderAggregation.scss';
import {useSelector} from 'react-redux';
import {useDidUpdateEffect} from '../../helpers/customHooks';

const initialState = {
  showMenu: false,
  pinned: false,
  loading: false,
  aggregateType: 'none',
  aggregateValue: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_loading_status':
      return {
        ...state,
        loading: action.payload,
      };
    case 'update_dropdown_menu_visibility':
      return {
        ...state,
        showMenu: action.payload,
      };
    case 'update_pin_status':
      return {
        ...state,
        pinned: action.payload,
      };
    case 'update_dropdown_menu_visibility_and_pin_status':
      return {
        ...state,
        showMenu: action.payload.showMenu,
        pinned: action.payload.pinned,
      };
    case 'update_aggregation_type':
      return {
        ...state,
        aggregateType: action.payload,
      };
    case 'update_aggregation_value':
      return {
        ...state,
        aggregateValue: action.payload,
      };
    case 'update_aggregation_value_loading_status':
      return {
        ...state,
        aggregateValue: action.payload.aggregateValue,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

const DataTableHeaderAggregation = ({data}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const didFirstMount = useRef(false);
  const dropdownMenuRef = useRef(null);
  const nodeRef = useRef(null);
  const {
    databaseName,
    collectionName,
    timeout,
    dbQuery,
    filterData,
  } = useSelector(state => state.tableData);

  const {showMenu, pinned, loading, aggregateType, aggregateValue} = state;

  useEffect(() => {
    if (didFirstMount.current) {
      if (aggregateType !== 'none') {
        dispatch({
          type: 'update_loading_status',
          payload: true,
        });
        getColumnAggregateData();
      }
    } else didFirstMount.current = true;
  }, [aggregateType]);

  useDidUpdateEffect(() => {
    dispatch({
      type: 'update_aggregation_type',
      payload: 'none',
    });
  }, [filterData]);

  useEffect(() => {
    if (!showMenu) {
      return;
    }

    document.addEventListener('click', hideDropdownMenu);

    return () => document.removeEventListener('click', hideDropdownMenu);
  }, [showMenu, pinned]);

  const showDropdownMenu = event => {
    event.preventDefault();
    dispatch({
      type: 'update_dropdown_menu_visibility',
      payload: true,
    });
  };

  const hideDropdownMenu = event => {
    if (dropdownMenuRef && dropdownMenuRef.current.contains(event.target) ===
        false && pinned === false) {
      dispatch({
        type: 'update_dropdown_menu_visibility',
        payload: false,
      });
    }
  };

  const hideDropDownForcibly = () => {
    dispatch({
      type: 'update_dropdown_menu_visibility_and_pin_status',
      payload: {
        showMenu: false,
        pinned: false,
      },
    });
  };

  const handlePinChange = () => {
    dispatch({
      type: 'update_pin_status',
      payload: !pinned,
    });
  };

  const getColumnAggregateData = () => {
    const {namespace} = data;

    const query = [
      ...dbQuery,
      {
        '$group': {
          '_id': '',
          [namespace]: {
            [`$${aggregateType}`]: `$${namespace}`,
          },
        },
      },
    ];

    const reqData = {
      databaseName: databaseName,
      collectionName: collectionName,
      query,
      timeout,
    };

    axios.post('/data-browser/get-column-aggregate',
        reqData,
        {
          headers: {
            'X-CLIENT-ID': 'column-aggregation',
          },
        }).then(response => {
      const tempAggregateValue = response?.data?.data[0]?.[namespace] ?? 0;
      if (response.status === 200) {
        dispatch({
          type: 'update_aggregation_value_loading_status',
          payload: {
            aggregateValue: tempAggregateValue,
            loading: false,
          },
        });
      } else {
        dispatch({
          type: 'update_aggregation_value_loading_status',
          payload: {
            aggregateValue: 0,
            loading: false,
          },
        });
      }
    }).catch(() => {
      dispatch({
        type: 'update_aggregation_value_loading_status',
        payload: {
          aggregateValue: 0,
          loading: false,
        },
      });
    });

  };

  const handleAggregateTypeChange = event => {
    dispatch({
      type: 'update_aggregation_type',
      payload: event.target.value,
    });
  };

  return (
      <div
          className={showMenu ?
              'dropdown dtha-section active' :
              'dropdown dtha-section'}
          ref={dropdownMenuRef}
          title="Aggregation"
      >
        <FontAwesomeIcon
            icon={faCalculator}
            className="dtha-aggregate-icon"
            onClick={showDropdownMenu}
        />
        {
          showMenu && (
              <Draggable
                  defaultClassName="db-draggable"
                  defaultPosition={{x: -145, y: 25}}
                  handle=".drag-handle"
                  nodeRef={nodeRef}
              >
                <div className="box no-cursor" ref={nodeRef}>
                  <div className="dropdown-inner">
                    <div className="dropdown-inner-header-action-section">
                      <div
                          className="dropdown-inner-header-action-section-left">
                        <p className="filter-layout-title">Aggregation:</p>
                      </div>
                      <div
                          className="dropdown-inner-header-action-section-right">
                        <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="pushpin"
                            width="15px"
                            height="15px"
                            fill="currentColor"
                            aria-hidden="true"
                            className={pinned ? 'pin-icon active' : 'pin-icon'}
                            onClick={handlePinChange}
                        >
                          <path
                              d="M878.3 392.1L631.9 145.7c-6.5-6.5-15-9.7-23.5-9.7s-17 3.2-23.5 9.7L423.8 306.9c-12.2-1.4-24.5-2-36.8-2-73.2 0-146.4 24.1-206.5 72.3a33.23 33.23 0 00-2.7 49.4l181.7 181.7-215.4 215.2a15.8 15.8 0 00-4.6 9.8l-3.4 37.2c-.9 9.4 6.6 17.4 15.9 17.4.5 0 1 0 1.5-.1l37.2-3.4c3.7-.3 7.2-2 9.8-4.6l215.4-215.4 181.7 181.7c6.5 6.5 15 9.7 23.5 9.7 9.7 0 19.3-4.2 25.9-12.4 56.3-70.3 79.7-158.3 70.2-243.4l161.1-161.1c12.9-12.8 12.9-33.8 0-46.8zM666.2 549.3l-24.5 24.5 3.8 34.4a259.92 259.92 0 01-30.4 153.9L262 408.8c12.9-7.1 26.3-13.1 40.3-17.9 27.2-9.4 55.7-14.1 84.7-14.1 9.6 0 19.3.5 28.9 1.6l34.4 3.8 24.5-24.5L608.5 224 800 415.5 666.2 549.3z"/>
                        </svg>
                        <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="drag"
                            width="15px"
                            height="15px"
                            fill="currentColor"
                            aria-hidden="true"
                            className="drag-icon drag-handle cursor"
                        >
                          <path
                              d="M909.3 506.3L781.7 405.6a7.23 7.23 0 00-11.7 5.7V476H548V254h64.8c6 0 9.4-7 5.7-11.7L517.7 114.7a7.14 7.14 0 00-11.3 0L405.6 242.3a7.23 7.23 0 005.7 11.7H476v222H254v-64.8c0-6-7-9.4-11.7-5.7L114.7 506.3a7.14 7.14 0 000 11.3l127.5 100.8c4.7 3.7 11.7.4 11.7-5.7V548h222v222h-64.8c-6 0-9.4 7-5.7 11.7l100.8 127.5c2.9 3.7 8.5 3.7 11.3 0l100.8-127.5c3.7-4.7.4-11.7-5.7-11.7H548V548h222v64.8c0 6 7 9.4 11.7 5.7l127.5-100.8a7.3 7.3 0 00.1-11.4z"/>
                        </svg>
                        <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="close"
                            width="15px"
                            height="15px"
                            fill="currentColor"
                            aria-hidden="true"
                            className="close-icon"
                            onClick={hideDropDownForcibly}
                        >
                          <path
                              d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"/>
                        </svg>
                      </div>
                    </div>
                    <div className="dropdown-inner-section">
                      <label htmlFor="aggregate-type">Type:</label>
                      <select
                          id="aggregate-type"
                          name="aggregate-type"
                          className="db-select"
                          value={aggregateType}
                          onChange={handleAggregateTypeChange}
                      >
                        <option value="none">Select type</option>
                        <option value="sum">Total</option>
                        <option value="avg">Average</option>
                        <option value="stdDevSamp">Standard Deviation</option>
                        <option value="min">Minimum</option>
                        <option value="max">Maximum</option>
                      </select>
                      {
                        loading ?
                            (
                                <div className="loading-section">
                                  <FontAwesomeIcon
                                      className="loading-icon"
                                      icon={faSpinner}
                                      spin
                                  />
                                </div>
                            ) :
                            aggregateType !== 'none' &&
                            <h2 className="dtha-aggregate-value">{aggregateValue.toFixed(
                                2)}</h2>
                      }
                    </div>
                  </div>
                </div>
              </Draggable>
          )
        }
      </div>
  );
};

DataTableHeaderAggregation.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DataTableHeaderAggregation;
