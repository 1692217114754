import {UPDATE_USER_DATA, UPDATE_USER_DATA_STATUS} from '../actions/types';

const initialState = {
  userDataStatus: 'loading',
  userData: {},
};

const UserDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.payload.userData,
        userDataStatus: action.payload.userDataStatus,
      };
    case UPDATE_USER_DATA_STATUS:
      return {
        ...state,
        userDataStatus: action.payload,
      };
    default:
      return state;
  }
};

export default UserDataReducer;
