import React, { useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import PropTypes from 'prop-types';
import {faCompress, faExpand} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-kuroir';
import 'ace-builds/src-noconflict/ext-language_tools';

const DBCodeEditor = ({value, mode = 'json', className, height, onChange}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const editorRef = useRef(null);
  const parsedValue = typeof value === 'object' ? JSON.stringify(value, null, '\t') : value;

  useEffect(() => {
    const listener = document.addEventListener('fullscreenchange', (event) => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      if (document.fullscreenElement) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    });

    return listener;
  }, []);

  const handleEditorChange = data => onChange && onChange(data);

  const toggleFullscreen = element => {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  }

  const handleFullScreen = () => {
    if(editorRef) toggleFullscreen(editorRef.current);
  }
  const currHeight = isFullScreen ? window.screen.height : height;

  return (
    <div className='ace-editor-wrapper' ref={editorRef}>
      <AceEditor
          className={className}
          mode={mode}
          theme="kuroir"
          value={parsedValue}
          onChange={handleEditorChange}
          name="db-json-editor"
          width="100%"
          height={currHeight}
          fontSize={14}
          editorProps={{$blockScrolling: true}}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
          }}
      />
      <FontAwesomeIcon 
        className="ace-editor-fs-action"
        title={isFullScreen ? 'Minimize' : 'Maximize'}
        icon={isFullScreen ? faCompress : faExpand}
        onClick={handleFullScreen}
      />
    </div>
  );
};

DBCodeEditor.propTypes = {
	value: PropTypes.any.isRequired,
	className: PropTypes.string,
	height: PropTypes.string,
	onChange: PropTypes.func,
};

DBCodeEditor.defaultProps = {
	value: '',
	className: '',
	height: '150px',
};

export default DBCodeEditor;
