import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import lockIcon from '../assets/images/lock.png';
import warningIcon from '../assets/images/warning.png';
import {useSelector} from 'react-redux';

export const withPageAccessCheck = Component => {
  return function() {
    const [data, setData] = useState({pageStatus: 'loading'});
    const {isFullScreen} = useSelector(state => state.util);
    const location = useLocation();
    const {pageStatus} = data;

    useEffect(() => {
      setData({
        pageStatus: 'loading',
      });
      checkPageAccess();
    }, [location]);

    const checkPageAccess = () => {
      let tempPathname = location.pathname;

      if (tempPathname.includes('/app/admin/settings/db-name=') || tempPathname.includes('/app/data-table/db-name=')) {
        const splitPathname = tempPathname.split('/');
        const pathString = splitPathname.slice(0, splitPathname.length - 1).join('/');
        tempPathname = pathString + '?' + splitPathname[splitPathname.length - 1];
      }

      axios.post('/data-browser/check-permission', {
        url: tempPathname,
      }).then(response => {
        setData({
          pageStatus: 'success',
        });
      }).catch(error => {
        const status = error?.response?.status ?? '';
        if (status == 403) {
          setData({
            pageStatus: 'forbidden',
          });
        } else {
          setData({
            pageStatus: 'error',
          });
        }
      });
    };

    const redirectToHomepage = () => {
      window.location.href = '/';
    };

    const pageRefresh = () => {
      window.location.reload();
    };

    const showHeader = location.pathname.includes('/app/data-table/db-name=') && !isFullScreen ;

    if (pageStatus == 'loading') return (
        <>
          {showHeader && <h3 className="db-top-heading">Data Table</h3>}
          <h2 className="text-center mt-40">
            <FontAwesomeIcon
                className="mr-5"
                icon={faSpinner}
                spin
            /> Checking access permission..</h2>
        </>
    );

    if (pageStatus == 'forbidden') {
      return (
          <>
            {showHeader && <h3 className="db-top-heading">Data Table</h3>}
            <div className="text-center">
              <img
                  className="mt-40"
                  src={lockIcon}
                  alt="lock icon"
                  width={70}
              />
              <h2>Access Denied</h2>
              <p>You don't have permission to access this page.<br/>Contact an
                administrator to get permissions or go to homepage.</p>
              <button className="db-button db-button--primary"
                      onClick={redirectToHomepage}>Go to home
              </button>
            </div>
          </>
      );
    }
    if (pageStatus == 'error') {
      return (
          <>
            {showHeader && <h3 className="db-top-heading">Data Table</h3>}
            <div className="text-center">
              <img
                  className="mt-40"
                  src={warningIcon}
                  alt="lock icon"
                  width={70}
              />
              <h2>Error</h2>
              <p>Something went wrong. Please refresh the page.</p>
              <button
                  className="db-button db-button--primary"
                  onClick={pageRefresh}
              >Refresh
              </button>
            </div>
          </>
      );
    }

    return <Component/>;
  };
};
