import React, { useEffect, useState, useCallback } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Col, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ExportButtons from '../../components/export_buttons/ExportButtons';
import {
	CancelApiButton,
	DataTable,
	DataTableHeaderColumnPicker,
	ExternalFilters,
} from '../../components/index';
import PreAggregationList from '../../components/pre_aggregation_list/PreAggregationList';
import { getEngine } from 'db-engine';
import { FILTER_TYPE, STRING_FILTER_SUB_TYPE } from '../../helpers/constants';
import {
	useComponentWillMount,
	useDidUpdateEffect,
	usePrevious,
} from '../../helpers/customHooks';
import {
	getCalculatedTypeAndSubType,
	isEmptyObject,
	parseTimeout,
} from '../../helpers/util';
import { withPageAccessCheck } from '../../helpers/withPageAccessCheck';
import { resetTableData } from '../../redux/actions/TableDataActions';

const buildExternalFilterRows = (tableColumns, filterData) => {
	let tempFilterRows = {};
	if (
		!isEmptyObject(filterData) &&
		typeof tableColumns !== 'undefined' &&
		tableColumns !== null
	) {
		let tempCounter = 0;
		tableColumns.forEach((currColumnData) => {
			const { namespace, filter } = currColumnData;
			const currColumnFilterData = filterData[namespace];

			const getCounterValue = () => {
				tempCounter += 1;
				return tempCounter;
			};

			if (namespace !== '' && filter !== null) {
				const { trueBackendType = '', type = '', subType = '' } = filter ?? {};
				const { calculatedType, calculatedSubType } =
					getCalculatedTypeAndSubType(type, subType);

				switch (calculatedType) {
					case FILTER_TYPE.ENUM:
						if (currColumnFilterData && currColumnFilterData.length > 0) {
							const tempLfrKey = 'lfr_' + getCounterValue();
							const tempBoolFilterRow = {
								condition: 'or',
								key: tempLfrKey,
								subFilters: {},
								type: 'logic',
								readOnly: true,
							};

							currColumnFilterData.forEach((arrItem) => {
								const tempFrKey = 'fr_' + getCounterValue();
								tempBoolFilterRow.subFilters[tempFrKey] = {
									condition: ['exist', 'nil', 'blank'].includes(arrItem)
										? arrItem
										: 'eq',
									fieldName: namespace,
									key: tempLfrKey + '-subFilters-' + tempFrKey,
									type: 'normal',
									value: ['exist', 'nil', 'blank'].includes(arrItem)
										? ''
										: arrItem,
									readOnly: true,
									trueBackendType,
									filterType: calculatedType,
								};
							});
							tempFilterRows[tempLfrKey] = tempBoolFilterRow;
						}
						break;
					case FILTER_TYPE.DATE:
						if (currColumnFilterData) {
							if (
								typeof currColumnFilterData !== 'object' &&
								currColumnFilterData !== ''
							) {
								const tempSearchFrKey = 'fr_' + getCounterValue();
								tempFilterRows[tempSearchFrKey] = {
									condition: 'eq',
									fieldName: namespace,
									key: tempSearchFrKey,
									type: 'normal',
									value: currColumnFilterData,
									readOnly: true,
									trueBackendType,
									filterType: calculatedType,
								};
							} else if (
								currColumnFilterData.hasOwnProperty('fromDate') &&
								currColumnFilterData.hasOwnProperty('toDate') &&
								currColumnFilterData.fromDate !== '' &&
								currColumnFilterData.toDate !== ''
							) {
								const tempDateRangeLfrKey = 'lfr_' + getCounterValue();
								const tempDateRangeLteFrKey = 'fr_' + getCounterValue();
								const tempDateRangeGteFrKey = 'fr_' + getCounterValue();

								const tempDateRangeFilterRow = {
									condition: 'and',
									key: tempDateRangeLfrKey,
									subFilters: {},
									type: 'logic',
									readOnly: true,
								};

								tempDateRangeFilterRow.subFilters[tempDateRangeGteFrKey] = {
									condition: 'gte',
									fieldName: namespace,
									key:
										tempDateRangeLfrKey +
										'-subFilters-' +
										tempDateRangeGteFrKey,
									type: 'normal',
									value: currColumnFilterData.fromDate,
									readOnly: true,
									trueBackendType,
									filterType: calculatedType,
								};
								tempDateRangeFilterRow.subFilters[tempDateRangeLteFrKey] = {
									condition: 'lte',
									fieldName: namespace,
									key:
										tempDateRangeLfrKey +
										'-subFilters-' +
										tempDateRangeLteFrKey,
									type: 'normal',
									value: currColumnFilterData.toDate,
									readOnly: true,
									trueBackendType,
									filterType: calculatedType,
								};
								tempFilterRows[tempDateRangeLfrKey] = tempDateRangeFilterRow;
							}
						}
						break;
					case FILTER_TYPE.STRING:
						if (calculatedSubType === STRING_FILTER_SUB_TYPE.SEARCH) {
							if (typeof currColumnFilterData !== 'undefined') {
								const tempSearchFrKey = 'fr_' + getCounterValue();
								tempFilterRows[tempSearchFrKey] = {
									condition: 'contains',
									fieldName: namespace,
									key: tempSearchFrKey,
									type: 'normal',
									value: currColumnFilterData,
									readOnly: true,
									trueBackendType,
									filterType: calculatedType,
								};
							}
						} else {
							if (currColumnFilterData) {
								const dataCount = Math.round(
									Object.keys(currColumnFilterData).length / 2
								);
								if (dataCount === 1) {
									const tempDefaultFrKey = 'fr_' + getCounterValue();
									const { condition, value } = currColumnFilterData.fr1;
									tempFilterRows[tempDefaultFrKey] = {
										condition,
										fieldName: namespace,
										key: tempDefaultFrKey,
										type: 'normal',
										value: ['exist', 'nil', 'blank'].includes(condition)
											? ''
											: value,
										readOnly: true,
										trueBackendType,
										filterType: calculatedType,
									};
								} else {
									const buildBaseNumberFilterData = (data, dataCount) => {
										let tempObj = {};
										for (let i = 1; i <= dataCount; i++) {
											if (i === 1) {
												const { condition, value } = data['fr' + 1];
												const tempDefaultFrKey = 'fr_' + getCounterValue();

												tempObj[tempDefaultFrKey] = {
													condition: condition,
													fieldName: namespace,
													key: tempDefaultFrKey,
													type: 'normal',
													value: ['exist', 'nil', 'blank'].includes(condition)
														? ''
														: value,
													readOnly: true,
													trueBackendType,
													filterType: calculatedType,
												};
											} else {
												const tempBaseLfrKey = 'lfr_' + getCounterValue();
												const tempDefaultFrKey = 'fr_' + getCounterValue();
												const { condition, value } = data['fr' + i];

												tempObj = {
													[tempBaseLfrKey]: {
														condition: data['lfr' + i],
														key: tempBaseLfrKey,
														subFilters: {
															...tempObj,
															[tempDefaultFrKey]: {
																condition: condition,
																fieldName: namespace,
																key: tempDefaultFrKey,
																type: 'normal',
																value: ['exist', 'nil', 'blank'].includes(
																	condition
																)
																	? ''
																	: value,
																readOnly: true,
																trueBackendType,
																filterType: calculatedType,
															},
														},
														type: 'logic',
														readOnly: true,
													},
												};
											}
										}
										return tempObj;
									};

									tempFilterRows = {
										...tempFilterRows,
										...buildBaseNumberFilterData(
											currColumnFilterData,
											dataCount,
											false
										),
									};
								}
							}
						}
						break;
					case FILTER_TYPE.NUMBER:
						if (currColumnFilterData) {
							const dataCount = Math.round(
								Object.keys(currColumnFilterData).length / 2
							);
							if (dataCount === 1) {
								const tempDefaultFrKey = 'fr_' + getCounterValue();
								const { condition, value } = currColumnFilterData.fr1;
								tempFilterRows[tempDefaultFrKey] = {
									condition,
									fieldName: namespace,
									key: tempDefaultFrKey,
									type: 'normal',
									value: ['exist', 'nil', 'blank'].includes(condition)
										? ''
										: Number(value),
									readOnly: true,
									trueBackendType,
									filterType: calculatedType,
								};
							} else {
								const buildBaseNumberFilterData = (data, dataCount) => {
									let tempObj = {};
									for (let i = 1; i <= dataCount; i++) {
										if (i === 1) {
											const { condition, value } = data['fr' + 1];
											const tempDefaultFrKey = 'fr_' + getCounterValue();

											tempObj[tempDefaultFrKey] = {
												condition: condition,
												fieldName: namespace,
												key: tempDefaultFrKey,
												type: 'normal',
												value: ['exist', 'nil', 'blank'].includes(condition)
													? ''
													: Number(value),
												readOnly: true,
												trueBackendType,
												filterType: calculatedType,
											};
										} else {
											const tempBaseLfrKey = 'lfr_' + getCounterValue();
											const tempDefaultFrKey = 'fr_' + getCounterValue();
											const { condition, value } = data['fr' + i];

											tempObj = {
												[tempBaseLfrKey]: {
													condition: data['lfr' + i],
													key: tempBaseLfrKey,
													subFilters: {
														...tempObj,
														[tempDefaultFrKey]: {
															condition: condition,
															fieldName: namespace,
															key: tempDefaultFrKey,
															type: 'normal',
															value: ['exist', 'nil', 'blank'].includes(
																condition
															)
																? ''
																: Number(value),
															readOnly: true,
															trueBackendType,
															filterType: calculatedType,
														},
													},
													type: 'logic',
													readOnly: true,
												},
											};
										}
									}
									return tempObj;
								};

								tempFilterRows = {
									...tempFilterRows,
									...buildBaseNumberFilterData(currColumnFilterData, dataCount),
								};
							}
						}
						break;
					default:
						if (currColumnFilterData) {
							const { fieldCondition, fieldValue } = currColumnFilterData;
							const tempDefaultFrKey = 'fr_' + getCounterValue();

							tempFilterRows[tempDefaultFrKey] = {
								condition: fieldCondition,
								fieldName: namespace,
								key: tempDefaultFrKey,
								type: 'normal',
								value: ['exist', 'nil', 'blank'].includes(fieldCondition)
									? ''
									: fieldValue,
								readOnly: true,
								trueBackendType,
								filterType: calculatedType,
							};
						}
						break;
				}
			}
		});
	}

	return tempFilterRows;
};

const sortColumns = (tableColumns) => {
	let fixedColumns = [],
		columns = [];
	tableColumns.forEach((column) => {
		if (column?.visible) {
			if (column?.fixed) {
				fixedColumns.push(column);
			} else columns.push(column);
		}
	});
	return [...fixedColumns, ...columns];
};

const TableDetailed = () => {
	const [showFilters, setFilterVisibility] = useState(false);
	const {
		loading,
		databaseType,
		timeout,
		configuredTableColumns,
		filteredTableColumns,
		isTableColumnsFiltered,
		isJsonFilterActive,
		data,
		dbQuery,
		dbMatchQuery,
		sortData,
		filterData,
		filterRows,
		externalFilterRows,
		pagination,
	} = useSelector((state) => state.tableData);
	const {
		defaultDatabaseName,
		defaultCollectionName,
		isFullScreen,
		defaultFilterVisibility,
		enableColumnFilters,
		enableDownload,
	} = useSelector((state) => state.util);
	const dispatch = useDispatch();
	let { dbName, collectionName } = useParams();
	const { pageNumber, count } = pagination;
	const prevDBMatchQuery = usePrevious(dbMatchQuery);

	if (isFullScreen && defaultDatabaseName && defaultCollectionName) {
		dbName = defaultDatabaseName;
		collectionName = defaultCollectionName;
	}

	useComponentWillMount(() => {
		if (!isFullScreen) {
			dispatch(resetTableData());
		}
	});

	useEffect(() => {
		getStructure();
	}, []);

	useDidUpdateEffect(() => {
		dispatch(resetTableData()).then(() => {
			getStructure();
		});
	}, [dbName, collectionName]);

	// useDidUpdateEffect(() => {
	//   if (configuredTableColumns.length > 0) {
	//     getTableData(true);
	//   }
	// }, [configuredTableColumns]);

	useDidUpdateEffect(() => {
		let getTotalRecords = false;

		if (prevDBMatchQuery !== dbMatchQuery) {
			getTotalRecords = true;
		}

		getTableData(getTotalRecords);
	}, [dbQuery]);

	const commonTableColumns = isTableColumnsFiltered
		? filteredTableColumns
		: configuredTableColumns;
	const { timeoutValue, timeoutUnit } = parseTimeout(timeout);

	const getStructure = () => {
		dispatch({
			type: 'update_loading_status',
			payload: true,
		});
		axios
			.post(
				'/data-browser/get-structure',
				{
					databaseName: dbName,
					collectionName: collectionName,
					query: [{ $sample: { size: 10 } }],
					timeout,
				},
				{
					headers: {
						'X-CLIENT-ID': 'table-structure',
					},
				}
			)
			.then((response) => {
				const { status, data: resData } = response;
				if (status === 200) {
					const {
						databaseName,
						databaseType,
						collectionName,
						namespace,
						tableColumns,
						configuredTableColumns,
					} = resData.data;
					const dbEngine = getEngine(databaseType);
					const { dbStringifyQuery, dbMatchStringifyQuery } = dbEngine.getQuery(
						{},
						{},
						pagination
					);

					dispatch({
						type: 'update_table_config_data',
						payload: {
							databaseName,
							databaseType,
							collectionName,
							globalNamespace: namespace,
							tableColumns,
							configuredTableColumns:
								configuredTableColumns.length > 0
									? sortColumns(configuredTableColumns)
									: [],
							dbQuery: dbStringifyQuery,
							dbMatchQuery: dbMatchStringifyQuery,
							loading: configuredTableColumns.length > 0,
						},
					});
				} else {
					dispatch({
						type: 'update_loading_status',
						payload: false,
					});
				}
			})
			.catch(() => {
				dispatch({
					type: 'update_loading_status',
					payload: false,
				});
			});
	};

	const getTableData = (getTotalCount) => {
		const reqData = {
			databaseName: dbName,
			collectionName: collectionName,
			query: dbQuery,
			page: {
				pageNumber,
				count,
			},
			timeout,
		};

		axios
			.post('/data-browser/get-data', reqData, {
				headers: {
					'X-CLIENT-ID': 'table-data',
				},
			})
			.then((response) => {
				const {
					status,
					data: { data },
				} = response;
				if (status === 200) {
					dispatch({
						type: 'update_table_data',
						payload: data ?? [],
					});
					if (getTotalCount && isJsonFilterActive === false) {
						getTotalTableRecordCount(reqData);
					}
				} else {
					dispatch({
						type: 'update_table_data',
						payload: [],
					});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({
					type: 'update_table_data',
					payload: [],
				});
			});
	};

	const fetchDataForcefully = useCallback(() => {
		getTableData(true);
	}, []);

	const getTotalTableRecordCount = (reqData) => {
		let tempReqData = { ...reqData };
		tempReqData.query = dbMatchQuery;

		// if (tempReqData.query.length > 0) {
		//   if (tempReqData.query[0].hasOwnProperty('$match')) {
		//     tempReqData.query = tempReqData.query[0].$match;
		//   } else if (tempReqData.query[1].hasOwnProperty('$match')) {
		//     tempReqData.query = tempReqData.query[1].$match;
		//   } else tempReqData.query = {};
		// } else tempReqData.query = {};

		axios
			.post('/data-browser/get-count', tempReqData, {
				headers: {
					'X-CLIENT-ID': 'table-record-count',
				},
			})
			.then((response) => {
				const { status, data } = response;
				if (status === 200) {
					dispatch({
						type: 'update_table_total_record_count',
						payload: data.data,
					});
				}
			})
			.catch((errors) => console.log(errors.response));
	};

	const handleTableSortChange = (sortData) => {
		let tempSortData = { ...sortData };
		const currColumnKey = Object.keys(sortData)[0];

		if (tempSortData.hasOwnProperty(currColumnKey)) {
			if (sortData[currColumnKey] === 0) {
				delete tempSortData[currColumnKey];
			} else {
				tempSortData[currColumnKey] = sortData[currColumnKey];
			}
		} else tempSortData[currColumnKey] = sortData[currColumnKey];

		const dbEngine = getEngine(databaseType);
		const { dbStringifyQuery, dbMatchStringifyQuery } = dbEngine.getQuery(
			{ ...filterRows, ...externalFilterRows },
			tempSortData,
			pagination
		);

		dispatch({
			type: 'update_sort_data_and_database_query',
			payload: {
				sortData: tempSortData,
				dbQuery: dbStringifyQuery,
				dbMatchQuery: dbMatchStringifyQuery,
			},
		});
	};

	const handleTableFilterChange = (data) => {
		const tempExternalFilterRows = buildExternalFilterRows(
			commonTableColumns,
			data
		);
		const dbEngine = getEngine(databaseType);
		const { dbStringifyQuery, dbMatchStringifyQuery } = dbEngine.getQuery(
			{ ...externalFilterRows, ...tempExternalFilterRows },
			sortData,
			pagination
		);

		dispatch({
			type: 'update_table_filter_row_data_and_database_query',
			payload: {
				filterData: data,
				filterRows: tempExternalFilterRows,
				dbQuery: dbStringifyQuery,
				dbMatchQuery: dbMatchStringifyQuery,
			},
		});
	};

	const handleTablePaginationChange = (paginationData) => {
		const dbEngine = getEngine(databaseType);
		const { dbStringifyQuery, dbMatchStringifyQuery } = dbEngine.getQuery(
			{ ...filterRows, ...externalFilterRows },
			sortData,
			paginationData
		);
		dispatch({
			type: 'update_db_query_and_table_current_page_number',
			payload: {
				dbQuery: dbStringifyQuery,
				dbMatchQuery: dbMatchStringifyQuery,
				pageNUmber: paginationData.pageNumber,
			},
		});
	};

	const toggleFilterVisibility = () => {
		setFilterVisibility((prevState) => !prevState);
	};

	const handleTimeoutValueChange = (e) => {
		dispatch({
			type: 'update_timeout',
			payload: (e.target.value ?? 15) + timeoutUnit,
		});
	};

	const handleTimeoutUnitChange = (e) => {
		const selectedTimeoutUnit = e.target.value;
		let tempTimeoutValue = timeoutValue;

		if (selectedTimeoutUnit === 'ms') {
			tempTimeoutValue *= 1000;
		} else if (selectedTimeoutUnit === 's') {
			if (1000 <= tempTimeoutValue) {
				tempTimeoutValue /= 1000;
			} else tempTimeoutValue = 1;
		}

		dispatch({
			type: 'update_timeout',
			payload: tempTimeoutValue + selectedTimeoutUnit,
		});
	};

	const handleColumnPickerChange = (data) => {
		dispatch({
			type: 'update_filtered_columns',
			payload: {
				filteredTableColumns: data,
				isTableColumnsFiltered: true,
			},
		});
	};

	const handleResetColumnPicker = () => {
		dispatch({
			type: 'update_filtered_columns',
			payload: {
				filteredTableColumns: configuredTableColumns,
				isTableColumnsFiltered: false,
			},
		});
	};

	return (
		<>
			{!isFullScreen && (
				<h3 className='db-top-heading'>
					{dbName} - {collectionName}
				</h3>
			)}
			<div className='db-main-content'>
				{isFullScreen === false ||
				(isFullScreen === true && defaultFilterVisibility === true) ? (
					<>
						<div className='db-show-hide-filter-section'>
							<Row>
								<Col xs={12} className='db-show-hide-filter-alert-section' />
								<Col xs={10}>
									{showFilters ? (
										<button
											className='db-button db-show-hide-filter-button'
											onClick={toggleFilterVisibility}
										>
											<FontAwesomeIcon icon={faChevronUp} className='mr-10' />
											Hide filters
										</button>
									) : (
										<button
											className='db-button db-show-hide-filter-button'
											onClick={toggleFilterVisibility}
										>
											<FontAwesomeIcon icon={faChevronDown} className='mr-10' />
											Show filters
										</button>
									)}
									<DataTableHeaderColumnPicker
										title='Select table columns'
										configuredTableColumns={configuredTableColumns}
										filteredTableColumns={filteredTableColumns}
										onReset={handleResetColumnPicker}
										onChange={handleColumnPickerChange}
									/>
									<div className='db-input-group-wrapper ml-10'>
										<div className='db-input-group'>
											<div className='db-input-group-prepend'>
												<span className='db-input-group-text'>Timeout</span>
											</div>
											<input
												type='number'
												min={1}
												className='db-input w-100 ml-10'
												value={timeoutValue}
												onChange={handleTimeoutValueChange}
											/>
											<select
												className='db-select'
												title='Unit'
												value={timeoutUnit}
												onChange={handleTimeoutUnitChange}
											>
												<option value='s'>sec</option>
												<option value='ms'>ms</option>
											</select>
										</div>
									</div>
									<CancelApiButton />
								</Col>
								{enableDownload && (
									<Col xs={2} className='text-right'>
										<ExportButtons />
									</Col>
								)}
							</Row>
						</div>
						{showFilters && (
							<ExternalFilters fetchDataForcefully={fetchDataForcefully} />
						)}
					</>
				) : enableDownload ? (
					<div className='db-show-hide-filter-section'>
						<Col xs={12} className='text-right'>
							<ExportButtons />
						</Col>
					</div>
				) : null}
				{!isFullScreen && (
					<PreAggregationList
						dbName={dbName}
						collectionName={collectionName}
						timeout={timeout}
					/>
				)}
				<DataTable
					loading={loading}
					dbName={dbName}
					collectionName={collectionName}
					columnHeaders={commonTableColumns}
					enableColumnFilters={enableColumnFilters}
					data={data}
					sortData={sortData}
					onSortChange={handleTableSortChange}
					filterData={filterData}
					onFilterChange={handleTableFilterChange}
					pagination={pagination}
					onPaginationChange={handleTablePaginationChange}
					// tableHeight={500}
				/>
			</div>
		</>
	);
};

export default withPageAccessCheck(TableDetailed);
