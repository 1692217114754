import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import { Col, Row } from 'react-grid-system';
import { DataTable, Modal } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { addAlertToDOM } from '../../helpers/util';
import './Tags.scss';
import { withPageAccessCheck } from '../../helpers/withPageAccessCheck';
import {UPDATE_GLOBAL_ERROR_DATA} from '../../redux/actions/types';
import { useDispatch } from 'react-redux';

const tableColumnHeaders = [
	{
		label: 'S NO',
		key: '',
		namespace: '',
		orderIndex: 0,
		width: 70,
	},
	{
		label: 'Name',
		key: 'label',
		namespace: 'label',
		orderIndex: 1,
	},
	{
		label: 'Color',
		key: 'color',
		namespace: 'color',
		orderIndex: 2,
	},
	{
		label: 'Created By',
		key: 'created_by',
		namespace: 'created_by',
		orderIndex: 3,
	},
	{
		label: 'Databases',
		key: 'databases',
		namespace: 'databases',
		orderIndex: 4,
	},
	{
		key: 'action',
		label: 'Action',
		namespace: 'action',
		width: 100,
		type: 'all',
		filter: {
		'type': 'none',
		},
	},
];

const initialState = {
	tagsStatus: 'loading',
	tags: [],
	isAddTag: true,
	modalVisibility: false,
	modalData: {
		key: '',
		label: '',
		color: '#000000',
		description: '',
	},
	tagSaveLoading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'update_tags_status':
			return {
				...state,
				tagsStatus: action.payload,
			};
		case 'update_tags_and_status':
			return {
				...state,
				tagsStatus: action.payload.tagsStatus,
				tags: action.payload.tags,
			};
		case 'update_modal_visibility':
			return {
				...state,
				modalVisibility: action.payload,
			};
		case 'update_modal_visibility_and_is_add_tag':
			return {
				...state,
				modalVisibility: action.payload.modalVisibility,
				isAddTag: action.payload.isAddTag,
			};
		case 'update_modal_visibility_and_edit_data':
			return {
				...state,
				modalVisibility: action.payload.modalVisibility,
				isAddTag: false,
				modalData: action.payload.modalData
			}
		case 'update_modal_data':
			return {
				...state,
				modalData: action.payload,
			};
		case 'update_modal_data_and_visibility':
			return {
				...state,
				modalVisibility: action.payload.modalVisibility,
				modalData: action.payload.modalData,
			};
		case 'update_modal_data_and_tag_save_status':
			return {
				...state,
				modalVisibility: action.payload.modalVisibility,
				modalData: action.payload.modalData,
				tagSaveLoading: false,
			};
		case 'update_tag_loading_status':
			return {
				...state,
				tagSaveLoading: action.payload,
			};
		default:
			return state;
	}
};

const Tags = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const reduxDispatch = useDispatch();
	const {
		tagsStatus,
		tags,
		modalVisibility,
		modalData,
		isAddTag,
		tagSaveLoading,
	} = state;
	const { label, color, description } = modalData;
	const { globalTimeout } = window['runConfig'];

	useEffect(() => {
		getTags();
	}, []);

	const getTags = () => {
		dispatch({
			type: 'update_tags_status',
			payload: 'loading',
		});
		axios
			.post('/data-browser/admin/get-tags', {
				timeout: globalTimeout,
			})
			.then((response) => {
				if (response.status === 200) {
					const data = response?.data?.data ?? {};
					const tempTagsData = [];
					for (const key in data) {
						if (data.hasOwnProperty(key)) {
							tempTagsData.push(data[key]);
						}
					}

					dispatch({
						type: 'update_tags_and_status',
						payload: {
							tagsStatus: 'success',
							tags: tempTagsData,
						},
					});
				} else {
					dispatch({
						type: 'update_tags_and_status',
						payload: {
							tagsStatus: 'error',
							tags: [],
						},
					});
				}
			})
			.catch((error) => {
				console.log(error.response);
				dispatch({
					type: 'update_tags_and_status',
					payload: {
						tagsStatus: 'error',
						tags: [],
					},
				});
			});
	};

	const openModal = () => {
		dispatch({
			type: 'update_modal_visibility_and_is_add_tag',
			payload: {
				modalVisibility: true,
				isAddTag: true,
			},
		});
	};

	const closeModal = () => {
		dispatch({
			type: 'update_modal_data_and_visibility',
			payload: {
				modalVisibility: false,
				modalData: {
					label: '',
					color: '#000000',
					description: '',
				},
			},
		});
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		dispatch({
			type: 'update_modal_data',
			payload: {
				...modalData,
				[name]: value,
			},
		});
	};

	const handleSubmit = () => {
		if(label !== '') {
			dispatch({
			type: 'update_tag_loading_status',
			payload: true,
		});

		const requestPayload = {
			...modalData,
			timeout: globalTimeout,
		};

		if (isAddTag) delete requestPayload.key;

		axios
			.post('/data-browser/admin/create-update-tag', requestPayload)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					dispatch({
						type: 'update_modal_data_and_tag_save_status',
						payload: {
							modalVisibility: false,
							modalData: {
								key: '',
								label: '',
								color: '#000000',
								description: '',
							},
							tagSaveLoading: false,
						},
					});
					getTags();
				} else {
					addAlertToDOM(`.add-edit-tag-section`, 'warning', 'Something went wrong. Try again.');
					dispatch({
						type: 'update_tag_loading_status',
						payload: false,
					});
				}
			})
			.catch((error) => {
				let errorMsg = 'Something went wrong. Please try again.'
				if(error?.response?.data?.message) {
					errorMsg = error.response.data.message; 
				}
				addAlertToDOM(`.add-edit-tag-section`, 'warning', errorMsg);
				dispatch({
					type: 'update_tag_loading_status',
					payload: false,
				});
			});
		} else addAlertToDOM(`.add-edit-tag-section`, 'warning', 'Label should not be empty', 'mb-10', 2000);
	};

	const deleteTag = index => {
		if(tags?.[index]) {
			const {key, label} = tags[index];
			axios.post('/data-browser/admin/delete-tags', {
				label,
				key,
				timeout: globalTimeout
			}).then(response => {
				console.log(response);
				if(response.status === 200){
					addAlertToDOM('.add-edit-tag-alert-section', 'success', 'Tag has been deleted successfully');
					getTags();
				} else addAlertToDOM('.add-edit-tag-alert-section', 'warning', 'Something went wrong');
			}).catch(error => {
				if(error?.response?.data?.message){
					reduxDispatch({
						type: UPDATE_GLOBAL_ERROR_DATA,
						payload: {
							visibility: true,
							errors: error?.response?.data?.message
						}
					});
				}
				addAlertToDOM('.add-edit-tag-alert-section', 'warning', 'Something went wrong');
			})
		}
	}

	const handleAction = (actionType, index) => {
		if(actionType === 'delete') {
			deleteTag(index);
		} else if(actionType === 'edit') {
			const { key, label, color, description } = tags[index];
			dispatch({
				type: 'update_modal_visibility_and_edit_data',
				payload: {
					modalVisibility: true,
					isAddTag: false,
					modalData: { key, label, color, description }
				}
			})
		}
	}

	return (
		<Row>
			<Col xs={12} className='text-right'>
				<div className="add-edit-tag-alert-section text-left"/>
				<button className='db-button db-button--primary' onClick={openModal}>Add Tag</button>
			</Col>
			<Col xs={12}>
				<DataTable
					loading={tagsStatus === 'loading'}
					dbName=''
					collectionName=''
					columnHeaders={tableColumnHeaders}
					data={tags}
					disablePagination={true}
					actionType="all"
        			onAction={handleAction}
				/>
				<Modal
					title={isAddTag ? 'Add Tag' : 'Edit Tag'}
					width={600}
					visible={modalVisibility}
					onCancel={closeModal}
				>
					<div className='add-edit-tag-section'>
						<Row>
							<Col xs={8}>
								<label htmlFor='label'>Label :</label>
								<input
									type='text'
									name='label'
									className='db-input full-width'
									placeholder=''
									value={label}
									onChange={handleInputChange}
								/>
							</Col>
							<Col xs={4}>
								<label htmlFor='color'>Color :</label>
								<input
									type='color'
									name='color'
									className='full-width'
									value={color}
									onChange={handleInputChange}
								/>
							</Col>
							<Col xs={12} className='mt-10'>
								<label htmlFor='description'>Description :</label>
								<textarea
									className='db-input full-width'
									name='description'
									rows={4}
									value={description}
									onChange={handleInputChange}
								/>
							</Col>
							<Col xs={12} className='text-right'>
								<button
									className='db-button db-button--primary'
									onClick={handleSubmit}
								>
									{tagSaveLoading ? (
										<span>
											<FontAwesomeIcon
												className='loading-icon mr-5'
												icon={faSpinner}
												spin
											/>
											Submit
										</span>
									) : (
										'Submit'
									)}
								</button>
							</Col>
						</Row>
					</div>
				</Modal>
			</Col>
		</Row>
	);
};

export default withPageAccessCheck(Tags);
