import React, {useEffect, useReducer} from 'react';
import DualListBox from 'react-dual-listbox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from '../modal/Modal';
import {
  faBorderAll,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import {addAlertToDOM} from '../../helpers/util';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const initialState = {
  showModal: false,
  tempColumnsData: [],
  selectedColumnsData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_modal_state':
      return {
        ...state,
        showModal: action.payload,
      };
    case 'update_list_box_data':
      return {
        ...state,
        tempColumnsData: action.payload.tempColumnsData,
        selectedColumnsData: action.payload.selectedColumnsData,
      };
    case 'update_temp_filtered_columns':
      return {
        ...state,
        selectedColumnsData: action.payload,
      };
    default:
      return state;
  }
};

const listBoxFilter = item => ({label: item.label, value: item.namespace});

const DataTableHeaderColumnPicker = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    title = '',
    configuredTableColumns,
    filteredTableColumns,
    onChange,
    onReset,
    doValidate,
  } = props;

  const {showModal, tempColumnsData, selectedColumnsData} = state;

  useEffect(() => {
    if (configuredTableColumns) {
      const buildDlData = configuredTableColumns.map(listBoxFilter);
      const buildDlSelectedData = filteredTableColumns.map(
          item => (item.namespace));

      dispatch({
        type: 'update_list_box_data',
        payload: {
          tempColumnsData: buildDlData,
          selectedColumnsData: buildDlSelectedData,
        },
      });
    }
  }, [configuredTableColumns, filteredTableColumns]);

  const handleModalVisibility = () => {
    dispatch({
      type: 'update_modal_state',
      payload: !showModal,
    });
  };

  const handleListBoxChange = (selected) => {
    if (doValidate && configuredTableColumns.length === selected.length) {
      dispatch({
        type: 'update_temp_filtered_columns',
        payload: selected.filter(item => !item.includes('.')),
      });
    } else dispatch({
      type: 'update_temp_filtered_columns',
      payload: selected,
    });
  };

  const validateData = () => {
    let foundDuplicates = false;
    let matchedSelections = {};
    selectedColumnsData.forEach(columnData => {
      if (columnData.includes('.')) {
        selectedColumnsData.forEach(data => {
          if (columnData !== data) {
            if (data.includes(columnData)) {
              const splitData = data.split('.');
              for (let i = 0; i <= splitData.length; i++) {
                const trimmedData = splitData.slice(0, i).join('.');
                if (trimmedData === columnData) {
                  foundDuplicates = true;
                  if (matchedSelections.hasOwnProperty(columnData)) {
                    if (!matchedSelections[columnData].includes(
                        data)) matchedSelections[columnData].push(data);
                  } else matchedSelections[columnData] = [data];
                }
              }
            } else if (columnData.includes(data)) {
              const splitColumnData = columnData.split('.');
              for (let i = 0; i <= splitColumnData.length; i++) {
                const trimmedColumnData = splitColumnData.slice(0, i).join('.');
                if (trimmedColumnData === data) {
                  foundDuplicates = true;
                  if (matchedSelections.hasOwnProperty(data)) {
                    if (!matchedSelections[data].includes(
                        columnData)) matchedSelections[data].push(columnData);
                  } else matchedSelections[data] = [columnData];
                }
              }
            }
          }
        });
      }
    });
    return {foundDuplicates, matchedSelections};
  };

  const buildAlertText = data => {
    let alertText = '';

    for (const objKey in data) {
      if (data.hasOwnProperty(objKey)) {
        alertText += `<li>${objKey} -> ${data[objKey].length > 1 ?
            data[objKey][0] + ', ...' :
            data[objKey]}</li>`;
      }
    }

    return `<ul>${alertText}</ul>`;
  };

  const saveFilteredColumns = () => {
    if (doValidate) {
      if (selectedColumnsData.length === 0) {
        addAlertToDOM(
            '.db-modal .db-dual-list-box-actions',
            'warning',
            'Please select at least one column to proceed.',
            'mt-10',
            2000,
        );
      } else {
        const {foundDuplicates, matchedSelections} = validateData();
        if (foundDuplicates) {
          addAlertToDOM(
              '.db-modal .db-dual-list-box-actions',
              'warning',
              `<p>Can't save current selection. Found parent and child objects are added.</p> ${buildAlertText(matchedSelections)}`,
              'mt-10',
              5000,
              true,
          );
        } else {
          const tempFilteredData = configuredTableColumns.filter(
              item => selectedColumnsData.includes(item.namespace));
          onChange && onChange(tempFilteredData);
          dispatch({
            type: 'update_modal_state',
            payload: false,
          });
        }
      }
    } else {
      const tempFilteredData = configuredTableColumns.filter(
          item => selectedColumnsData.includes(item.namespace));
      onChange && onChange(tempFilteredData);
      dispatch({
        type: 'update_modal_state',
        payload: false,
      });
    }
  };

  const resetFilteredColumns = () => {
    onReset && onReset();
    dispatch({
      type: 'update_modal_state',
      payload: false,
    });
  };

  return (
      <>
        <button
            className="db-button"
            onClick={handleModalVisibility}
        >
          <FontAwesomeIcon
              icon={faBorderAll}
              className="mr-5"
          /> {title}
        </button>
        <Modal
            title="Table columns:"
            visible={showModal}
            onCancel={handleModalVisibility}
        >
          <DualListBox
              options={tempColumnsData}
              icons={{
                moveLeft: <FontAwesomeIcon
                    icon={faChevronLeft}
                />,
                moveAllLeft: [
                  <FontAwesomeIcon
                      key={0}
                      icon={faChevronLeft}
                  />,
                  <FontAwesomeIcon
                      key={1}
                      icon={faChevronLeft}
                  />,
                ],
                moveRight: <FontAwesomeIcon
                    icon={faChevronRight}
                />,
                moveAllRight: [
                  <FontAwesomeIcon
                      key={0}
                      icon={faChevronRight}
                  />,
                  <FontAwesomeIcon
                      key={1}
                      icon={faChevronRight}
                  />,
                ],
                moveDown: <FontAwesomeIcon
                    icon={faChevronDown}
                />,
                moveUp: <FontAwesomeIcon
                    icon={faChevronUp}
                />,
              }}
              selected={selectedColumnsData}
              // preserveSelectOrder={true}
              // showOrderButtons={true}
              canFilter={true}
              showHeaderLabels={true}
              onChange={handleListBoxChange}
          />
          <div className="db-dual-list-box-actions text-right">
            <button
                className="db-button db-button--danger mr-5"
                onClick={resetFilteredColumns}
            >Reset
            </button>
            <button
                className="db-button db-button--primary"
                onClick={saveFilteredColumns}
            >Apply
            </button>
          </div>
        </Modal>
      </>
  );
};

export default DataTableHeaderColumnPicker;
