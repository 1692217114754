import React, {useEffect, useReducer} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

const initialState = {
  loading: true,
  baseFilterRowCount: 1,
  baseFilterData: {
    fr1: {
      condition: 'eq',
      value: '',
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_loading_status':
      return {
        ...state,
        loading: action.payload,
      };
    case 'update_base_filter_data':
      return {
        ...state,
        baseFilterData: action.payload.baseFilterData,
        baseFilterRowCount: action.payload.baseFilterRowCount,
      };
    case 'update_base_filter_data_loading_status':
      return {
        ...state,
        baseFilterData: action.payload.baseFilterData,
        baseFilterRowCount: action.payload.baseFilterRowCount,
        loading: false,
      };
    default:
      return state;
  }
};

const DTHBaseFilterLayout = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {loading, baseFilterRowCount, baseFilterData} = state;

  const {
    data: {namespace},
    filterData,
    onFilterChange,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      if (filterData && filterData.hasOwnProperty(namespace)) {
        const count = Math.round(Object.keys(filterData[namespace]).length / 2);
        dispatch({
          type: 'update_base_filter_data_loading_status',
          payload: {
            baseFilterData: filterData[namespace],
            baseFilterRowCount: count,
          },
        });
      } else dispatch({
        type: 'update_base_filter_data_loading_status',
        payload: {
          baseFilterData: {
            fr1: {
              condition: 'eq',
              value: '',
            },
          },
          baseFilterRowCount: 1,
        },
      });
    }, 200);
  }, [filterData, namespace]);

  const addFieldRow = () => {
    let tempBaseFilterData = {...baseFilterData};
    const incBaseFilterRowCount = baseFilterRowCount + 1;

    tempBaseFilterData['lfr' + incBaseFilterRowCount] = 'and';
    tempBaseFilterData['fr' + incBaseFilterRowCount] = {
      condition: 'eq',
      value: '',
    };

    dispatch({
      type: 'update_base_filter_data',
      payload: {
        baseFilterData: tempBaseFilterData,
        baseFilterRowCount: incBaseFilterRowCount,
      },
    });
  };

  const removeFieldRow = () => {
    if (baseFilterRowCount > 1) {
      let tempBaseFilterData = {...baseFilterData};

      if (tempBaseFilterData.hasOwnProperty(
          'lfr' + baseFilterRowCount)) delete tempBaseFilterData['lfr' +
      baseFilterRowCount];
      if (tempBaseFilterData.hasOwnProperty(
          'fr' + baseFilterRowCount)) delete tempBaseFilterData['fr' +
      baseFilterRowCount];

      dispatch({
        type: 'update_base_filter_data',
        payload: {
          baseFilterData: tempBaseFilterData,
          baseFilterRowCount: baseFilterRowCount - 1,
        },
      });
    }
  };

  const handleOperatorChange = event => {
    const {name, value} = event.target;
    let tempBaseFilterData = {...baseFilterData};
    tempBaseFilterData[name] = value;
    dispatch({
      type: 'update_base_filter_data',
      payload: {
        baseFilterData: tempBaseFilterData,
        baseFilterRowCount,
      },
    });
  };

  const handleFilterRowDataChange = event => {
    const {name = '', value = ''} = event.target;
    const tempBaseFilterData = {...baseFilterData};
    const splitName = name.split('-');
    tempBaseFilterData[splitName[0]][splitName[1]] = value;
    dispatch({
      type: 'update_base_filter_data',
      payload: {
        baseFilterData: tempBaseFilterData,
        baseFilterRowCount,
      },
    });
  };

  const sendFilterDataToParent = () => {
    let tempFilterData = {...filterData};
    tempFilterData[namespace] = baseFilterData;
    onFilterChange(tempFilterData);
  };

  const resetFilter = () => {
    let tempFilterData = {...filterData};
    if (tempFilterData.hasOwnProperty(namespace)) {
      delete tempFilterData[namespace];
      onFilterChange(tempFilterData);
    }
    dispatch({
      type: 'update_base_filter_data',
      payload: {
        baseFilterData: {
          fr1: {
            condition: 'eq',
            value: '',
          },
        },
        baseFilterRowCount: 1,
      },
    });
  };

  const buildFieldRows = () => {
    let fieldRows = [], i;
    for (i = 1; i <= baseFilterRowCount; i++) {
      if (baseFilterData.hasOwnProperty('lfr' + i)) {
        const currOperatorValue = baseFilterData['lfr' + i];
        fieldRows.push(
            <select
                name={`lfr${i}`}
                key={'lfr' + i}
                className="db-select mt-5 mb-15"
                style={{width: 100}}
                value={currOperatorValue}
                onChange={handleOperatorChange}
            >
              <option value="and">AND</option>
              <option value="or">OR</option>
              <option value="nor">NOR</option>
            </select>,
        );
      }

      if (baseFilterData.hasOwnProperty('fr' + i)) {
        const {condition, value} = baseFilterData['fr' + i];
        const isValueDisabled = ['exist', 'nil', 'blank'].includes(condition);

        fieldRows.push(
            <div className="field-rows-wrapper" key={'fr' + i}>
              <select
                  name={`fr${i}-condition`}
                  className="db-select"
                  value={condition}
                  onChange={handleFilterRowDataChange}
              >
                <option value="eq">Equals to</option>
                <option value="ne">Not equals to</option>
                <option value="contains">Contains</option>
                <option value="exist">Exist</option>
                <option value="nil">Nil</option>
                <option value="blank">Blank</option>
              </select>
              <input
                  type="text"
                  name={`fr${i}-value`}
                  className="db-input"
                  value={value}
                  disabled={isValueDisabled}
                  onChange={handleFilterRowDataChange}
              />
            </div>,
        );
      }
    }
    return fieldRows;
  };

  return (
      <>
        <div className="dropdown-inner-section">
          {
            loading ? (
                <h3 className="text-center mt-20 mb-20">
                  <FontAwesomeIcon
                      className="loading-icon mr-5"
                      icon={faSpinner}
                      spin
                  /> Loading..</h3>
            ) : (
                <>
                  {buildFieldRows()}
                  <div>
                    <button
                        className="db-button db-button--primary mr-5 mt-0 mb-0"
                        onClick={addFieldRow}
                    >+
                    </button>
                    <button
                        className="db-button db-button--danger mt-0 mb-0"
                        onClick={removeFieldRow}
                    >-
                    </button>
                  </div>
                </>
            )
          }
        </div>
        <div className="dropdown-inner-action-section">
          <button
              className="filter"
              type="button"
              onClick={sendFilterDataToParent}
          >Filter
          </button>
          <button
              className="clear"
              type="button"
              onClick={resetFilter}
          >Clear
          </button>
        </div>
      </>
  );
};

export default DTHBaseFilterLayout;
