import React, { useEffect, useState } from 'react';
import {
	faBars, faCaretDown, faCog, faDatabase, faExclamationCircle, faTags, faTimes, faUser, faUserCircle, faUserCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { isEmptyObject, slugify, sortDatabaseCollections } from '../../helpers/util';
import {
	UPDATE_DATABASE_COLLECTION_NAMES,
	UPDATE_DATABASE_COLLECTION_NAMES_LOADING
} from '../../redux/actions/types';
import './NavigationBar.scss';

const NavigationBar = () => {
	const [sidebarVisibility, setSidebarVisibility] = useState(false);
	const {
		util: { databaseCollectionNames },
		userData: { userData },
	} = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();
	const { globalTimeout } = window['runConfig'];

	useEffect(() => {
		const getCollectionDatabaseNames = () => {
			let databaseNames = []
			let databaseCollectionNames = {}
			let configStateDic = {}
			axios
				.post('/data-browser/database-collection-names', {
					timeout: globalTimeout,
				})
				.then((response) => {
					if (response.status === 200) {
						const data = sortDatabaseCollections(response?.data?.data ?? {});
						// let databaseNames = [];

						for (let dbName in data) {
							if (dbName && data.hasOwnProperty(dbName)) {
								databaseNames.push(dbName);
								data[dbName].forEach(coll => {
									configStateDic[`${dbName}--${coll.collection_name}`] = coll.config_state
								});
							}
						}

						// console.log("configStateDic", configStateDic);

						axios
						.post('/data-browser/get-tags', {
							timeout: globalTimeout,
						})
						.then((response) => {
							if (response.status === 200) {
								const dataRaw = response?.data?.data ?? {}

								let tags = {}
								let tagsName =[]
								for (const k in dataRaw) {
									if (Object.hasOwnProperty.call(dataRaw, k)) {
										const element = dataRaw[k];
										let databases = element.databases ? element.databases:{} ;
										if (Object.keys(databases).length > 0) {
											tagsName.push(`${element.label}__${element.color}`)
											tags[`${element.label}__${element.color}`] = []
											for (const dbName in element.databases) {
												if (Object.hasOwnProperty.call(element.databases, dbName)) {
													const cols = element.databases[dbName];
													cols.forEach(collName => {
														tags[`${element.label}__${element.color}`].push({
															collection_name: collName,
															config_state: configStateDic[`${dbName}--${collName}`],
															db_name : dbName,
															type : "tags",
															tagSlug: slugify(element.label)
														})
													});
												}
											}
										}
									}
								}

								databaseNames = [...tagsName, ...databaseNames]
								databaseCollectionNames = {...sortDatabaseCollections(tags),...data};
							}

							dispatch({
								type: UPDATE_DATABASE_COLLECTION_NAMES,
								payload: {
									databaseNames,
									databaseCollectionNames,
								},
							});
						})
					} else
						dispatch({
							type: UPDATE_DATABASE_COLLECTION_NAMES_LOADING,
							payload: false,
						});
				})
				.catch((error) => {
					console.log(error);
					dispatch({
						type: UPDATE_DATABASE_COLLECTION_NAMES_LOADING,
						payload: false,
					});
				});
		};

		getCollectionDatabaseNames();
	}, []);

	const getConfigState = (configState) => {
		return configState === 'not-configured' ? (
			<FontAwesomeIcon icon={faExclamationCircle} />
		) : configState === 'admin-configured' ? (
			<FontAwesomeIcon icon={faUserCog} />
		) : configState === 'user-configured' ? (
			<FontAwesomeIcon icon={faUser} />
		) : (
			''
		);
	};

	const buildDatabaseCollectionList = () => {
		if (!isEmptyObject(databaseCollectionNames)) {
			let collectionList = [];
			for (const dbName in databaseCollectionNames) {
				if (databaseCollectionNames.hasOwnProperty(dbName)) {
					collectionList.push(
						<li 
							key={dbName}
							className="pointer"
						>
							<div className="expand-collections" onClick={(x)=>{
						const target = x.target.closest('.expand-collections')
						target.lastChild.classList.toggle("expanded")
						target.nextSibling.classList.toggle("expand")
						}}>
						<span>
							{dbName.split("__").length>1?<FontAwesomeIcon color={dbName.split("__")[1]} icon={faTags} className='tags-icon' />:<FontAwesomeIcon icon={faDatabase} className='database-icon' />}
							{dbName.split("__").length>1?dbName.split("__")[0]:dbName}
						</span>
						<FontAwesomeIcon icon={faCaretDown} className='expand-icon'/>
					</div>
							<ul className='sidenav-child'>
								{databaseCollectionNames[dbName].map(
									({ db_name = '', collection_name = '--', config_state = false, type = '', tagSlug }, i) => (
										<li
											key={`${dbName}-${collection_name}-${i}`}
											data-class={config_state}
										>
											<NavLink
												title={collection_name}
												to={
													config_state === 'not-configured'
														? type === 'tags' 
															? `/app/admin/settings/tag=${tagSlug}&db-name=${db_name}&collection-name=${collection_name}` 
															: `/app/admin/settings/db-name=${dbName}&collection-name=${collection_name}`
														: type === 'tags' 
															? `/app/data-table/tag=${tagSlug}&db-name=${db_name}&collection-name=${collection_name}`
															: `/app/data-table/db-name=${dbName}&collection-name=${collection_name}`
												}
												activeClassName='active'
												onClick={closeSidebar}
											>
												<div className='label'>{collection_name}</div>
												<span className='mw-20 text-center'>
													{getConfigState(config_state)}
												</span>
											</NavLink>
										</li>
									)
								)}
							</ul>
						</li>
					);
				}
			}
			return collectionList;
		}
	};

	const redirectToConfigPage = () => {
		const locationPathName = history.location.pathname;
		if (
			locationPathName.includes('/app/data-table/db-name=') &&
			locationPathName.includes('collection-name=')
		) {
			const splitPathName = locationPathName.split('/');
			history.push(`/app/admin/settings/${splitPathName[3]}`);
		} else history.push(`/app/admin/generic-settings/`);
	};

	const handleLogout = () => {
		const {
			mainApp: { logoutApiPath, logoutRedirectionUrl },
		} = window['runConfig'];

		axios
			.delete(logoutApiPath)
			.then((response) => {
				if (response.status === 200) {
					let redirectUrl = logoutRedirectionUrl;

					if (redirectUrl.includes('http') || redirectUrl.includes('https')) {
						redirectUrl += `?redirectUri=${window.location.href}`;
					}

					window.location.href = redirectUrl;
				}
			})
			.catch((error) => console.log(error.response));
	};

	const openSidebar = () => {
		setSidebarVisibility(true);
	};

	const closeSidebar = () => {
		setSidebarVisibility(false);
	};

	const getUserFullName = () => {
		const { first_name = '', last_name = '' } = userData ?? {};
		return `${first_name} ${last_name}`;
	};

	const getUserRole = () => {
		const { roles } = userData ?? {};
		if (roles.length === 0) {
			return 'Unknown';
		} else if (roles.length === 1) {
			return roles[0]?.label ?? 'Unknown';
		} else return 'Unknown';
	};

	return (
		<div className='db-navbar'>
			<div className='db-navbar-logo-section'>
				<FontAwesomeIcon
					title='Menu'
					icon={faBars}
					className='menu-icon'
					onClick={openSidebar}
				/>
				<div className='sidenav' style={{ width: sidebarVisibility ? 300 : 0 }}>
					<div
						className='close-icon-wrapper'
						style={{ left: sidebarVisibility ? 0 : -300 }}
					>
						<FontAwesomeIcon
							icon={faTimes}
							className='close-icon'
							onClick={closeSidebar}
						/>
					</div>
					<ul className='sidenav-parent'>{buildDatabaseCollectionList()}</ul>
				</div>
			</div>
			<div className='db-navbar-menu-section'>
				<FontAwesomeIcon
					title='Settings'
					icon={faCog}
					aria-label='settings'
					className='settings-icon cursor-pointer mr-15'
					onClick={redirectToConfigPage}
				/>
				<div className='user-dropdown'>
					<FontAwesomeIcon
						title='User'
						icon={faUserCircle}
						aria-label='user'
						className='user-icon cursor-pointer'
						onClick={redirectToConfigPage}
					/>
					<div className='user-dropdown-content'>
						<div className='user-detail-section'>
							<p className='user-name'>{getUserFullName()}</p>
							<p className='user-role'>{getUserRole()}</p>
						</div>
						<div className='links-section'>
							<p className='cursor-pointer' onClick={handleLogout}>
								Logout
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavigationBar;
