import React, {useState} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import axios from 'axios';
import Switch from 'react-switch';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
  faSortDown,
  faSortUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import ConfigurableTableColumnFilterRow
  from './ConfigurableTableColumnFilterRow';
import {CELL_WRAP_TYPE_CONSTANTS} from '../../helpers/constants';
import {
  addAlertToDOM,
  convertColumnNameToClassName,
  setInnerObjValue,
} from '../../helpers/util';
import {UPDATE_GLOBAL_ERROR_DATA} from '../../redux/actions/types';
import { useDispatch } from 'react-redux';

const {
  WRAP_DYNAMIC,
  WRAP_SCROLL_X,
  WRAP_SCROLL_Y,
  CLIP,
  DYNAMIC,
} = CELL_WRAP_TYPE_CONSTANTS;

const saveDeleteBtnStyles = {width: 100};

const DataTableColumnConfigurationRow = props => {
  const [loading, setLoadingStatus] = useState(false);
  const [showFields, setFieldsVisibility] = useState(false);
  const [tableColumnData, setTableColumnData] = useState(props.data);
  const reduxDispatch = useDispatch();

  const {
    index,
    columnCount,
    dbName,
    collectionName,
    data,
    configuredTableColumns,
    onOrderChange,
    onSave,
    onDelete,
    saveDefaultFlag,
  } = props;

  const {globalTimeout} = window['runConfig'];
  const toggleContentVisibility = () => setFieldsVisibility(prevState => !prevState);

  const handleFieldsChange = (event, dataType) => {
    const {name, value} = event.target;
    const tempTableColumnData = {...tableColumnData};

    let parsedValue;
    if (dataType === 'bool') {
      parsedValue = value == 'true';
    } else if (dataType === 'number') {
      parsedValue = Number(value);
    } else parsedValue = value;

    if (tempTableColumnData?.filter?.type === 'enum' && name ===
        'filter-subType') {
      let parsedSourceData;
      if (value === 'bool') {
        parsedSourceData = [true, false];
      } else if (value === 'static') {
        parsedSourceData = [];
      } else if (value === 'dynamic') {
        parsedSourceData = JSON.stringify({
          databaseName: dbName,
          collectionName,
          fieldName: tempTableColumnData.namespace,
          query: {},
          timeout: '10s',
        });
      }
      setInnerObjValue('filter-source-data', parsedSourceData,
          tempTableColumnData);
    }

    if (name.includes('filter')) {
      setInnerObjValue(name, parsedValue, tempTableColumnData);
    } else tempTableColumnData[name] = parsedValue;

    // if (name === 'filter-subType') {
    //   if (tempTableColumnData?.filter?.source?.data) deleteByPath(tempTableColumnData, 'filter-source-data');
    // }

    tempTableColumnData.meta = {configured: true};

    setTableColumnData(tempTableColumnData);
  };

  const handleSwitchChange = (fieldName, checked) => {
    const tempTableColumnData = {...tableColumnData};
    tempTableColumnData[fieldName] = checked;

    tempTableColumnData.meta = {configured: true};
    setTableColumnData(tempTableColumnData);
  };

  const handleCacheChange = isCached => {
    const tempTableColumnData = {...tableColumnData};
    tempTableColumnData.filter.isCached = isCached;
    setTableColumnData(tempTableColumnData);
    if(!isCached){
      clearEnumCache();
    }
  }

  const clearEnumCache = () => {
    axios.post('/data-browser/admin/clear-enum-field-cache', {
      databaseName: dbName,
      collectionName,
      fieldName: key,
      timeout: globalTimeout
    }).then(response => {
        console.log(response);
    }).catch(error => {
      console.log(error.response);
    })
  }

  const handleStaticSourceDataChange = data => {
    const tempTableColumnData = {...tableColumnData};

    setInnerObjValue('filter-subType', 'static', tempTableColumnData);
    setInnerObjValue('filter-source-data', data, tempTableColumnData);
    tempTableColumnData.filter.subType = 'static';
    tempTableColumnData.meta = {configured: true};

    setTableColumnData(tempTableColumnData);
  };

  const handleDynamicSourceDataChange = data => {
    const tempTableColumnData = {...tableColumnData};
    setInnerObjValue('filter-source-data', data, tempTableColumnData);
    tempTableColumnData.filter.subType = 'dynamic';
    tempTableColumnData.meta = {configured: true};

    setTableColumnData(tempTableColumnData);
  };

  const handleSaveColumnData = className => {
    setLoadingStatus(true);
    const errorsList = [];
    const updatedConfiguredTableColumns = configuredTableColumns.map(item => item.namespace === tableColumnData.namespace ? tableColumnData : item);
    const tempConfiguredTableColumns = updatedConfiguredTableColumns.map(item => {
      if (item?.filter?.source?.data) {
        const tempConfiguredTableColumn = {...item};
        const sourceData = tempConfiguredTableColumn.filter.source.data;
        let parsedSourceData = '';
        try {
          parsedSourceData = typeof sourceData === 'object' ? sourceData : JSON.parse(sourceData);
          parsedSourceData.query = typeof parsedSourceData.query === 'string' ? parsedSourceData.query : JSON.stringify(parsedSourceData.query); 
          tempConfiguredTableColumn.filter.source.data = parsedSourceData;
        } catch(error) {
          errorsList.push(error.message);
        }
        return tempConfiguredTableColumn;
      }
      return item;
    });

    if(errorsList.length <= 0) {
      const reqData = {
        databaseName: dbName,
        collectionName: collectionName,
        saveDefaultFlag: saveDefaultFlag,
        namespace: dbName + '.' + collectionName,
        configuredTableColumns: tempConfiguredTableColumns,
        timeout: globalTimeout,
      };
      
      axios.post('/data-browser/admin/save-structure', reqData).then(response => {
        if (response.status === 200) {
          addAlertToDOM(`.table-column-content-section.${className}`, 'success', 'Successfully saved');
          onSave(updatedConfiguredTableColumns);
        } else addAlertToDOM(`.table-column-content-section.${className}`, 'warning', 'Something went wrong. Please try again');
        setLoadingStatus(false);
      }).catch(error => {
        addAlertToDOM(`.table-column-content-section.${className}`, 'warning', 'Something went wrong. Please try again');
        setLoadingStatus(false);
        if(error?.response?.data?.message){
          reduxDispatch({
            type: UPDATE_GLOBAL_ERROR_DATA,
            payload: {
              visibility: true,
              errors: error.response.data.message
            }
          });
        }
      });
    } else {
      setLoadingStatus(false);
      reduxDispatch({
        type: UPDATE_GLOBAL_ERROR_DATA,
        payload: {
          visibility: true,
          errors: errorsList
        }
      });
    }
  };

  const handleDeleteColumnData = () => {
    onDelete(index);
  };

  if (Object.keys(tableColumnData).length === 0) {
    return (
        <div className="db-alert warning">
          <FontAwesomeIcon icon={faExclamationCircle}/>
          <p>No data found.</p>
        </div>
    );
  }

  const {
    key,
    label,
    namespace,
    visible = false,
    sortable = false,
    fixed = false,
    aggregation = false,
    wrapType = 'wrap-dynamic',
    width = 240,
    filter,
  } = tableColumnData;

  const buildColumnClassNames = data?.meta?.configured ?
      'table-column-section configured' :
      'table-column-section';
  const columnClassName = convertColumnNameToClassName(key);

  return (
      <div className="table-column-section-wrapper">
        <div className="table-column-section-wrapper-content">
          <div className={buildColumnClassNames}>
            <div
                className="table-column-header-section"
                onClick={toggleContentVisibility}
            >
              <div className="table-column-title-wrapper">
                <p className="table-column-title">{key}</p>
              </div>
              {data.meta?.configured &&
              <p className="configured-tag">Configured</p>}
              <FontAwesomeIcon icon={showFields ? faChevronUp : faChevronDown}/>
            </div>
            {
              showFields && (
                  <div className={`table-column-content-section cn-${columnClassName}`}>
                    <Container fluid>
                      <Row gutterWidth={15}>
                        <Col className="mt-10" xs={3}>
                          <label
                              htmlFor="key"
                              className="column-section-label"
                          >Key :</label>
                          <input
                              type="text"
                              className="db-input"
                              name="key"
                              value={key}
                              disabled
                          />
                        </Col>
                        <Col className="mt-10" xs={3}>
                          <label
                              htmlFor="namespace"
                              className="column-section-label"
                          >Namespace :</label>
                          <input
                              type="text"
                              className="db-input"
                              name="namespace"
                              value={namespace}
                              disabled
                          />
                        </Col>
                        <Col className="mt-10" xs={3}>
                          <label
                              htmlFor="label"
                              className="column-section-label"
                          >Label :</label>
                          <input
                              type="text"
                              className="db-input"
                              name="label"
                              value={label}
                              onChange={event => handleFieldsChange(event,
                                  'string')}
                          />
                        </Col>
                        <Col className="mt-10" xs={3}>
                          <label
                              htmlFor="wrapType"
                              className="column-section-label"
                          >Cell Wrap Type :</label>
                          <select
                              className="db-input"
                              name="wrapType"
                              value={wrapType}
                              onChange={event => handleFieldsChange(event,
                                  'string')}
                          >
                            <option value={WRAP_DYNAMIC}>Wrap Dynamic</option>
                            <option value={WRAP_SCROLL_X}>Wrap Scroll-X</option>
                            <option value={WRAP_SCROLL_Y}>Wrap Scroll-Y</option>
                            <option value={CLIP}>Clip</option>
                            <option value={DYNAMIC}>Dynamic</option>
                          </select>
                        </Col>
                        <Col className="mt-10" xs={3}>
                          <label
                              htmlFor="width"
                              className="column-section-label"
                          >Width :</label>
                          <input
                              type="number"
                              className="db-input"
                              name="width"
                              value={width}
                              onChange={event => handleFieldsChange(event,
                                  'number')}
                          />
                        </Col>
                        {
                          filter?.type === 'number' && (
                              <Col className="mt-10" xs={3}>
                                <div className="column-section-switch-wrapper">
                                  <label
                                      htmlFor="aggregation-switch"
                                      className="column-section-label mr-15"
                                  >Aggregation :</label>
                                  <Switch
                                      onChange={checked => handleSwitchChange('aggregation', checked)}
                                      checked={aggregation}
                                      onColor="#86d3ff"
                                      onHandleColor="#2693e6"
                                      handleDiameter={20}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={15}
                                      width={40}
                                      className="react-switch"
                                      id="aggregation-switch"
                                  />
                                </div>
                              </Col>
                          )
                        }
                        <Col className="mt-10" xs={3}>
                          <div className="column-section-switch-wrapper">
                            <label
                                htmlFor="visible-switch"
                                className="column-section-label mr-15"
                            >Visible :</label>
                            <Switch
                                onChange={checked => handleSwitchChange(
                                    'visible', checked)}
                                checked={visible}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={15}
                                width={40}
                                className="react-switch"
                                id="visible-switch"
                            />
                          </div>
                        </Col>
                        <Col className="mt-10" xs={3}>
                          <div className="column-section-switch-wrapper">
                            <label htmlFor="sortable-switch"
                                   className="column-section-label mr-10">Sortable
                              :</label>
                            <Switch
                                onChange={checked => handleSwitchChange(
                                    'sortable', checked)}
                                checked={sortable}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={15}
                                width={40}
                                className="react-switch"
                                id="sortable-switch"
                            />
                          </div>
                        </Col>
                        <Col className="mt-10" xs={3}>
                          <div className="column-section-switch-wrapper">
                            <label htmlFor="fixed-switch"
                                   className="column-section-label mr-10">Fixed
                              :</label>
                            <Switch
                                onChange={checked => handleSwitchChange('fixed',
                                    checked)}
                                checked={fixed}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={15}
                                width={40}
                                className="react-switch"
                                id="fixed-switch"
                            />
                          </div>
                        </Col>
                      </Row>
                      <ConfigurableTableColumnFilterRow
                          dbName={dbName}
                          collectionName={collectionName}
                          columnNamespace={namespace}
                          data={filter}
                          onFieldsChange={handleFieldsChange}
                          onStaticDataChange={handleStaticSourceDataChange}
                          onDynamicDataChange={handleDynamicSourceDataChange}
                          onCacheChange={handleCacheChange}
                      />
                      <Row gutterWidth={15}>
                        <Col xs={12}>
                          <button
                              style={saveDeleteBtnStyles}
                              className="db-button db-button--primary mt-15"
                              onClick={() => handleSaveColumnData('cn-' + columnClassName)}
                          >
                            {
                              loading ? (
                                  <span>
                                    <FontAwesomeIcon
                                        className="loading-icon mr-5"
                                        icon={faSpinner}
                                        spin
                                    />
                                    Save
                                  </span>
                              ) : 'Save'
                            }
                          </button>
                          <button
                              style={saveDeleteBtnStyles}
                              className="db-button db-button--danger ml-10 mt-15"
                              onClick={handleDeleteColumnData}
                          >Delete
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </div>
              )
            }
          </div>
        </div>
        <div className="table-column-section-wrapper-sort">
          <div className="sort-up-icon-wrapper">
            <FontAwesomeIcon
                className={index === 0 ?
                    'sort-up-icon disabled' :
                    'sort-up-icon'}
                icon={faSortUp}
                onClick={() => index > 0 && onOrderChange(index, index - 1)}
            />
          </div>
          <div className="sort-order-div">
            <input
                value={index + 1}
                type="number"
                className="sort-order-input"
                min={0}
                max={columnCount - 1}
                onChange={event => onOrderChange(index, event.target.value - 1)}
            />
          </div>
          <div className="sort-up-icon-wrapper">
            <FontAwesomeIcon
                className={index === columnCount - 1 ?
                    'sort-down-icon disabled' :
                    'sort-down-icon'}
                icon={faSortDown}
                onClick={() => index !== columnCount - 1 &&
                    onOrderChange(index, index + 1)}
            />
          </div>
        </div>
      </div>
  );
};

export default DataTableColumnConfigurationRow;
