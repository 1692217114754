import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SettingsSidebar from '../../components/settings_sidebar/SettingsSidebar';
import GenericSettings from '../generic_settings/GenericSettings';
import CollectionConfiguration from '../collection_configuration/CollectionConfiguration';
import Tags from '../tags/Tags';
import UserRestrictions from '../user_restrictions/UserRestrictions';
import './Settings.scss';

const Settings = () => {
	const { url } = useRouteMatch();

	return (
		<>
			<h3 className='db-top-heading'>Settings</h3>
			<div className='db-settings-section'>
				<SettingsSidebar />
				<div className='db-settings-right-section'>
					<Switch>
						<Route exact path={`${url}/generic-settings/cache/`}>
							<GenericSettings />
						</Route>
						<Route exact path={`${url}/security-settings/`}>
							<Redirect to={`${url}/security-settings/restrictions/`}/>
						</Route>
						<Route exact path={`${url}/security-settings/restrictions/`}>
							<UserRestrictions />
						</Route>
						<Route exact path={`${url}/generic-settings/tags/`}>
							<Tags />
						</Route>
						<Route path={`${url}/settings/tag=:tagName&db-name=:dbName&collection-name=:collectionName`}>
							<CollectionConfiguration />
						</Route>
						<Route path={`${url}/settings/db-name=:dbName&collection-name=:collectionName`}>
							<CollectionConfiguration />
						</Route>
					</Switch>
				</div>
			</div>
		</>
	);
};

export default Settings;
