import React, {useEffect, useState} from 'react';

const DTHSearchFilterLayout = props => {
  const [searchString, setSearchString] = useState('');
  const {
    data: {namespace},
    filterData,
    onFilterChange,
  } = props;

  useEffect(() => {
    if (filterData) {
      setSearchString(filterData[namespace] ?? '');
    }
  }, [filterData, namespace]);

  const handleSearchStringChange = e => {
    setSearchString(e.target.value);
  };

  const setFilterData = () => {
    let tempFilterData = {...filterData};
    tempFilterData[namespace] = searchString.trim();
    onFilterChange(tempFilterData);
  };

  const resetFilter = () => {
    let tempFilterData = {...filterData};
    if (tempFilterData.hasOwnProperty(namespace)) {
      delete tempFilterData[namespace];
      onFilterChange(tempFilterData);
    }
  };

  return (
      <>
        <div className="dropdown-inner-section">
          <input
              type="text"
              className="db-input"
              value={searchString}
              onChange={handleSearchStringChange}
          />
        </div>
        <div className="dropdown-inner-action-section">
          <button
              className="filter"
              type="button"
              onClick={setFilterData}
          >Filter
          </button>
          <button
              className="clear"
              type="button"
              onClick={resetFilter}
          >Clear
          </button>
        </div>
      </>
  );
};

export default DTHSearchFilterLayout;
